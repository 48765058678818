<template>
  <h2 class="subtitle"><slot></slot></h2>
</template>

<script>
export default {
  name: 'Subtitle',
  data: function () {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.subtitle {
  font-size: 20px;
  font-weight: normal;
  line-height: 1;
  color: var(--title-color);
}
</style>