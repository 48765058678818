<template>
  <a :href="href" class="c-link" :class="{ 'c-link--no-icon': !showIcon }">
    <slot></slot>
    <span v-if="showIcon">
      <img :src="icoArrowRight" alt="">
    </span>
  </a>
</template>

<script>
import icoArrowRight from '../assets/ico-arrow-right.png'
export default {
  name: 'CLink',
  props: {
    href: String,
    showIcon: {
      type: Boolean,
      default: true
    },
  },
  data: function () {
    return {
      icoArrowRight: icoArrowRight
    }
  }
}
</script>
<style lang="scss" scoped>
.c-link {
  position: relative;
  font-weight: 500;
  z-index: 1;
  text-decoration: none;
  font-size: 14px;
  color: var(--title-color);

  img {
    width: 19px;
    height: auto;
    margin-left: 14px;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 35px);
    height: 10px;
    opacity: 0.27;
    background-image: var(--gradient-link);
    z-index: -1;
  }


  &--no-icon {
    &::after {
      width: 100%;
    }
  }

}
</style>