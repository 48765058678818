<template>
  <div class="container">
    <div class="container-app">
      <div class="row">
        <div class="col-md-12 mb-3">
          <back />
        </div>
        <div class="col-md-12 col-lg-6 offset-lg-3">
          <div class="rl-box m-auto">
            <h2 class="title-small">{{ $t("setReset.title")}}</h2>
            <p class="rl-description">{{ $t("setReset.desc")}}</p>

            <form @submit="reset">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-floating">
                    <input type="text" class="form-control" id="username" :value="$store.state.resetUsername" disabled>
                    <label for="username">{{ $t("setReset.email")}}</label>
                  </div>
                </div>
                <div class="col-md-12">
                  <hr class="separator">
                </div>
                <div class="col-md-12">
                  <div class="form-floating">
                    <input type="number" class="form-control" :class="{'is-invalid': errors.otp}" id="otp" v-model="otp" :placeholder="$t('setReset.otp')">
                    <label for="otp">{{ $t("setReset.otp")}}</label>
                    <div class="invalid-feedback" v-if="errors.otp">
                      {{ $t("setReset.errorOtp")}}
                    </div>
                  </div>
                  <div class="setreset-text">{{ $t("setReset.textOtp")}}</div>
                  <div class="setreset-link" @click="resend">{{ $t("setReset.linkOtp")}}</div>
                </div>
                <div class="col-md-12">
                  <hr class="separator">
                </div>
                <div class="col-md-12 mb-3">
                  <password :name="$t('setReset.password')" :error="errors.password" :error-message="$t('setReset.errorPassword')" v-model="password" />
                  <div class="setreset-text mb-3">{{ $t("setReset.textPassword")}}</div>
                  <password :name="$t('setReset.confirmPassword')" :error="errors.confirmPassword" :error-message="$t('setReset.errorConfirmPassword')" v-model="confirmPassword" />
                </div>
              </div>
              <button type="submit" class="btn btn-big text-uppercase w-100 mt-3" :disabled="!validReset">{{ $t("setReset.reset")}}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Back from "@/components/Back";
import Password from "@/components/Password";
export default {
  name: 'ResetPassword',
  data: function () {
    return {
      password: '',
      confirmPassword: '',
      otp: '',
      errors: {
        otp: false,
        password: false,
        confirmPassword: false,
      },
    }
  },
  computed: {
    validReset(){
      let valid = true
      if(!this.otp) {
        valid = false
      }
      if(!this.password) {
        valid = false
      }
      if(!this.confirmPassword) {
        valid = false
      }
      return valid
    }
  },
  components: {
    Password,
    Back
  },
  methods: {
    resend: function() {
      this.$store.commit('setLoader', true)
      this.axios.patch(process.env.VUE_APP_API_AUTH_URL + 'reset-password', {
        EmailCustomerCode: this.$store.state.resetUsername
      }).then(() => {
        this.$store.commit('setToast', {
          show: true,
          type: 'success',
          text: this.$t('setReset.codeSent'),
          timeout: 3000
        })
      }).catch(() => {
        this.$store.commit('setToast', {
          show: true,
          type: 'danger',
          text: this.$t('setReset.codeError'),
          timeout: 3000
        })
      }).finally(() => {
        this.$store.commit('setLoader', false)
      });
    },
    reset: function(ev) {
      ev.preventDefault()

      // Reset errors
      for (const errorsKey in this.errors) {
        this.errors[errorsKey] = false
      }

      // Validation
      if (this.password !== this.confirmPassword) {
        this.errors.confirmPassword = true
      }

      if(!/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?!.*[^a-zA-Z0-9?=.*[*.!@$%^&:;<>,.?~_+-=|]).{8,20}/.test(this.password)) {
        this.errors.password = true
      }

      let error = false
      for (const errorsKey in this.errors) {
        if (this.errors[errorsKey]) {
          error = true
        }
      }

      if (!error) {
        this.$store.commit('setLoader', true)

        this.axios.patch(process.env.VUE_APP_API_AUTH_URL + 'complete-reset-password', {
          Email: this.$store.state.resetUsername,
          PinCode: this.otp,
          NewPassword: this.password
        }).then(() => {
          this.$router.push('/password-cambiata')
        }).catch(() => {
          this.errors.otp = true
        }).finally(() => {
          this.$store.commit('setLoader', false)
        });
      }

    }
  },
  created() {

  },
  mounted() {
  }
}
</script>
<style lang="scss" scoped>
.form-password {
  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    cursor: pointer;
  }
}

.container-app {
  @include bp($md) {
    padding: 40px 0 80px;
  }
}

.separator {
  border-color: var(--light-grey);
  opacity: .1;
  margin: 24px 0;
}

.setreset {
  &-link {
    margin: 5px 0 0;
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    color: var(--black);
    text-decoration: underline;
    cursor: pointer;
  }

  &-text {
    margin: 35px 0 0;
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
    color: var(--title-color);
  }
}
</style>