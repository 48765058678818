<template>
  <div class="container">
    <div class="container-app">
      <div class="row">
        <div class="col-md-12 mb-3">
          <back :message="$t('reset.message')" />
        </div>
        <div class="col-md-12 col-lg-6 offset-lg-3">
          <div class="rl-box m-auto">
            <h2 class="title-small">{{ $t("reset.title")}}</h2>
            <p class="rl-description">{{ $t("reset.desc")}}</p>

            <form @submit="reset">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <div class="form-floating">
                    <input type="text" class="form-control" :class="{'is-invalid': errors.username}" id="username" placeholder="Username o Indirizzo e-mail" v-model="username">
                    <label for="username">{{ $t("reset.email")}}</label>
                    <div class="invalid-feedback" v-if="errors.username">
                      {{ $t("reset.emailNotValid")}}
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn btn-big text-uppercase w-100" :disabled="!validReset">{{ $t("reset.reset")}}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Back from "@/components/Back";
export default {
  name: 'ResetPassword',
  data: function () {
    return {
      username: '',
      errors: {
        username: false,
      },
    }
  },
  computed: {
    validReset(){
      let valid = true
      if(!this.username) {
        valid = false
      }
      return valid
    }
  },
  components: {
    Back
  },
  methods: {
    validateEmail: function(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    reset: function(ev) {
      ev.preventDefault()
      this.errors.username = false

      if (!this.validateEmail(this.username)) {
        this.errors.username = true
        return;
      }

      this.$store.commit('setLoader', true)

      this.axios.patch(process.env.VUE_APP_API_AUTH_URL + 'reset-password', {
        EmailCustomerCode: this.username
      }).then(() => {
        this.$store.commit('setResetUsername', this.username)
        this.$router.push('/imposta-password')
      }).catch(() => {
        this.errors.username = true
      }).finally(() => {
        this.$store.commit('setLoader', false)
      });

    }
  },
  created() {

  },
  mounted() {
  }
}
</script>
<style lang="scss" scoped>
.form-password {
  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    cursor: pointer;
  }
}

.container-app {
  @include bp($md) {
    padding: 40px 0 80px;
  }
}
</style>