<template>
  <div
    class="card supply"
    style="height: 325px;"
    @click="goToSupply"
    :class="
    {
      'supply--gas': supply.supplyTypeCode === 'GAS',
      'supply--energy': supply.supplyTypeCode === 'EE',
      'supply--payment': mode === 'payment',
    }
  ">
    <span v-if="supply.paymentStatus == 'PAID' && mode === 'payment'" class="supply-icon supply-icon--paid"><img :src="icoCheck" /></span>
    <span v-if="supply.paymentStatus == 'NOTPAID' && mode === 'payment'" class="supply-icon supply-icon--not-paid"><img :src="icoKo" /></span>
    <span v-if="supply.paymentStatus == 'PARTIAL' && mode === 'payment'" class="supply-icon supply-icon--partial"><img :src="icoKo" /></span>


    <img v-if="supply.supplyTypeCode === 'EE'" src="../assets/illustration-luce-white.png" class="card-img-top" alt="...">
    <img v-if="supply.supplyTypeCode === 'GAS'" src="../assets/illustration-gas-white.png" class="card-img-top" alt="...">

    <div class="card-body" v-if="mode === 'view'">
      <h5 class="card-title small-text">{{ $t("supply.number") }}</h5>
      <p class="card-text medium-text">{{ supply.SupplyPoint }}</p>
      <span class="card-text small-text" style="margin-bottom: 15px;">{{ supply.address }}</span>
    </div>
    <div class="card-body" v-if="mode === 'view'">
      <p v-if="supply.invoiceId != null" class="card-text small-text" style="margin-bottom: 5px;">{{ $t("supply.lastInvoice") }}</p>
      <h3 v-if="supply.totalValue != null" class="card-title price">€ {{ formatFloat(supply.totalValue) }}</h3>
      <p v-if="supply.expirationDate != null" class="card-text small-text expiry">{{ $t("supply.expiry") }} {{ $moment(supply.expirationDate).format('DD/MM/YYYY') }}</p>
    </div>

    <div class="card-body" v-if="mode === 'payment'">
      <h5 v-if="this.active" class="card-title small-text">{{ $t("supply.active", { type: supplyType }) }}</h5>
      <div v-if="!this.active"  style="display: flex;flex-direction: row;">
        <h5 class="card-title small-text">{{ $t("supply.not_active", { type: supplyType }) }}</h5>
       
        <div style="font-size: 10px;background-color: red;height: 15px;margin-left: 4px;padding-left:4px;padding-right: 4px;margin-top: 3px;border-radius: 2px;">
            {{ $t("supply.closed") }}
        </div>      
      </div>
      <h5 class="card-title small-text supply-active">{{ supply.supplyPoint }}</h5>
      <h3 class="card-title price">€ {{ formatFloat(supply.totalValue) }}</h3>
      <div class="card-text small-text expiry">{{ $t("supply.expiry") }} {{ $moment(supply.expirationDate).format('DD/MM/YYYY') }}</div>
      <div class="card-text small-text payment">
        <div @click="showNoteList(supply)" style="align-items: center;justify-content: center;">
          <span v-if="supply.notes.length > 0">
          <img :src="icoKo" width="18" height="18" style="margin-top: -3px;margin-right: 5px;" />
        </span>
        <span v-if="supply.totalValue < 0">{{ $t("supply.paymentNegative") }}</span>
        <span v-if="supply.paymentStatus === 'PAID' && supply.totalValue >= 0">{{ $t("supply.paymentOK") }}</span>
        <span v-if="supply.paymentStatus === 'NOTPAID' && supply.totalValue >= 0">{{ $t("supply.paymentKO") }}</span>
        <span v-if="supply.paymentStatus === 'PARTIAL' && supply.totalValue >= 0">{{ $t("supply.paymentPartial") }}</span>
        <span v-if="supply.paymentStatus === 'INPAYMENT' && supply.totalValue >= 0">{{ supply.paymentButtonLabel }}</span>
        </div>
        
      </div>
      <div class="card-text small-text payment-details">
       
        <template v-if="supply.masterContractPaymentTypeCode === 'BLP'">
          <span v-if="(!supply.hasOwnProperty('paymentStatus') || supply.paymentStatus === '' || supply.paymentStatus === 'COMPENS'  || supply.paymentStatus === 'PENDING')  && supply.expirationDate">{{ $t("supply.paymentBplInvoiceNumber", { number: supply.invoiceNumber, date: $moment(supply.invoiceDate).format('DD/MM/YYYY')}) }}</span>
          <!--<br v-if="(!supply.hasOwnProperty('paymentStatus') || supply.paid === null)  && supply.expirationDate" />
           <span v-if="(!supply.hasOwnProperty('paymentStatus') || supply.paid === null)  && supply.expirationDate">{{ $t("supply.paymentBpl", {date: $moment(supply.expirationDate).format('DD/MM/YYYY')}) }}</span>-->
          <span v-if="supply.paymentStatus === 'NOTPAID'">{{ $t("supply.paymentBplKo", { date: $moment(supply.expirationDate).format('DD/MM/YYYY')}) }}</span>
          <span v-if="supply.paymentStatus === 'PAID'">{{ $t("supply.paymentBplOk", { date: $moment(supply.expirationDate).format('DD/MM/YYYY')}) }}</span>
        </template>

        
        <template v-if="supply.masterContractPaymentTypeCode === 'RID'">
          <span v-if="(!supply.hasOwnProperty('paymentStatus') || supply.paymentStatus === '' || supply.paymentStatus === 'COMPENS'  || supply.paymentStatus === 'PENDING')  && supply.expirationDate">{{ $t("supply.paymentRidInvoiceNumber", { number: supply.invoiceNumber, date: $moment(supply.invoiceDate).format('DD/MM/YYYY')}) }}</span>
          <!--<br v-if="(!supply.hasOwnProperty('paymentStatus') || supply.paid === null)  && supply.expirationDate" />
          <span v-if="(!supply.hasOwnProperty('paymentStatus') || supply.paid === null) && supply.expirationDate">{{ $t("supply.paymentRid",{date: $moment(supply.expirationDate).format('DD/MM/YYYY')}) }}</span>-->
          <span v-if="supply.paymentStatus === 'NOTPAID'">{{ $t("supply.paymentRidKo", { date: $moment(supply.expirationDate).format('DD/MM/YYYY')}) }}</span>
          <span v-if="supply.paymentStatus === 'PAID'">{{ $t("supply.paymentRidOk", { date: $moment(supply.expirationDate).format('DD/MM/YYYY')}) }}</span>
        </template>

        <br v-if="supply.paymentStatus === 'NOTPAID' || supply.paymentStatus === 'PAID'" />
        <span v-if="supply.paymentStatus === 'NOTPAID' || supply.paymentStatus === 'PAID' || supply.paymentStatus === 'INPAYMENT'">{{ $t("supply.invoiceNumber", { number: supply.invoiceNumber, date: $moment(supply.invoiceDate).format('DD/MM/YYYY')}) }}</span>
        
        <!-- <span v-if="supply.totalValue < 0">{{ $t("supply.invoiceNumber", { number: supply.invoiceNumber, date: $moment(supply.invoiceDate).format('DD/MM/YYYY')}) }}</span>
        -->
      </div>
    </div>
  </div>
  
</template>

<script>
import icoCheck from '../assets/ico-check-white.svg'
import icoKo from '../assets/ico-attetion-white.svg'

export default {
  name: 'Supply',
  props: {
    supply: Object,
    mode: {
      type: String,
      default: 'view'
    }
  },
  data: function () {

    let active = this.isActive();

    return {
      icoCheck: icoCheck,
      icoKo: icoKo,
      active:active,
    }
  },
  methods: {
    showNoteList: function(supply) {
      console.log(supply);
      this.$store.commit('setNoteList', supply.notes);
      this.$store.commit('setTitleNoteList',this.$t("supply.invoiceNumberNoDate",{number: supply.invoiceNumber}));
      event.stopPropagation();
    },
    isActive: function() {
      var active = false;

      const userContract = this.$store.state.currentUserContract;

      const supplies = userContract.supplyPoints;

      for (let index = 0; index < supplies.length; index++) {
        const element = supplies[index];

      
        if (this.supply.supplyPoint != undefined && this.supply.supplyPoint != null) {
          if (element.supplyPoint == this.supply.supplyPoint) {
            active = element.active;
            break;
          }
        } else if (this.supply.SupplyPoint != undefined && this.supply.SupplyPoint != null) {
          if (element.supplyPoint == this.supply.SupplyPoint) {
            active = element.active;
            break;
          }
        }

       

      }
      
      return active;
    },
    goToSupply: function() {
      this.$store.commit('setSupply', this.supply)
      this.$router.push('/bolletta/' + this.supply.supplyTypeCode + '/' + this.supply.invoiceId)
    }
  },
  computed: {
    supplyType: function () {
      return this.supply.supplyTypeCode === 'GAS' ? this.$t('supply.supplyGas') : this.$t('supply.supplyEE')
    }
  }
}
</script>
<style lang="scss" scoped>
.supply {
  padding: 18px 28px 26px;
  border: none;
  color: #fff;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;

  &-icon {
    position: absolute;
    top: 25px;
    right: 25px;
    border-radius: 50%;
    padding: 2px;
    line-height: 1;

    &--paid {
      background-color: var(--green);
    }

    &--not-paid {
      background-color: var(--red);
    }

    &--partial {
      background-color:  var(--payment-partial);
    }
  }

  .card-img-top {
    width: 47px;
  }

  .card-body {
    padding: 20px 0 0;
  }

  &-active {
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
  }

  .price {
    line-height: 1;
    margin-bottom: 5px;
  }

  .expiry {
    font-weight: 300;
  }

  .payment {
    margin-top: 12px;

    &-details {
      margin-top: 8px;
      font-size: 12px;
      font-weight: 300;
      line-height: normal;
      letter-spacing: normal;
      color: var(--white);

    }
  }

  &--payment {
    margin-bottom: 24px;

    .price {
      margin-top: 15px;
    }
  }

  &--energy {
    background-image: var(--gradient-energy);
  }

  &--gas {
    background-image: var(--gradient-gas);
  }
}
</style>