<template>
  <div class="container">
    <div class="container-app">
      <div class="row">
        <div class="col-md-12 mb-3">
          <back />
        </div>
        <div class="col-md-12 col-lg-6 offset-lg-3">
          <div class="rl-box m-auto">
            <h2 class="title-small">{{ $t("editPayment.title")}}</h2>
            <p class="rl-description">{{ $t("editPayment.desc")}}</p>

            <form @submit="submit">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <div class="form-floating">
                    <input type="text" class="form-control" :class="{'is-invalid': errors.bank}" id="bank" v-model="bank" :placeholder="$t('editPayment.bank')">
                    <label for="username">{{ $t("editPayment.bank")}}</label>
                    <div class="invalid-feedback" v-if="errors.bank">
                      {{ $t("editPayment.errorBank")}}
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mb-3">
                  <div class="form-floating">
                    <input type="text" class="form-control" :class="{'is-invalid': errors.iban}" id="iban" v-model="iban" :placeholder="$t('editPayment.iban')">
                    <label for="username">{{ $t("editPayment.iban")}}</label>
                    <div class="invalid-feedback" v-if="errors.iban">
                      {{ $t("editPayment.errorIban")}}
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn btn-big text-uppercase w-100 mt-3" :disabled="!valid">{{ $t("editPayment.edit")}}</button>
            </form>

          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Back from "@/components/Back";
export default {
  name: 'EditPayment',
  data: function () {
    return {
      bank: '',
      iban: '',
      codeLengths: {
        AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
        CH: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
        FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
        HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
        LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
        MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
        RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,
        AL: 28, BY: 28, CR: 22, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25,
        SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
      },
      errors: {
        bank: false,
        iban: false
      }
    }
  },
  computed: {
    valid(){
      let valid = true
      if(!this.bank) {
        valid = false
      }
      if(!this.iban) {
        valid = false
      }
      return valid
    }
  },
  components: {
    Back
  },
  methods: {
    checkIban: function(input){
      let iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
        code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
        digits;
      // check syntax and length
      if (!code || iban.length !== this.codeLengths[code[1]]) {
        return false;
      }
      // rearrange country code and check digits, and convert chars to ints
      digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
        return letter.charCodeAt(0) - 55;
      });
      // final check
      return this.mod97(digits);
    },
    mod97: function (string){
      let checksum = string.slice(0, 2), fragment;
      for (let offset = 2; offset < string.length; offset += 7) {
        fragment = String(checksum) + string.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
      }
      return checksum;
    },
    submit: function(ev) {
      ev.preventDefault()

      // Reset errors
      for (const errorsKey in this.errors) {
        this.errors[errorsKey] = false
      }

      // Validation
      if(!this.bank) {
        this.errors.bank = true
      }

      if(!this.iban || !this.checkIban(this.iban)) {
        this.errors.iban = true
      }

      let error = false
      for (const errorsKey in this.errors) {
        if (this.errors[errorsKey]) {
          error = true
        }
      }

      if (!error) {
        this.$store.commit('setLoader', true)

        // Change bank
        this.axios.patch(process.env.VUE_APP_API_AUTH_URL + 'change-payment-method', {
          Bank: this.bank,
          IBAN: this.iban
        }, this.authSetting).then(() => {
          this.axios.get(process.env.VUE_APP_API_AUTH_URL + 'user-profile', this.authSetting)
            .then((response) => {
              this.$store.commit('setUser', response.data)

              this.$store.commit('setToast', {
                show: true,
                type: 'success',
                text: this.$t('editPayment.confirmMessage'),
                timeout: 3000
              })
              this.$router.back()
              this.$store.commit('setLoader', false)
            }).catch(() => {});
        }).catch(() => {
          this.errors.iban = true
          this.errors.bank = true
          this.$store.commit('setLoader', false)
        });
      }
    }
  },
  created() {
    this.bank = this.$store.state.user.bankName
    // this.iban = this.$store.state.user.bankName
  },
  mounted() {
  }
}
</script>
<style lang="scss" scoped>
.form-password {
  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    cursor: pointer;
  }
}

.container-app {
  padding: 24px 0;

  @include bp($md) {
    padding: 40px 0 80px;
  }
}

.separator {
  border-color: var(--light-grey);
  opacity: .1;
  margin: 24px 0;
}

</style>