<template>
  <section class="payments">
    <div class="container">
      <div class="row payments-title align-items-center">
        <div class="col-md-4 col-lg-6">
          <subtitle>{{ $t('payments.title') }}</subtitle>
        </div>
        <div class="col-md-8 col-lg-6 text-lg-end">
          <payment :hasList="true" :status="status" :type="paymentStatus"></payment>
        </div>
      </div>
      <div class="row bills-content" ref="bills">
        <div class="col-lg-12" v-if="billsTotal === 0 && end">
          {{ $t('bill.noBills')}}
        </div>
        <div class="col-lg-12" v-if="billsTotal > 0">
          <template v-for="year in billsYears" :key="'year-' + year">
            <div class="row pb-5" v-for="month in billsMonths(year)" :key="'month-' + month">

            <div class="col-12">
              <date-header :year="year" :month="month" />
            </div>
            <div class="col-md-4 col-lg-3" v-for="b in bills[year][month]" :key="'invoice-' + b.invoiceId">
              <supply mode="payment" :supply="b" />
            </div>
            </div>
          </template>
          <div class="row pb-5" v-if="this.end && this.enableBefore2020">
            <div class="col-12">
            <div style="background-color: #858585;width: 100%; height: 5px;opacity: 0.1;margin-bottom: 20px;"></div>
            <div style="display: flex;flex-direction: column;align-items: center;">
                <div style="display: flex;flex-direction: row;">
                <span style="display: inline-block;font-size: 14px;color: #000;text-align: center;">
                  {{ this.$t("payment.support_title") }}
                </span>
              </div>
        
              <div style="display: flex;flex-direction: row;">
                <span style="display: inline-block;font-size: 14px;color: #000;">
                  {{ this.$t("payment.support_subtitle_1") }}
                </span>
                <a style="margin-left: 5px;" href="/assistenza?show_back=true">{{this.$t("payment.support_subtitle_2")}}</a>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <popup @close="() => this.closePopup()" v-if="$store.state.notes.length > 0" />
</template>

<script>
import Subtitle from "@/components/Subtitle";
import Supply from "@/components/Supply";
import DateHeader from "@/components/DateHeader";
import Payment from "@/components/Payment";
import Popup from "@/components/PopupNoteList";
export default {
  name: 'Payments',
  components: {
    Payment,
    DateHeader,
    Supply,
    Subtitle,
    Popup
  },
  data: function () {
    return {
      bills: {},
      total: 0,
      end: false,
      isLoading: false,
      limit: 10,
      offset: 0,
      billsTotal: 0,
      paymentStatus: 'ok',
      enableBefore2020:false,
      status:0,
    }
  },
  computed: {
    billsYears: function () {
      return Object.keys(this.bills).sort((a,b) => { return b - a})
    },
  },
  methods: {
    closePopup: function() {
      this.$store.commit('setNoteList', []);
      this.$store.commit('setTitleNoteList','');
    },
    goToHelp: function () {
      this.$store.commit('setShowBack', true);
    },
    billsMonths: function (year) {
      return Object.keys(this.bills[year]).sort((a,b) => { return b - a})
    },
    resetScrollData: function(){
      this.bills = {}
      this.offset = 0
      this.billsTotal = 0
      this.end = false,
      this.enableBefore2020 = false
    },
    getData: async function (loader) {

      console.log(loader,'_aa_xx_loader');

      if (loader) {
        this.$store.commit('setLoader', true)
      }
      this.isLoading = true

      var url = process.env.VUE_APP_API_DWH_URL + 'invoices?limit=' + this.limit + '&offset=' + this.offset + '';

      if (this.$store.state.filterInvoiceStatus != undefined && this.$store.state.filterInvoiceStatus != null) {
        url= url + '&paid='+this.$store.state.filterInvoiceStatus;
      }

      console.log(url,'_aa_xx_');
     
      await this.axios.get(url, this.authDWHSetting)
        .then((response) => {
          response.data.invoices.map((bill) => {
            const year = this.$moment(bill.invoiceDate).format('YYYY')
            const month = this.$moment(bill.invoiceDate).format('M')
            if (!this.bills[year]) {
              this.bills[year] = {}
            }
            if (!this.bills[year][month]) {
              this.bills[year][month] = []
            }
            this.billsTotal++
            this.bills[year][month].push(bill)
          })

          this.enableBefore2020 = response.data.existBefore2020;

          this.offset += this.limit
          this.total = response.data.total

          if (this.total <= this.billsTotal) {
            this.end = true
          }
        }).catch(() => {});

      this.isLoading = false

      if (loader) {
        this.$store.commit('setLoader', false)
      }
    },
    handleScroll: function(){
      if (this.$refs.bills.getBoundingClientRect().bottom < window.innerHeight && !this.isLoading && !this.end) {
        console.log('_aa_xx_scrolling',this.offset);
        this.getData(this.offset == 0)
      }
    },
  },
  async created() {
    this.axios.get(process.env.VUE_APP_API_DWH_URL + 'payment-status', this.authDWHSetting)
      .then((response) => {
        this.paymentStatus = response.data.paymentsAreRegular ? 'ok' : 'ko'
      }).catch(() => {});

      this.getData(true);
  },
  watch: {
  '$store.state.filterInvoiceStatus': function() {
      this.bills = {}
      this.offset = 0
      this.billsTotal = 0
      this.end = false,
      this.enableBefore2020 = false

      console.log('_aa_xx_store');
      this.getData(true);
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }}
</script>

<style lang="scss" scoped>
.payments {
  &-title {
    margin-top: 24px;
    margin-bottom: 20px;

    @include bp($md) {
      margin-top: 65px;
    }

  }
}
</style>