<template>
  <div>
    <div class="c-select" :class="{ 'c-select--error': error, 'c-select--disabled': disabled }">
      <select @change="change" ref="select" :disabled="disabled" :value="modelValue">
        <slot></slot>
      </select>
      <img :src="icoArrowDown" />
    </div>
    <div class="c-select-error" v-if="error">{{ errorMessage }}</div>
  </div>
</template>

<script>
import icoArrowDown from '../assets/ico-arrow-down.svg'
export default {
  name: 'CSelect',
  props: {
    modelValue: String,
    errorMessage: String,
    error: Boolean,
    disabled: Boolean,
  },
  emits: ['update:modelValue'],
  methods: {
    change: function(){
      this.$emit('update:modelValue', this.$refs.select.value)
    }
  },
  data: function () {
    return {
      icoArrowDown: icoArrowDown
    }
  }
}
</script>
<style lang="scss" scoped>
.c-select {
  position: relative;
  cursor: pointer;
  background-color: var(--white);
  border: solid 1px var(--light-grey-3);

  img {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }

  &--error {
    border-color: var(--bs-danger);
  }

  &--disabled {
    cursor: not-allowed;
    background-color: var(--light-grey-3);

    select {
      cursor: not-allowed;
    }
  }

  &-error {
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    color: var(--bs-danger);
    padding: 0 15px;
    margin-top: 5px;
  }
}

select {
  cursor: pointer;
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  border: none;
  font-size: 14px;
  color: var(--title-color);
  outline: none!important;
  appearance: none;
  -moz-appearance: none;
  z-index: 1;
  background-color: transparent;
}
</style>