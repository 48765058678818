<template>
  <div class="notification">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-xl-4 mb-3 mb-lg-0">
          <subtitle>{{ $t("notification.title") }}</subtitle>
         
        </div>
        <div class="col-lg-8 col-xl-6">
          <h3 v-if="notifications.length == 0" class="notification-subtitle">{{ $t("notification.subtitle") }}</h3>
          <div v-if="notifications.length > 0" ref="notifications" class="row">
            <div class="col-md-12" v-for="(n, i) in notifications" :key="'notification-' + i">
              <notification-block :notification="n" />
            </div>
            <div class="col-md-12" v-if="!end">
              <div class="notification-more">
                <c-link @click="showMore" :show-icon="false">{{ $t("notification.more") }}</c-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Subtitle from "@/components/Subtitle";
import CLink from "@/components/CLink";
import NotificationBlock from "@/components/NotificationBlock";
export default {
  name: 'Notification',
  components: {NotificationBlock, CLink, Subtitle},
  props: ['type'],
  data: function () {
    return {
      notifications: [],
      limit: 10,
      offset: 0,
      end: false,
      isLoading: false
    }
  },
  methods: {
    showMore: function () {
      this.offset += this.limit
      this.getData(true)
    },
    getData: async function(loader) {
      if (loader) {
        this.$store.commit('setLoader', true)
      }
      this.isLoading = true

      await this.axios.get(process.env.VUE_APP_API_DWH_URL + 'notifications?limit=' + this.limit + '&offset=' + this.offset, this.authDWHSetting)
        .then((response) => {
          this.notifications.push(...response.data.results)
          this.total = response.data.total

          if (this.notifications.length >= this.total) {
            this.end = true
          }
        }).catch(() => {});

      this.isLoading = false

      if (loader) {
        this.$store.commit('setLoader', false)
      }
    },
  },
  mounted() {
    this.getData(true)
  }
}
</script>
<style lang="scss" scoped>
.notification {
  padding: 24px 0;
  background-color: var(--grey);

  @include bp($md) {
    padding: 80px 0;
  }

  &-subtitle {
    opacity: 0.5;
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    color: var(--title-color);
  }

  &-more {
    position: relative;
    padding-top: 35px;
    text-align: center;
    margin-top: 19px;

    &:before {
      content: '';
      display: block;
      height: 5px;
      background-color: var(--light-grey);
      opacity: .1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
}
</style>