<template>
  <button
    class="c-button"
    :type="type !== 'form' ? 'button' : 'submit'"
    :class="{
      'c-button--form': type === 'form',
      'c-button--blue': color === 'blue',
      'c-button--auto': width === 'auto'
    }"
  >
    <span class="icon" v-if="!!$slots.icon"><slot name="icon"></slot></span>
    <span class="text" v-if="!!$slots.text"><slot name="text"></slot></span>
  </button>
</template>

<script>
export default {
  name: 'CButton',
  props: {
    type: String,
    color: {
      type: String,
      default: 'orange',
      validator(value) {
        return ['orange', 'blue'].includes(value)
      }
    },
    width: {
      type: String,
      default: 'full',
      validator(value) {
        return ['full', 'auto'].includes(value)
      }
    },
  },
  data: function () {
    return {
    }
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.c-button {
  width: 100%;
  height: 48px;
  line-height: 1;
  margin: 0;
  padding: 0;
  border-radius: 4px;
  border: solid 2px var(--ee);
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  color: var(--ee);
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    margin-right: 8px;
  }

  &--form {
    width: auto;
    height: 50px;
    line-height: 50px;
    padding: 0 55px;
    border-radius: 4px;
    font-size: 14px;
    text-transform: uppercase;
    border: none;
    background-color: var(--gas);
    color: var(--white);

    &:disabled {
      background-color: var(--grey-submit);
      color: var(--grey-submit-2);
    }
  }

  &--auto {
    width: auto;
    height: 50px;
    line-height: 50px;
    padding: 0 55px;
    border-radius: 4px;
    background-color: var(--gas);
    font-size: 14px;
    color: var(--white);
    text-transform: uppercase;
    border: none;
    margin: 0 auto;
  }
}
</style>