<template>
  <div class="friend">
    <span class="friend-status">{{ $t("friend.active") }}</span>
    <h4 class="friend-title">{{ person.full_name }}</h4>
    <div class="friend-discount">{{ $t("friend.discount") }}</div>
    <ul class="friend-details">
      <li v-if="person.ee > 0">
        <img src="../assets/ee.png"> {{ formatFloat(person.ee) }} €
      </li>
      <li v-if="person.gas > 0">
        <img src="../assets/gas.png"> {{ formatFloat(person.gas) }} €
      </li>
    </ul>
    <div class="friend-text">{{ $t("friend.text", { date: person.date_to}) }}</div>
  </div>
</template>

<script>
export default {
  name: 'Friend',
  props: {
    person: Object
  },
  data: function () {
    return {
    }
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.friend {
  position: relative;
  background-color: var(--white);
  padding: 20px;
  margin-bottom: 24px;

  &-title {
    margin: 0 0 20px;
    opacity: 0.8;
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    text-transform: uppercase;
    color: var(--title-color);
  }

  &-status {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 12px;
    font-weight: normal;
    line-height: 1;
    color: var(--title-color);
    display: inline-block;
  }

  &-discount {
    margin: 0;
    font-size: 18px;
    font-weight: normal;
    line-height: 1;
    color: var(--black);
  }

  &-text {
    margin: 14px 0 0;
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    color: var(--black);
  }

  &-details {
    display: flex;
    margin: 12px 0;
    font-size: 15px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: normal;
    color: var(--black);
    padding: 0;
    list-style: none;

    @include bp($lg) {
      font-size: 21px;
    }

    li {
      display: flex;
      align-items: center;

      img {
        width: 36px;
        margin-right: 10px;

        @include bp($lg) {
          width: 46px;
        }
      }

      &:after {
        content: '+';
        display: block;
        opacity: 0.3;
        font-size: 21px;
        font-weight: bold;
        line-height: 1;
        color: var(--black);
        margin: 0 7px 0 12px;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
}
</style>