<template>
  <div
    @click="goToAutoReadingSupply"
    :class="
    {
      'supply--gas': supply.supplyTypeCode === 'GAS',
      'supply--energy': supply.supplyTypeCode === 'EE'
    }
  " style="cursor: pointer;;display: flex;flex-direction: row;align-items: center;border-radius: 5px;padding-left: 20px;padding-right: 20px;">
    
    <div style="height: 47px;width: 47px;display: flex;" v-if="supply.supplyTypeCode === 'EE'">
      <img style="height: 47px;width: 47px;" src="../assets/illustration-luce-white.png" class="card-img-top" alt="...">
    </div>

    <div style="height: 47px;width: 35px;display: flex;" v-if="supply.supplyTypeCode === 'GAS'">
      <img style="height: 47px;width: 35px;" src="../assets/illustration-gas-white.png" class="card-img-top" alt="...">
    </div>
  
    <div v-if="supply.supplyTypeCode === 'EE' && mode === 'view'" class="card-body" style="display: flex;flex-direction: column;flex: 1;">
      <span style="font-size: 16px;color: white;">{{ $t("autoReadingLogged.title_ee") }}</span>
      <span style="font-size: 20px;color: white;">{{ supply.SupplyPoint }}</span>
      <span style="font-size: 14px;color: white;">{{ supply.address }}</span>
    </div>

    <div v-if="supply.supplyTypeCode === 'GAS' && mode === 'view'" class="card-body" style="display: flex;flex-direction: column;flex: 1;">
      <span style="font-size: 16px;color: white;">{{ $t("autoReadingLogged.title_gas") }}</span>
      <span style="font-size: 20px;color: white;">{{ supply.SupplyPoint }}</span>
      <span style="font-size: 14px;color: white;">{{ supply.address }}</span>
    </div>

    <div  v-if="mode === 'view' && this.detectAutoReadingSended()" style="width:30px;height: 30px;border-radius: 15px;background-color: green;display: flex;align-items: center;justify-content: center;">
        <div  style="width:26px;height: 26px;border-radius: 13px;background-color: white;display: flex;align-items: center;justify-content: center;">
              <img :src="icoCheck">
        </div>
    </div>
    <!-- <div  v-if="mode === 'view' && (this.$store.state.dashboard.selfReadingInfo && this.$store.state.dashboard.selfReadingInfo.gasIsAvailable) && !this.detectAutoReadingSended()" style="width:30px;height: 30px;border-radius: 15px;background-color: #8f8f8f;display: flex;align-items: center;justify-content: center;">
        <div  style="width:26px;height: 26px;border-radius: 13px;background-color: white;display: flex;align-items: center;justify-content: center;">
          <div  style="width:22px;height: 22px;border-radius: 11px;background-color: #8f8f8f;display: flex;align-items: center;justify-content: center;padding-left: 4px;padding-right: 4px;">
                <div style="height: 2px;width: 100%;background-color: white;"></div>
            </div>      
        </div>
    </div>
    <div  v-if="mode === 'view' && (this.$store.state.dashboard.selfReadingInfo && this.$store.state.dashboard.selfReadingInfo.eeIsAvailable) && !this.detectAutoReadingSended()" style="width:30px;height: 30px;border-radius: 15px;background-color: #8f8f8f;display: flex;align-items: center;justify-content: center;">
        <div  style="width:26px;height: 26px;border-radius: 13px;background-color: white;display: flex;align-items: center;justify-content: center;">
          <div  style="width:22px;height: 22px;border-radius: 11px;background-color: #8f8f8f;display: flex;align-items: center;justify-content: center;padding-left: 4px;padding-right: 4px;">
                <div style="height: 2px;width: 100%;background-color: white;"></div>
            </div>      
        </div>
    </div> -->
  </div>
</template>

<script>
import icoCheck from '../assets/ico-check.svg'

export default {
  name: 'SupplyAutoReading',
  props: {
    supply: Object,
    mode: {
      type: String,
      default: 'view'
    }
  },
  data: function () {
    return {
      icoCheck: icoCheck
    }
  },
  methods: {
    goToAutoReadingSupply: function() {

      if (this.supply.supplyTypeCode == 'GAS') {
        if (this.$store.state.dashboard.selfReadingInfo && !this.$store.state.dashboard.selfReadingInfo.gasIsAvailable) {
          this.$store.commit('setAlertAutoReading', "gas")
        } else {
          this.$store.commit('setAutoReadingSupply', this.supply)
          this.$router.push('/auto-reading-'+this.supply.supplyTypeCode.toLowerCase())
        }
      } else {
        if (this.$store.state.dashboard.selfReadingInfo && !this.$store.state.dashboard.selfReadingInfo.eeIsAvailable) {
          this.$store.commit('setAlertAutoReading', "ee")
        } else {
          this.$store.commit('setAutoReadingSupply', this.supply)
          this.$router.push('/auto-reading-'+this.supply.supplyTypeCode.toLowerCase())
        }
      }
    },
    detectAutoReadingSended: function() {
      var result = false;
      
      const year = this.$moment().format('YYYY');
      const month = this.$moment().format('MM');

      const value =  (year+'_'+month+'_'+this.supply.supplyTypeCode+'_'+this.supply.SupplyPoint); 

      const sendedList = this.$store.state.autoReadingSended;

      for (let index = 0; index < sendedList.length; index++) {
        const element = sendedList[index];
        
        if (element.value == value) {
          result = true;
          break;
        }
      }



      return result;
    }
  },
  computed: {
    supplyType: function () {
      return this.supply.supplyTypeCode === 'GAS' ? this.$t('supply.supplyGas') : this.$t('supply.supplyEE')
    }
  }
}
</script>
<style lang="scss" scoped>
.supply {
  padding: 18px 28px 26px;
  border: none;
  color: #fff;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;

  &-icon {
    position: absolute;
    top: 25px;
    right: 25px;
    border-radius: 50%;
    padding: 2px;
    line-height: 1;

    &--paid {
      background-color: var(--green);
    }

    &--not-paid {
      background-color: var(--red);
    }
  }

  .card-img-top {
    width: 47px;
  }

  .card-body {
    padding: 20px 0 0;
  }

  &-active {
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
  }

  .price {
    line-height: 1;
    margin-bottom: 5px;
  }

  .expiry {
    font-weight: 300;
  }

  .payment {
    margin-top: 12px;

    &-details {
      margin-top: 8px;
      font-size: 12px;
      font-weight: 300;
      line-height: normal;
      letter-spacing: normal;
      color: var(--white);

    }
  }

  &--payment {
    margin-bottom: 24px;

    .price {
      margin-top: 15px;
    }
  }

  &--energy {
    background-image: var(--gradient-energy);
  }

  &--gas {
    background-image: var(--gradient-gas);
  }
}
</style>