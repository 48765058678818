<template>
  <div class="c-popup" v-if="this.showAddContract == 1">
    <div class="c-popup-content" style="padding: 46px 0px 0px 0px;">
      <div style="width: 100%;">
        <span v-if="this.windowWidth < 480" @click="this.$store.commit('showAddContract',false)" style="cursor: pointer;position: absolute;right: 12px;">
          <img class="is-close" :src="$siteURL + '/build/frontend/images/ico-close.png'" />
        </span>
        <span v-if="this.windowWidth >= 480" @click="this.$store.commit('showAddContract',false)" style="cursor: pointer;position: absolute;right: 50px;">
          <img class="is-close" :src="$siteURL + '/build/frontend/images/ico-close.png'" />
        </span>
      </div>
      <div style="max-width: 370px;margin: auto;padding: 42px 12px 27px;display: flex;flex-direction: column;">

        <span v-if="this.windowWidth < 480" style="font-size: 30px;color: #002626;">
          {{ $t('popupUserContractList.add_contract_title') }}        
        </span>
        <span v-if="this.windowWidth >= 480" style="font-size: 34px;color: #002626;">
          {{ $t('popupUserContractList.add_contract_title') }}        
        </span>
        <span style="font-size: 16px;font-weight: 300;color: #002626;margin-top: 20px;">
          {{ $t('popupUserContractList.add_contract_subtitle') }}        
        </span>
        <form @submit="addContract">
          <div class="row">
            <div class="col-md-12 mb-2" style="margin-top: 20px;">
                  <div class="form-floating">
                    <input type="text" class="form-control" :class="{'is-invalid': errors.code}" id="code" v-model="code" :placeholder="$t('popupUserContractList.code')">
                    <label for="code">{{ $t("popupUserContractList.code")}}</label>
                    <div class="invalid-feedback" v-if="errors.code">
                      {{ $t("popupUserContractList.errorCode")}}
                    </div>
                  </div>
            </div>
            <div class="col-md-12 mb-2" style="margin-top: 20px;">
                  <div class="form-floating">
                    <input type="text" class="form-control"  :class="{'is-invalid': errors.vat}" id="vat" v-model="vat" :placeholder="$t('popupUserContractList.vat')">
                    <label for="code">{{ $t("popupUserContractList.vat")}}</label>
                    <div class="invalid-feedback" v-if="errors.code">
                      {{ $t("popupUserContractList.errorVat")}}
                    </div>
                  </div>
            </div>
          </div>
          <div class="invalid-feedback" v-if="errors.errorNoContract">
                {{ $t("popupUserContractList.errorNoContract")}}
          </div>
          <div class="invalid-feedback" v-if="errors.errorNoConnect">
                {{ $t("popupUserContractList.errorNoConnect")}}
          </div>
          <div class="invalid-feedback" v-if="errors.errorServer">
                {{ $t("popupUserContractList.errorServer")}}
          </div>
          <div style="margin-top: 72px;margin-bottom: 75px;">
            <button type="submit" class="btn btn-big text-uppercase w-100 mt-3" :disabled="!validForm">{{ $t("popupUserContractList.continue")}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="c-popup" v-if="this.showAddContract == 2">
    <div class="c-popup-content" style="padding: 46px 0px 0px 0px;">
      <div style="width: 100%;">
        <span v-if="this.windowWidth < 480" @click="showAddContract = 1" style="cursor: pointer;margin-left: 12px;">
        <span style="color: #002626;font-size: 14px;">
          <img :src="icoArrowRight" alt="" style="width: 19px;
            height: auto;
            margin-right: 14px;
            transform: rotate(180deg);">
        </span>
        {{  $t('popupUserContractList.text_back') }}
      </span>
        <span v-if="this.windowWidth >= 480" @click="showAddContract = 1" style="cursor: pointer;margin-left: 56px;">
        <span style="color: #002626;font-size: 14px;">
          <img :src="icoArrowRight" alt="" style="width: 19px;
            height: auto;
            margin-right: 14px;
            transform: rotate(180deg);">
        </span>
        {{  $t('popupUserContractList.text_back') }}
      </span>
      <span v-if="this.windowWidth < 480" @click="this.$store.commit('showAddContract',false)" style="cursor: pointer;position: absolute;right: 12px;margin-top: 0px;">
          <img class="is-close" :src="$siteURL + '/build/frontend/images/ico-close.png'" />
        </span>
      <span v-if="this.windowWidth >= 480" @click="this.$store.commit('showAddContract',false)" style="cursor: pointer;position: absolute;right: 50px;">
          <img class="is-close" :src="$siteURL + '/build/frontend/images/ico-close.png'" />
        </span>
      </div>
      
      <div style="max-width: 370px;margin: auto;padding: 42px 12px 27px;display: flex;flex-direction: column;">

        <span style="font-size: 34px;color: #002626;">
          {{ $t('popupUserContractList.add_contract_confirm_title') }}        
        </span>
        <span style="font-size: 16px;font-weight: 300;color: #002626;margin-top: 20px;">
          {{ $t('popupUserContractList.add_contract_confirm_subtitle',{email: this.email}) }}        
        </span>
        <div style="height: 20px;"></div>
        <form @submit="confirm">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-floating">
                    <input type="number" class="form-control" :class="{'is-invalid': errors.otp}" id="otp" v-model="otp" :placeholder="$t('confirmPhone.otp')">
                    <label for="otp">{{ $t("popupUserContractList.otp")}}</label>
                    <div class="invalid-feedback" v-if="errors.otp">
                      {{ $t("popupUserContractList.errorOtp")}}
                    </div>
                    <div class="invalid-feedback" v-if="errors.errorServer">
                      {{ $t("popupUserContractList.errorServer")}}
                    </div>
                    <div class="setreset-text">{{ $t("popupUserContractList.textOtp")}}</div>
                  <div class="setreset-link" @click="resend">{{ $t("popupUserContractList.linkOtp")}}</div>
                  </div>
                  
                </div>
              </div>
              <button type="submit" class="btn btn-big text-uppercase w-100 mt-4" :disabled="!validReset">{{ $t("popupUserContractList.reset")}}</button>
          </form>
      </div>
    </div>
  </div>
  <div class="c-popup" v-if="this.showAddContract == 3">
    <div class="c-popup-content" style="padding: 46px 0px 0px 0px;">
      <div style="max-width: 370px;margin: auto;padding: 42px 12px 27px;display: flex;flex-direction: column;align-items: center;">

        <img src="../assets/ico_check_black.png" width="83" height="83" />
        <span style="font-size: 34px;color: #002626;margin-top: 39px;">
          {{ $t('popupUserContractList.add_contract_confirm_success_title') }}        
        </span>
        <span style="font-size: 16px;font-weight: 300;color: #002626;margin-top: 20px;">
          {{ $t('popupUserContractList.add_contract_confirm_success_subtitle') }}        
        </span>
        <div style="height: 30px;width: 100%;"></div>
        <button @click="successOnBack" class="btn btn-big text-uppercase w-100 mt-4">{{ $t("popupUserContractList.add_contract_confirm_success_back")}}</button>
        <div style="height: 30px;"></div>
      </div>
    </div>
  </div>
</template>
<script>
import icoArrowRight from '../assets/ico-arrow-right.png'

export default {
  name: 'PopupAddUserContract',
  props: {
  },
  data: function () {
    return {
      windowWidth: window.innerWidth,
      icoArrowRight: icoArrowRight,
      showAddContract:1,
      code:'',
      vat:'',
      email:'',
      vendorCustomerId:'',
      otp:'',
      errors:{
        code:false,
        vat:false,
        otp:false,
        errorServer:false,
        errorNoContract:false,
        errorNoConnect:false,
      }
    }
  },
  computed: {
    validForm(){
      let valid = true
      if(!this.code) {
        valid = false
      } else if(!this.vat) {
        valid = false
      }
      return valid
    },
    validReset(){
      let valid = true
      if(!this.otp) {
        valid = false
      }
      return valid
    }
  },
  methods: {
    addNewContract: function() {
        this.showAddContract = 1;
    },
    addContract: async function(ev) {
      ev.preventDefault();

      this.errors.code = false
      this.errors.vat = false
      this.errors.errorServer = false
      this.errors.errorNoContract = false;

      if (!this.code) {
        this.errors.code = true
      }

      if (!this.vat) {
        this.errors.vat = true
      }

      if (this.errors.code == false && this.errors.vat == false) {
        this.$store.commit('setLoader', true);

        await this.axios.get(process.env.VUE_APP_API_AUTH_URL + 'user-contracts?customerCode=' + this.code + '&vatFiscalCode=' + this.vat, this.authSetting)
        .then(async(response) =>   {

          if (response.data && Array.isArray(response.data) && response.data.length > 0) {

            var contract = response.data[0];

            var user = contract.user;

            if (user) {
              this.connectContract(user.vendorCustomerId,user.customerCode,user.vatFiscalCode,"");
            } else {
              this.errors.errorNoContract = true;
              this.$store.commit('setLoader', false);
            }

            console.log(contract,'_aa_xx');
          } else {
            this.errors.errorNoContract = true;
            this.$store.commit('setLoader', false);
          }

        }).catch(async() => {
          this.errors.errorServer = true;
          this.$store.commit('setLoader', false);
        });

        
      }
    },
    connectContract: async function(vendorCustomerId,customerCode,vatFiscalCode,email) {

      this.errors.errorNoConnect = false;

      await this.axios.patch(process.env.VUE_APP_API_AUTH_URL_API + 'user-contract/connect', {
        VendorCustomerId: vendorCustomerId,
        CustomerCode: customerCode,
        VatFiscalCode : vatFiscalCode,
        VerificationEmailAddress : email.length > 0 ? email : ""
      }, this.authSetting).then(async(response) => {       
        if (response.data.result) {
          this.code = customerCode;
          this.vat = vatFiscalCode;
          this.email = response.data.email
          this.vendorCustomerId = vendorCustomerId

          this.showAddContract = 2;
        } else {
          this.errors.errorNoConnect = true;
        }

        this.$store.commit('setLoader', false);
      }).catch(() => {
        this.errors.errorServer = true;
        this.$store.commit('setLoader', false)
      });
    },
    resend: function() {
      this.$store.commit('setLoader', true)
      this.connectContract(this.vendorCustomerId,this.code,this.vat,"");
    },
    confirm: async function(ev) {
      ev.preventDefault();

      this.errors.otp = false
      this.errors.errorServer = false

      if (!this.otp) {
        this.errors.otp = true
      }

      if (this.errors.otp == false) {
        this.$store.commit('setLoader', true)

        await this.axios.patch(process.env.VUE_APP_API_AUTH_URL_API + 'user-contract/verify-email', {
        VendorCustomerId: this.vendorCustomerId,
        CustomerCode: this.code,
        VatFiscalCode: this.vat,
        OTP: this.otp
      }, this.authSetting).then(async(response) => {       
        if (response.data.result) {
          this.code = ''
          this.vat = ''
          this.email = ''
          this.vendorCustomerId = ''
          this.otp = ''

          this.showAddContract = 3;
        } else {
          this.errors.errorServer = true;
        }

        this.$store.commit('setLoader', false);
      }).catch(() => {
        this.errors.errorServer = true;
        this.$store.commit('setLoader', false)
      });
      }
    },
    successOnBack: async function(ev) {
      ev.preventDefault();

      this.$store.commit('setLoader', true);
     

      await this.axios.get(process.env.VUE_APP_API_AUTH_URL + 'user-contracts', this.authSetting)
          .then((response) => {
            this.$store.commit('setUserContractList',response.data);

            if (response.data.length == 1) {
              this.showPopup = false;
              this.$store.commit('setUserContract',response.data[0]);
              this.$store.commit('setAccessTokenDWH',response.data[0].user.dwhAccessToken);
              this.$store.commit('showContractList', false);

              this.$router.go();
            } else {
              this.showAddContract = 0;
            }
            this.$store.commit('setLoader', false);
          }).catch(() => {
            this.$store.commit('setLoader', false);
          });
          this.$store.commit('showAddContract',false)
    }
  }
}
</script>
<style lang="scss" scoped>
.c-popup {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.5);
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    z-index: 1;
  }

  &-content {
    z-index: 2;
    position: relative;
    width: 100%;
    max-width: 630px;
    border-radius: 8px;
    border: solid 1px #dcdcdc;
    background-color: var(--white);
    max-height: 80%;
    overflow: auto;
    
    padding: 60px 20px;
    width: 90%;

    @include bp($md) {
      padding: 60px 100px;
    }
    
  }
  .setreset {
  &-link {
    
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    color: var(--black);
    text-decoration: underline;
    cursor: pointer;
  }

  &-text {
    margin: 35px 0 0;
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
    color: var(--title-color);
  }
}
}

</style>