<template>
  <div class="container">
    <div class="container-app">
      <div class="row">
          
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: 'Coupon',
  data: function () {
    return {
      code: '',
    }
  },
  computed: {
  components: {
   
  },
  methods: {
  }
  },
  created() {

  },
  mounted() {

    this.code = this.$route.params.code;

   

    if (this.code != null && this.code != undefined) {

      console.log(this.code,'_aa_xx');
     
      this.$store.commit('setCoupon', this.code)
      this.$router.push('/registrazione');
       
    } else {
      this.$router.push('/login');
    }

    
  }

}
</script>