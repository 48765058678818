<template>
  <div class="container">
    <div class="container-app">
      <div class="row">
        <div class="col-md-12 col-lg-6 offset-lg-3">
          <p>
            <span style="font-size: 24px;color:#002626;font-weight: 300;">
                {{ $t("autoReadingLogged.title") }}
            </span>
          </p>
          <p>
            <span style="font-size: 16px;color:#002626;font-weight: 300;">
                {{ this.$store.state.dashboard.selfReadingInfo ? this.$store.state.dashboard.selfReadingInfo.timeWindowsInfoMessage : ''}}
              </span>
          </p>
          <div v-if="this.windowWidth >= 480" style="display: flex;flex-direction: row;">
            <AutoReadingItem mode="ee" />
            <div style="width: 30px;"></div>
            <AutoReadingItem mode="gas" />
          </div>
          <div v-if="this.windowWidth < 480" style="display: flex;flex-direction: column;">
            <AutoReadingItem mode="ee" />
            <div style="height: 30px;"></div>
            <AutoReadingItem mode="gas" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <popup @close="() => this.closePopup()" v-if="$store.state.alertAutoReading" />
</template>

<script>
import AutoReadingItem from "@/components/AutoReadingItem";
import Popup from "@/components/PopupEnableReading";

export default {
  name: 'AutoReading',
  data: function () {
    return {
      windowWidth: window.innerWidth
    };
  },
  computed: {
    
  },
  components: {
    AutoReadingItem,
    Popup
  },
  methods: {
    closePopup: function() {
      this.$store.commit('setAlertAutoReading', "")
    }
  },
  created() {
    
  },
  mounted() {
    this.$store.commit('setAlertAutoReading', "")
  }
}
</script>
<style lang="scss" scoped>
.form-password {
  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    cursor: pointer;
  }
}

.container-app {
  @include bp($md) {
    padding: 40px 0 80px;
  }
}
</style>