<template>
  <div class="form-floating form-password">
    <img :src="iconShow" v-if="passwordType === 'password'" @click="passwordType = 'text'">
    <img :src="iconHide" v-if="passwordType === 'text'" @click="passwordType = 'password'">
    <input :type="passwordType" class="form-control" :class="{'is-invalid': error}" id="password" :placeholder="name" @input="update" :value="modelValue">
    <label for="password">{{ name }}</label>
    <div class="invalid-feedback" v-if="error">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import iconHide from '../assets/ico-hide.svg'
import iconShow from '../assets/ico-show.svg'
export default {
  name: "Password",
  emits: ['update:modelValue'],
  props: {
    modelValue: String,
    name: String,
    errorMessage: String,
    error: Boolean
  },
  methods: {
    update: function (ev) {
      this.$emit('update:modelValue', ev.target.value)
    }
  },
  data: function () {
    return {
      iconHide: iconHide,
      iconShow: iconShow,
      passwordType: 'password'
    }
  },
}
</script>

<style lang="scss" scoped>
.form-password {
  img {
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
  }
}
</style>