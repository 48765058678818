<template>
  <div class="content">
    <toast v-if="$store.state.toast.show" />
    <Header :type="$store.state.type" @onLoaded="headerLoaded = true" v-if="!routeNoHeaderFooter" />
    <div id="content" class="webapp" v-if="(headerLoaded && footerLoaded) || routeNoHeaderFooter">
      <router-view v-slot="{ Component, route }" :key="$route.path">
        <transition :name="route.meta.transition || 'fade'">
          <component :is="Component" />
        </transition>
      </router-view>
      <loader :open="$store.state.loader"></loader>
     
    </div>
    <Footer :type="$store.state.type" @onLoaded="footerLoaded = true" v-if="!routeNoHeaderFooter" />
 </div>

</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
import Loader from "@/components/Loader";

import Toast from "@/components/Toast";
export default {
  name: 'App',
  data: function () {
    return {
      headerLoaded: false,
      footerLoaded: false,
      routeNoHeaderFooter: false,
      routeNoHeaderFooterList: ["Maintenance"]
    }
  },
  watch:{
    $route (to, old){
      this.routeNoHeaderFooter = this.routeNoHeaderFooterList.includes(to.name);

      this.$store.commit('pushHistory', to.name)

      if (old.name === "Notification") {
        this.readNotifications()
      }
    }
  },
  components: {
    Toast,
    Loader,
    Header,
    Footer
  },
  methods: {
    checkNotifications() {
      if (this.$store.state.accessTokenDWH) {
        this.axios.get(process.env.VUE_APP_API_DWH_URL + 'unread-notifications', this.authDWHSetting)
          .then((response) => {
            this.$store.commit('setUnreadNotification', response.data.counter)
          }).catch(() => {});
      }
    },
    readNotifications() {
      if (this.$store.state.accessTokenDWH) {
        this.axios.post(process.env.VUE_APP_API_DWH_URL + 'set-notifications-read', { notification_id: '' }, this.authDWHSetting)
      }
    },
    setDeviceType() {
                    const platform = navigator.platform.toLowerCase();
                    if (/(android|webos|iphone|ipad|ipod|blackberry|windows phone)/.test(platform)) {
                        this.deviceType = 'mobile';
                    } else if (/mac|win|linux/i.test(platform)) {
                        this.deviceType = 'desktop';
                    } else if (/tablet|ipad/i.test(platform)) {
                        this.deviceType = 'mobile';
                    } else {
                        this.deviceType = 'desktop';
                    }
                }
  },
  created() {
    this.$store.commit('resetHistory')

    // Check if maintenance is active
    this.axios.get(process.env.VUE_APP_API_DWH_URL + 'apiMaintenance')
      .then((response) => {
        if (response.data.isUnderMaintenance) {
          this.$router.push({ name: 'Maintenance' })
        }
      }).catch(() => {});

    // Refresh token if is present
    if (this.$store.state.accessToken) {
      this.axios.post(process.env.VUE_APP_API_AUTH_URL + 'refresh-token', null, this.authSetting)
        .then((response) => {
          this.$store.commit('setAccessToken', response.data.refresh_token)
        }).catch(() => {
          this.$store.commit('reset')
          this.$router.push('/login')
        });
    }

    let headersDashboard = null
    if (this.$store.state.accessToken) {
      headersDashboard = this.authSetting
    }
    // Get dashboard data
    this.axios.get(process.env.VUE_APP_API_DWH_URL + 'dashboard', headersDashboard)
      .then((response) => {
        this.$store.commit('setDashboard', response.data)
      }).catch(() => {});
  },
  mounted() {
    this.checkNotifications()

    setInterval(() => {
      this.checkNotifications()
    }, 30000)
  }
}
</script>

<style lang="scss">
:root {
  --bs-danger: #ff1616;
  --title-color: #002626;
  --text-color: #4e4e4e;
  --primary-color: #2279c1;
  --gradient-chat: radial-gradient(circle at 100% 0, #2279c1, #f19711);
  --gradient-energy: linear-gradient(52deg, #e0c821 12%, #f19711 89%);
  --gradient-energy-reverse: linear-gradient(52deg, #f19711 12%, #e0c821 89%);
  --gradient-gas: linear-gradient(52deg, #53c6e0 12%, #2279c1 89%);
  --gradient-link: linear-gradient(99deg, #f16c21 1%, #009cbd 100%);
  --red: #f30000;
  --green: #009200;
  --payment-partial:#ffee33;
  --light-green: #00d10a;
  --light-red: #ff4252;
  --unread: #ff1c5e;
  --light-grey: #858585;
  --light-grey-2: #ababab;
  --light-grey-3: #d9d9d9;
  --grey-submit: #c1c1c1;
  --grey-submit-2: #737373;
  --dark-grey: #9f9f9f;
  --black: #000;
  --white: #fff;
  --grey: #f8f8f8;
  --grey2: #f5f5f5;
  --ee: #f19711;
  --gas: #2279c1;
  --toast-success: #b6ffc0;
  --toast-danger: #ffb6b6;
}

body, #app {
  min-height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.webapp {
  flex: 1;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
  border-color: var(--bs-danger);
}

.invalid-feedback {
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  color: var(--bs-danger);;
  padding: 0 15px;
  display: block;
}

.invalid-feedback-checkbox {
  padding: 0 15px 0 35px;
}

.form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-danger);;
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-danger);;
}

strong {
  font-weight: 700;
}

.form-floating-flex {
  .form-control {
    min-width: 0;
    width: 100%;
  }
}

.rl-description {
  margin-bottom: 20px;

  @include bp($md) {
    margin-bottom: 45px;
  }
}

.container-app {
  padding: 24px 0;

  @include bp($md) {
    padding: 65px 0 80px;
  }
}

.title-small {
  font-size: 27px;

  @include bp($md) {
    font-size: 34px;
  }

}
</style>

<style lang="scss">
.swiper {
  padding-bottom: 35px;

  .swiper-scrollbar {
    width: 100%!important;
    left: 0!important;
    height: 1px;
    background-color: #979797;
  }

  .swiper-scrollbar-drag {
    cursor: move;
    height: 3px;
    top: -1px;
    background-image: var(--gradient-energy);
  }
}
</style>