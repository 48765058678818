<template>
  <ul>
    <li :key="'tab-' + i" v-for="(l,i) in list" :class="{ 'is-active': activeIndex == i}" @click="select(i)">{{ l }}</li>
  </ul>
</template>

<script>
export default {
  name: 'TabHeader',
  props: ['list', 'activeIndex'],
  data: function () {
  },
  computed: {
    
  },
  methods: {
    select(index) {
      this.$emit('select', index)
    }
  },
}
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;

  li {
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    margin-right: 20px;
    color: var(--black);
    position: relative;
    margin-left: 5px;
    cursor: pointer;

    &.is-active {
      &::after {
        content: "";
        position: absolute;
        bottom: -3px;
        left: -5px;
        width: calc(100% + 10px);
        height: 10px;
        opacity: 0.27;
        background-image: var(--gradient-link);
        z-index: 1;
      }
    }

  }
}
</style>