<template>
  <div class="clear-bill">
    <div class="clear-bill-grey">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <subtitle>{{ $t("clearYourBill.title") }}</subtitle>
            <h3 class="clear-bill-subtitle">{{ $t("clearYourBill.subtitle") }}</h3>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-6 mb-3 mb-md-0">
                <div class="clear-bill-subtitle-small">{{ $t("clearYourBill.yourCode") }}</div>
                <div class="clear-bill-data">{{ '@'+(this.$store.state.currentUserContract != null ? this.$store.state.currentUserContract.user.bringFriendCouponCode : '') }}</div>
              </div>
              <div v-if="this.$store.state.dashboard.couponSectionVisible" class="col-6 text-md-end">
                <div class="clear-bill-subtitle-small">{{ $t("clearYourBill.activeFriends") }}</div>
                <div class="clear-bill-data">{{this.$store.state.currentUserContract != null ? this.$store.state.currentUserContract.user.bringFriendActiveSupplyPointTotal : 0 }}</div>
              </div>
              <div class="col-md-12 pt-2 pt-md-5">
                <div class="col-lg-4 mb-2">
                    <div class="clear-bill-subtitle-small">{{ $t("clearYourBill.share") }}</div>  
                  </div>
                <div class="row">
                 
                  <div class="col-lg-4 mb-2">
                    <c-button @click="() => fbShare($siteURL+'/coupon/'+(this.$store.state.currentUserContract != null ? this.$store.state.currentUserContract.user.bringFriendCouponCode : ''))"><template #icon><img :src="icoFacebook" /></template><template #text>{{ $t("clearYourBill.facebook") }}</template></c-button>
                  </div>
                  <div class="col-lg-4 mb-2">
                    <c-button @click="() => lnShare($siteURL+'/coupon/'+(this.$store.state.currentUserContract != null ? this.$store.state.currentUserContract.user.bringFriendCouponCode : ''))"><template #icon><img :src="icoLinkedin" /></template><template #text>{{ $t("clearYourBill.linkedin") }}</template></c-button>
                  </div>
                  <div class="col-lg-4 mb-2">
                    <c-button @click="() => copy($siteURL+'/coupon/'+(this.$store.state.currentUserContract != null ? this.$store.state.currentUserContract.user.bringFriendCouponCode : ''))"><template #icon><img :src="icoCopy" /></template><template #text>{{ $t("clearYourBill.copy") }}</template></c-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="this.$store.state.dashboard.couponSectionVisible && friends.length > 0" ref="friends" class="row">
          <div class="col-md-12 clear-bill-friends">
            <text-header>{{ $t("clearYourBill.activeFriends") }}</text-header>
          </div>
          <div class="col-md-6 col-xl-4" v-for="(f, i) in friends" :key="'friend-' + i">
            <friend :person="f" />
          </div>
        </div>
      </div>
    </div>
    <div class="clear-bill-how">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-xl-4">
            <h4 class="clear-bill-how-title"><span v-html="$t('clearYourBill.howItWorks1')"></span></h4>
            <div class="clear-bill-how-text"><span v-html="$t('clearYourBill.howItWorks2')"></span></div>
          </div>
          <div class="col-lg-12 col-xl-4">
            <h4 class="clear-bill-how-subtitle"><span v-html="$t('clearYourBill.howItWorks3')"></span></h4>
            <div class="clear-bill-how-desc"><span v-html="$t('clearYourBill.howItWorks4')"></span></div>

            <h4 class="clear-bill-how-subtitle"><span v-html="$t('clearYourBill.howItWorks5')"></span></h4>
            <div class="clear-bill-how-calc">
              <ul>
                <li>
                  <img src="../assets/ee.png"> {{ $t("clearYourBill.howItWorks6") }}
                </li>
                <li>
                  <img src="../assets/gas.png"> {{ $t("clearYourBill.howItWorks7") }}
                </li>
                <li>
                  {{ $t("clearYourBill.howItWorks8") }}
                  <span>{{ $t("clearYourBill.howItWorks9") }}</span>
                </li>
              </ul>
            </div>
            <div class="clear-bill-how-desc"><span v-html="$t('clearYourBill.howItWorks10')"></span></div>
          </div>
          <div class="col-lg-12 col-xl-4">
            <h4 class="clear-bill-how-subtitle"><span v-html="$t('clearYourBill.howItWorks11')"></span></h4>
            <ol class="clear-bill-how-list">
              <li><span v-html="$t('clearYourBill.howItWorks12')"></span></li>
              <li><span v-html="$t('clearYourBill.howItWorks13')"></span></li>
              <li><span v-html="$t('clearYourBill.howItWorks14')"></span></li>
            </ol>
          </div>
        </div>
        <div class="row justify-content-end mt-4">
          <div class="col-lg-12 col-xl-4 col-xl-offset-1">
            <h4 class="clear-bill-how-subtitle"><span v-html="$t('clearYourBill.howItWorks15')"></span></h4>
            <div class="clear-bill-how-desc"><span v-html="$t('clearYourBill.howItWorks16')"></span></div>
          </div>
          <div class="col-lg-12 col-xl-4">
            <h4 class="clear-bill-how-subtitle"><span v-html="$t('clearYourBill.howItWorks17')"></span></h4>
            <div class="clear-bill-how-desc"><span v-html="$t('clearYourBill.howItWorks18')"></span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import icoCheck from '../assets/ico-check.svg'
import icoFacebook from '../assets/ico-fb.svg'
import icoLinkedin from '../assets/ico-linkedin.svg'
import icoCopy from '../assets/ico-copy.svg'
import icoKo from '../assets/ico-ko.svg'
import Subtitle from "@/components/Subtitle";
import CButton from "@/components/CButton";
import TextHeader from "@/components/TextHeader";
import Friend from "@/components/Friend";
export default {
  name: 'ClearYourBill',
  components: {Friend, TextHeader, CButton, Subtitle},
  props: ['type'],
  data: function () {
    return {
      icoFacebook: icoFacebook,
      icoLinkedin: icoLinkedin,
      icoCopy: icoCopy,
      icoCheck: icoCheck,
      icoKo: icoKo,
      friends: [],
      limit: 9,
      offset: 0,
      end: false,
      isLoading: false
    }
  },
  methods: {
    copy: function(text){
      navigator.clipboard.writeText(text);
      this.$store.commit('setToast', {
        show: true,
        type: 'success',
        text: 'Link copiato con successo',
        timeout: 3000
      })
    },
    fbShare: function(text) {
      window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURI(text), '_blank').focus();
    },
    lnShare: function(text) {
      window.open('https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURI(text), '_blank').focus();
    },
    getData: async function(loader) {

      if (this.$store.state.dashboard.couponSectionVisible) {
          if (loader) {
            this.$store.commit('setLoader', true)
          }
          this.isLoading = true

          await this.axios.get(process.env.VUE_APP_API_DWH_URL + 'friends-list?limit=' + this.limit + '&offset=' + this.offset, this.authDWHSetting)
            .then((response) => {
              this.friends.push(...response.data.results)
              this.total = response.data.total

              if (this.friends.length >= this.total) {
                this.end = true
              }
            }).catch(() => {});

          this.isLoading = false

          if (loader) {
            this.$store.commit('setLoader', false)
          }
      }
    },
    handleScroll: function(){
      if (this.$refs.friends.getBoundingClientRect().bottom < window.innerHeight && !this.isLoading && !this.end) {
        this.getData()
      }
    },
  },
  mounted() {
    this.getData(true)
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>
<style lang="scss" scoped>
.clear-bill {
  &-grey {
    padding-top: 24px;
    padding-bottom: 24px;
    background-color: var(--grey);

    @include bp($md) {
      padding-top: 82px;
      padding-bottom: 44px;
    }
  }

  &-subtitle {
    margin: 27px 0;
    opacity: 0.5;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    color: var(--title-color);
    max-width: 370px;

    &-small {
      margin: 0 0 10px;
      opacity: 0.8;
      font-size: 12px;
      font-weight: bold;
      line-height: normal;
      color: var(--black);
      text-transform: uppercase;
    }
  }

  &-data {
    font-size: 20px;
    font-weight: bold;
    line-height: normal;
    color: var(--black);

    @include bp($md) {
      font-size: 27px;
    }

  }

  &-friends {
    margin: 50px 0 0;

    @include bp($md) {
      margin: 120px 0 20px;
    }
  }

  &-how {
    padding-top: 45px;
    padding-bottom: 60px;

    &-title {
      margin: 0 0 25px;
      font-size: 20px;
      font-weight: normal;
      line-height: normal;
      color: var(--title-color);
    }

    &-subtitle {
      margin: 0 0 15px;
      opacity: 0.8;
      font-size: 12px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: normal;
      color: var(--title-color);
      text-transform: uppercase;
    }

    &-text {
      margin: 0 0 38px;
      font-size: 16px;
      font-weight: 300;
      font-style: normal;
      line-height: normal;
      color: var(--title-color);
    }

    &-desc {
      margin: 0 0 20px;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--title-color);
      font-weight: 300;
    }

    &-list {
      margin: 0;
      padding: 0;
      font-size: 14px;
      font-weight: 300;
      line-height: normal;
      color: var(--title-color);
      list-style: none;
      counter-reset: clear-your-bill-counter;

      li {
        margin-bottom: 30px;
        counter-increment: clear-your-bill-counter;
        padding-left: 25px;
        position: relative;

        &::before {
          content: counter(clear-your-bill-counter);
          margin: 0;
          opacity: 0.8;
          font-size: 21px;
          font-weight: bold;
          line-height: 1;
          color: var(--dark-grey);
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    &-calc {
      background-color: var(--grey2);
      padding: 10px;
      display: inline-block;
      width: auto;
      margin-bottom: 20px;

      @include bp($xxl) {
        padding: 20px;
      }

      ul {
        display: flex;
        width: auto;
        margin: 0;
        align-items: center;
        font-size: 15px;
        font-weight: bold;
        line-height: 1;
        letter-spacing: normal;
        color: var(--black);
        list-style: none;

        @include bp($xl) {
          font-size: 17px;
        }

        @include bp($xxl) {
          font-size: 21px;
        }

      }

      li {
        display: flex;
        align-items: center;

        img {
          width: 28px;
          margin-right: 5px;

          @include bp($xxl) {
            width: 38px;
            margin-right: 10px;
          }

        }

        &:after {
          content: '+';
          display: block;
          opacity: 0.3;
          font-size: 21px;
          font-weight: bold;
          line-height: 1;
          color: var(--black);
          margin: 0 7px 0 12px;
        }

        &:nth-last-child(-n+2) {
          &:after {
            content: '=';
          }
        }

        &:last-child {
          &:after {
            display: none;
          }
        }

        span {
          margin: 0 0 0 6px;
          width: 56px;
          font-size: 10px;
          font-weight: normal;
          line-height: 1;
          letter-spacing: normal;
          color: var(--title-color);
          position: relative;
          padding-left: 10px;

          &:before {
            content: '/';
            display: block;
            opacity: 0.3;
            line-height: 1;
            margin: 0;
            font-size: 19px;
            font-weight: normal;
            color: var(--light-grey-2);
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }

    }
  }
}
</style>