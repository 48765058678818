<template>
  <div class="container">
    <div class="container-app">
      <div class="row">
        <div class="col-md-12">
          <div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas volutpat lectus felis, et mattis nulla posuere nec. Nulla facilisi. Suspendisse non blandit lectus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Fusce id aliquet nulla. Vestibulum vitae lacus cursus, finibus dolor quis, vestibulum nunc. Nulla in cursus neque. Aliquam quam lectus, facilisis gravida massa sed, accumsan suscipit orci. Fusce non arcu sodales, porttitor nibh id, luctus ante. Fusce eu ultrices erat.</p>
            <p>Donec iaculis fermentum lacus. Aenean ut nibh ullamcorper, suscipit justo sed, lobortis neque. Integer commodo lacus volutpat, ullamcorper diam mattis, bibendum sapien. Integer id aliquam nunc. Fusce tempus ex sit amet risus tristique, eget congue diam placerat. Cras sagittis bibendum neque, nec sodales nunc porta et. Quisque pulvinar odio augue, sit amet ultrices nulla rutrum non. Integer neque lectus, varius a semper sed, consequat nec ligula. Donec non pulvinar sem, et volutpat nulla. Duis a lectus mauris. Curabitur venenatis consectetur metus. Cras velit lorem, sollicitudin tincidunt sem quis, suscipit malesuada elit. Morbi tristique maximus ex, eu ornare est pretium tempor. Suspendisse faucibus commodo erat, ac posuere erat aliquam quis. Ut venenatis semper mauris, vitae dignissim elit pulvinar in.</p>
            <p>Sed iaculis feugiat erat quis consequat. Donec convallis ullamcorper nibh eu auctor. Duis arcu nulla, vulputate vel luctus et, tincidunt ut ligula. Donec luctus orci ac ipsum tincidunt maximus. Phasellus commodo, metus et viverra elementum, diam est rutrum magna, non vulputate erat est eu ligula. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Quisque non libero eget tellus ultrices pharetra. Duis ullamcorper bibendum mattis. Nullam mollis ante id scelerisque tempus. Vestibulum eget dui vel lorem tempor molestie vel ut dui. Fusce consectetur ex eu dolor hendrerit auctor. Pellentesque auctor id orci id maximus. Donec vel ipsum turpis.</p>
          </div>
          <button @click="logout" class="btn btn-primary">Logout</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'Logged',
  data: function () {
    return {
    }
  },
  methods: {
    logout: function(ev) {
      ev.preventDefault()
      this.$store.commit('reset')
      this.$router.push('/login')
    }
  },
  created() {

  },
  mounted() {
  }
}
</script>