<template>
  <div class="container">
    <div class="container-app">
      <div class="row">
        <div class="col-md-12 mb-3">
          <back />
        </div>
        <div class="col-md-12 col-lg-6 offset-lg-3">
          <div class="rl-box m-auto">
            <h2 class="title-small">{{ $t("editAddress.title")}}</h2>
            <p class="rl-description">{{ $t("editAddress.desc")}}</p>

            <form @submit="submit">
              <div class="row">
                <div class="col-md-12">
                  <accordion :title="$t('editAddress.addressPhysics')" :open="accordion === 1" @select="accordion = 1">
                    <div class="row">
                      <div class="col-md-12 mb-3">
                        <div class="form-floating">
                          <input type="text" class="form-control" :class="{'is-invalid': errors.address}" id="address" v-model="address" :placeholder="$t('editAddress.address')">
                          <label for="address">{{ $t("editAddress.address")}}</label>
                          <div class="invalid-feedback" v-if="errors.address">
                            {{ $t("editAddress.errorAddress")}}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mb-3">
                        <div class="form-floating">
                          <input type="text" class="form-control" :class="{'is-invalid': errors.number}" id="number" v-model="number" :placeholder="$t('editAddress.number')">
                          <label for="number">{{ $t("editAddress.number")}}</label>
                          <div class="invalid-feedback" v-if="errors.number">
                            {{ $t("editAddress.errorNumber")}}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mb-3">
                        <div class="form-floating">
                          <input type="text" class="form-control" :class="{'is-invalid': errors.city}" id="city" v-model="city" :placeholder="$t('editAddress.city')">
                          <label for="city">{{ $t("editAddress.city")}}</label>
                          <div class="invalid-feedback" v-if="errors.city">
                            {{ $t("editAddress.errorCity")}}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mb-3">
                        <div class="form-floating">
                          <input type="text" class="form-control" :class="{'is-invalid': errors.zip}" id="zip" v-model="zip" :placeholder="$t('editAddress.zip')">
                          <label for="zip">{{ $t("editAddress.zip")}}</label>
                          <div class="invalid-feedback" v-if="errors.zip">
                            {{ $t("editAddress.errorZip")}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </accordion>
                </div>
                <div class="col-md-12 mb-3">
                  <div class="separator"><span>{{ $t("editAddress.or") }}</span></div>
                </div>
                <div class="col-md-12 mb-3">
                  <accordion :title="$t('editAddress.addressOnline')" :open="accordion === 2" @select="accordion = 2">
                    <div class="row">
                      <div class="col-md-12 mb-3">
                        <div class="form-floating">
                          <input type="text" class="form-control" :class="{'is-invalid': errorsOnline.email}" id="email" v-model="email" :placeholder="$t('editAddress.email')">
                          <label for="email">{{ $t("editAddress.email")}}</label>
                          <div class="invalid-feedback" v-if="errorsOnline.email">
                            {{ $t("editAddress.errorEmail")}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </accordion>
                </div>
              </div>
              <button type="submit" class="btn btn-big text-uppercase w-100 mt-3" :disabled="!valid">{{ $t("editAddress.edit")}}</button>
            </form>

          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Back from "@/components/Back";
import Accordion from "@/components/Accordion";
export default {
  name: 'EditAddress',
  data: function () {
    return {
      accordion: this.$store.state.user.shippingTypeCode === 'MAIL' ? 2 : 1,
      address: '',
      number: '',
      city: '',
      zip: '',
      email: '',
      errors: {
        address: false,
        number: false,
        city: false,
        zip: false
      },
      errorsOnline: {
        email: false
      }
    }
  },
  computed: {
    valid(){
      let valid = true
      if (this.accordion === 1) {
        if(!this.address) {
          valid = false
        }
        if(!this.number) {
          valid = false
        }
        if(!this.city) {
          valid = false
        }
        if(!this.zip) {
          valid = false
        }
      } else {
        if(!this.email) {
          valid = false
        }
      }
      return valid
    },
    shippingAddress: function () {
      let address = ''
      if (this.accordion === 1) {
        address = this.address + ' ' + this.number + ' ' + this.city + ' ' + this.zip
      } else {
        address = this.email
      }
      return address
    }
  },
  components: {
    Accordion,
    Back
  },
  methods: {
    submit: function(ev) {
      ev.preventDefault()

      // Reset errors
      for (const errorsKey in this.errors) {
        this.errors[errorsKey] = false
      }
      for (const errorsKey in this.errorsOnline) {
        this.errors[errorsKey] = false
      }

      // Validation
      if (this.accordion === 1) {
        if(!this.address) {
          this.errors.address = true
        }
        if(!this.number) {
          this.errors.number = true
        }
        if(!this.city) {
          this.errors.city = true
        }
        if(!this.zip) {
          this.errors.zip = true
        }
      } else {
        if(!this.email) {
          this.errorsOnline.email = true
        }
      }

      let error = false
      if (this.accordion === 1) {
        for (const errorsKey in this.errors) {
          if (this.errors[errorsKey]) {
            error = true
          }
        }
      } else {
        for (const errorsKey in this.errorsOnline) {
          if (this.errorsOnline[errorsKey]) {
            error = true
          }
        }
      }

      if (!error) {
        this.$store.commit('setLoader', true)

        // Change bank
        this.axios.patch(process.env.VUE_APP_API_AUTH_URL + 'change-shipping-address', {
          ShippingAddress: this.shippingAddress
        }, this.authSetting).then(() => {
          this.axios.get(process.env.VUE_APP_API_AUTH_URL + 'user-profile', this.authSetting)
            .then((response) => {
              this.$store.commit('setUser', response.data)

              this.$store.commit('setToast', {
                show: true,
                type: 'success',
                text: this.$t('editAddress.confirmMessage'),
                timeout: 3000
              })
              this.$router.back()
              this.$store.commit('setLoader', false)
            }).catch(() => {});
        }).catch(() => {
          if (this.accordion === 1) {
            this.errors.address = true
            this.errors.number = true
            this.errors.city = true
            this.errors.zip = true
          } else {
            this.errorsOnline.email = true
          }
          this.$store.commit('setLoader', false)
        });
      }
    }
  },
  created() {
  },
  mounted() {
  }
}
</script>
<style lang="scss" scoped>
.form-password {
  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    cursor: pointer;
  }
}

.container-app {
  padding: 24px 0;

  @include bp($md) {
    padding: 40px 0 80px;
  }
}

.separator {
  margin: 24px 0;
  position: relative;
  border: none;
  text-align: center;
  height: auto;
  opacity: 0.5;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: normal;
  color: var(--title-color);

  &::before {
    content: '';
    opacity: .2;
    background-color: var(--light-grey);
    height: 5px;
    width: 100%;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }

  span {
    padding: 0 30px;
    background-color: var(--white);
    display: inline-block;
    position: relative;
    z-index: 1;
  }
}

</style>