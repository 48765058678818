<template>
  <div class="help">
    <div class="container">
      <div class="row pt-4">
        <div class="col-lg-12" style="margin-bottom: 20px;" v-if="$store.state.help.RequestTypeID || this.showback">
          <back />
        </div>
      </div>
      <div class="row help-row">
        <div class="col-lg-4 col-xl-4 mb-5 mb-lg-0">
          <subtitle>{{ $t("help.title") }}</subtitle>
          <h3 class="help-subtitle" v-html="$t('help.subtitle')"></h3>
          <ul class="help-contacts">
            <li v-if="$store.state.dashboard.contacts">
              <div class="help-contacts-title">{{ $t("help.number") }}</div>
              <div class="help-contacts-content">
                <a style="text-decoration: none;font-size: 24px;font-weight: normal;color: black;" :href="'tel:' + $store.state.dashboard.contacts.callCenterFreePhoneNumber">
                  {{ $store.state.dashboard.contacts.callCenterFreePhoneNumber }}
                </a>
               
              </div>
            </li>
            <li v-if="$store.state.dashboard.contacts">
              <div class="help-contacts-title">{{ $t("help.numberFromMobile") }}</div>
              <a style="text-decoration: none;font-size: 24px;font-weight: normal;color: black;" :href="'tel:' + $store.state.dashboard.contacts.callCenterPhoneNumberForMobiles">
                  {{ $store.state.dashboard.contacts.callCenterPhoneNumberForMobiles }}
                </a>
            </li>
            <li>
              <div class="help-contacts-title">{{ $t("help.chat") }}</div>
              <div class="help-contacts-content">{{ $t("footer.app.help_timeline_description") }}</div>
              <div class="help-contacts-hours">{{ $t("help.chatDays") }}</div>
            </li>
            <c-link v-if="$store.state.dashboard.chat.enabled">{{ $t("help.chatLink") }}</c-link>
          </ul>
        </div>
        <div class="col-lg-8 col-xl-6">
          <form class="help-form" method="post" action="" @submit="submit">
            <div class="help-form-title">{{ $t("help.formTitle") }}</div>
            <c-select v-model="argument" class="mb-md-5 mb-3" :error="errors.argument" :error-message="$t('help.errorArgument')" :disabled="$store.state.help.RequestTypeID">
              <option value="">{{ $t("help.formArgumentPlaceholder") }}</option>
              <template v-if="$store.state.help.hasOwnProperty('RequestTypeID')">
                <option :value="r.requestTypeID" v-for="r in $store.state.dashboard.requestTypes" :key="'request-' + r.requestTypeID">{{ r.description }}</option>
              </template>
              <template v-if="!$store.state.help.hasOwnProperty('RequestTypeID')">
                <option :value="c.complaintID" v-for="c in $store.state.dashboard.complaints" :key="'complaint-' + c.complaintID">{{ c.complaint }}</option>
              </template>
            </c-select>
            <c-textarea :error="errors.message" :error-message="$t('help.errorMessage')" v-model="message" :placeholder="$t('help.formProblemPlaceholder')" class="mb-4"></c-textarea>
            <div class="d-flex justify-content-end">
              <c-button :disabled="!argument || !message" type="form"><template #text>{{ $t("help.formSend") }}</template></c-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Subtitle from "@/components/Subtitle";
import CLink from "@/components/CLink";
import CSelect from "@/components/CSelect";
import CTextarea from "@/components/CTextarea";
import CButton from "@/components/CButton";
import Back from "@/components/Back";
export default {
  name: 'Help',
  components: {Back, CButton, CTextarea, CSelect, CLink, Subtitle},
  props: ['type'],
  data: function () {
    return {
      argument: '',
      message: '',
      errors: {
        argument: false,
        message: false
      },
      showback:false,
    }
  },
  methods: {
    submit: function(ev) {
      ev.preventDefault()
      this.errors.argument = false
      this.errors.message = false

      if (!this.argument) {
        this.errors.argument = true
      }

      if (!this.message) {
        this.errors.message = true
      }

      let error = false
      for (const errorsKey in this.errors) {
        if (this.errors[errorsKey]) {
          error = true
        }
      }

      if (!error) {
        this.$store.commit('setLoader', true)

        if (this.$store.state.help.RequestTypeID) {
          this.axios.post(process.env.VUE_APP_API_DWH_URL + 'send-request', {
            RequestTypeID: this.argument,
            Message: this.message,
            SupplyPoint: this.$store.state.help.SupplyPoint
          }, this.authDWHSetting)
            .then((response) => {
              if (response.data.result) {
                this.$store.commit('setToast', {
                  show: true,
                  type: 'success',
                  text: this.$t('help.messageSuccess'),
                  timeout: 3000
                })
                this.message = ''
              }
            }).catch(() => {
            this.$store.commit('setToast', {
              show: true,
              type: 'danger',
              text: this.$t('help.messageError'),
              timeout: 3000
            })
          }).finally(() => {
            this.$store.commit('setLoader', false)
          });
        } else {
          this.axios.post(process.env.VUE_APP_API_DWH_URL + 'send-complaint', { ComplaintID: this.argument, ComplaintText: this.message }, this.authDWHSetting)
            .then((response) => {
              if (response.data.result) {
                this.$store.commit('setToast', {
                  show: true,
                  type: 'success',
                  text: this.$t('help.messageSuccess'),
                  timeout: 3000
                })
                this.argument = ''
                this.message = ''
              }
            }).catch(() => {
            this.$store.commit('setToast', {
              show: true,
              type: 'danger',
              text: this.$t('help.messageError'),
              timeout: 3000
            })
          }).finally(() => {
            this.$store.commit('setLoader', false)
          });
        }
      }
    },
  },
  mounted() {
    if (this.$store.state.help.RequestTypeID) {
      this.argument = this.$store.state.help.RequestTypeID
    }

    this.showback = this.$route.query.show_back;

  },
}
</script>
<style lang="scss" scoped>
.help {
  background-color: var(--grey);
  padding: 0 0 24px;

  @include bp($md) {
    padding: 0 0 80px;
  }

  &-row {
    @include bp($md) {
      padding-top: 35px;
    }
  }

  &-subtitle {
    opacity: 0.5;
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    color: var(--title-color);
  }

  &-contacts {
    margin: 0;
    padding: 0;
    list-style: none;

    &-title {
      margin: 25px 0 12px;
      opacity: 0.72;
      font-size: 14px;
      font-weight: normal;
      line-height: 1;
      letter-spacing: normal;
      color: var(--black);
    }

    &-content {
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      color: var(--black);
    }

    &-hours {
      margin: 12px 0 18px;
      opacity: 0.48;
      font-size: 14px;
      line-height: 1;
      letter-spacing: normal;
      color: var(--black);
    }
  }

  &-form {
    &-title {
      opacity: 0.8;
      font-size: 12px;
      font-weight: bold;
      color: var(--title-color);
      text-transform: uppercase;
      margin: 0 0 25px;
    }
  }
}
</style>