<template>
  <div
    class="card autoreading"
    @click="goToAutoReading"
    :class="
    {
      'autoreading--gas': mode === 'gas',
      'autoreading--energy': mode === 'ee',
    }">

    <img v-if="mode === 'ee'" src="../assets/illustration-luce-white.png" class="card-img-top" alt="...">
    <img v-if="mode === 'gas'" src="../assets/illustration-gas-white.png" class="card-img-top" alt="...">

    <div class="card-body">
      <span style="font-size: 20px;font-weight: normal;color: white;" v-if="mode === 'ee'" class="card-title small-text">{{ $t("autoReading.eeTitle") }}</span>
      <span style="font-size: 20px;font-weight: normal;color: white;" v-if="mode === 'gas'" class="card-title small-text">{{ $t("autoReading.gasTitle") }}</span>
    </div>
    <div style="display: flex; flex-direction: row;margin-top: 62px;">
      <span style="font-size: 14px ; color: white;">{{ $t("autoReading.button") }}</span>
      <div style="width: 15px;"></div>
      <img src="../assets/ico-arrow-right-white.png" style="height: 19px; width: 19px; color: white;" alt="...">
    </div>
  </div>
</template>

<script>

export default {
  name: 'AutoReadingItem',
  props: {
    mode: {
      type: String,
      default: 'view'
    }
  },
  components: {
    
  },
  data: function () {
    
  },
  methods: {
    goToAutoReading: function() {

      if (this.mode === 'ee') {
        if (this.$store.state.dashboard.selfReadingInfo && !this.$store.state.dashboard.selfReadingInfo.eeIsAvailable) {
          this.$store.commit('setAlertAutoReading', "ee")
        } else {
          this.$router.push('/auto-reading-ee')
        }
      } else {
        if (this.$store.state.dashboard.selfReadingInfo && !this.$store.state.dashboard.selfReadingInfo.gasIsAvailable) {
          this.$store.commit('setAlertAutoReading', "gas")
        } else {
          this.$router.push('/auto-reading-gas')
        }
      }
    }
  },
  computed: {
    supplyType: function () {
      return this.mode === 'gas' ? this.$t('supply.supplyGas') : this.$t('supply.supplyEE')
    }
  }
}
</script>
<style lang="scss" scoped>
.autoreading {
  padding-top: 18px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 45px;
  border: none;
  color: #fff;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;

  &-icon {
    position: absolute;
    top: 25px;
    right: 25px;
    border-radius: 50%;
    padding: 2px;
    line-height: 1;
  }

  .card-img-top {
    width: 47px;
    height: 47px;
  }

  .card-body {
    padding: 20px 0 0;
  }

  &--energy {
    background-image: var(--gradient-energy);
  }

  &--gas {
    background-image: var(--gradient-gas);
  }
}
</style>