<template>
  <!--    footer private-->
  <footer v-if="type === 'private' && loaded === loadedCall" id="main-footer">
    <section class="app-stores" v-if="appBlock">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <p class="medium-text">{{ $t("footer.app.title") }}</p>
            <p class="small-text">{{ $t("footer.app.subtitle") }}</p>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="stores">
              <div class="app-store">
                <a href="https://apps.apple.com/us/app/luce-e-gas-italia/id6444408386" target="_blank">
                  <img src="../assets/app-store.png"/>
                </a>
              </div>
              <div class="play-store">
                <a href="https://play.google.com/store/apps/details?id=com.lucegas.app" target="_blank">
                  <img src="../assets/play-store.png"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="help private">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <div class="help-title">
              <h3 class="big-text">{{$t("footer.app.help_title")}}</h3>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 help-section">
            <div class="help-content">
              <p v-if="$store.state.dashboard.contacts && $store.state.dashboard.contacts.callCenterFreePhoneNumber" class="category m-0">{{$t("footer.app.help_green_number")}}</p>
              <p class="category"></p>
              <h3 v-if="$store.state.dashboard.contacts && $store.state.dashboard.contacts.callCenterFreePhoneNumber" class="big-text"><a :href="'tel:' + $store.state.dashboard.contacts.callCenterFreePhoneNumber">{{$store.state.dashboard.contacts.callCenterFreePhoneNumber}}</a></h3>
              </div>
          </div>
          <div class="col-lg-3 col-md-12 help-section">
            <div class="help-content">
              <p v-if="$store.state.dashboard.contacts && $store.state.dashboard.contacts.callCenterPhoneNumberForMobiles" class="category m-0">{{$t("footer.app.help_mobile_number")}}</p>
              <p class="category"></p>
              <h3 v-if="$store.state.dashboard.contacts && $store.state.dashboard.contacts.callCenterPhoneNumberForMobiles" class="big-text"><a :href="'tel:' + $store.state.dashboard.contacts.callCenterPhoneNumberForMobiles">{{$store.state.dashboard.contacts.callCenterPhoneNumberForMobiles}}</a></h3>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 help-section">
            <div class="help-content">
              <p v-if="$store.state.dashboard.contacts" class="category m-0">{{$t("footer.app.help_timeline_title")}}</p>
              <p class="category"></p>
              <h3 v-if="$store.state.dashboard.contacts" class="big-text">{{$t("footer.app.help_timeline_description")}}</h3>
              <p  v-if="$store.state.dashboard.contacts" class="category m-0">{{$t("footer.app.help_timeline_footer")}}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="container">
      <div class="footer-private footer-bottom">
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <div class="main-footer-navigation">
              <h5 class="footer-title-nav">{{snippetFooterItems.footer_menu_1}}</h5>
              <ul>
                <li v-for="(item, i) in snippetPrivateFooterItems.private_footer_menu_1" :key="item + i" class="links">
                  <a :href="item.menu_item_link">
                    <span class="big-text">
                      {{item.menu_item}}
                      <img :src="$siteURL + '/build/frontend/images/ico-arrow-right.png'" alt="">
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="offset-lg-2 col-lg-7 offset-md-0 col-md-12 offset-sm-0 col-sm-12">
            <div class="footer-bottom-navs">
            <div class="secondary-footer-navigation">
                <h5 class="footer-title-nav">{{snippetFooterItems.footer_menu_3 }}</h5>
                <ul>
                  <li v-for="(item, i) in thirdFooterItems" :key="item.id + i">
                    <a :href="item.url">
                      {{ item.title }}
                    </a>
                  </li>
                </ul>
              </div>
            
            <div class="contacts">
              <h5 class="footer-title-nav">Luce e Gas Italia SpA</h5>
              <p class="footer-contacts">
                {{snippetFooterItems.footer_menu_4_content}}
              </p>
              <div class="social-icons">
                <a :href="snippetFooterItems.facebook">
                  <img :src="$siteURL + '/build/frontend/images/ico-facebook.png'"/>
                </a>
                <a :href="snippetFooterItems.instagram">
                  <img :src="$siteURL + '/build/frontend/images/ico-ig.png'"/>
                </a>
                <a :href="snippetFooterItems.linkedin">
                  <img :src="$siteURL + '/build/frontend/images/ico-linkedin.png'"/>
                </a>
              </div>
            
          </div>
        </div>
          </div>
          
        </div>
      <!-- <div class="footer-info d-flex justify-content-between"> -->

      <!-- </div> -->
      </div>
    </div>
  </footer>

<!--  footer public-->

  <footer v-if="type === 'public' && loaded === loadedCall" id="main-footer">
    <section class="help">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <div class="help-title">
              <h3 class="big-text">{{$t("footer.app.help_title")}}</h3>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 help-section">
            <div class="help-content">
              <p v-if="$store.state.dashboard.contacts && $store.state.dashboard.contacts.callCenterFreePhoneNumber" class="category m-0">{{$t("footer.app.help_green_number")}}</p>
              <p class="category"></p>
              <h3 v-if="$store.state.dashboard.contacts && $store.state.dashboard.contacts.callCenterFreePhoneNumber" class="big-text"><a :href="'tel:' + $store.state.dashboard.contacts.callCenterFreePhoneNumber">{{$store.state.dashboard.contacts.callCenterFreePhoneNumber}}</a></h3>
              </div>
          </div>
          <div class="col-lg-3 col-md-12 help-section">
            <div class="help-content">
              <p v-if="$store.state.dashboard.contacts && $store.state.dashboard.contacts.callCenterPhoneNumberForMobiles" class="category m-0">{{$t("footer.app.help_mobile_number")}}</p>
              <p class="category"></p>
              <h3 v-if="$store.state.dashboard.contacts && $store.state.dashboard.contacts.callCenterPhoneNumberForMobiles" class="big-text"><a :href="'tel:' + $store.state.dashboard.contacts.callCenterPhoneNumberForMobiles">{{$store.state.dashboard.contacts.callCenterPhoneNumberForMobiles}}</a></h3>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 help-section">
            <div class="help-content">
              <p v-if="$store.state.dashboard.contacts" class="category m-0">{{$t("footer.app.help_timeline_title")}}</p>
              <p class="category"></p>
              <h3 v-if="$store.state.dashboard.contacts" class="big-text">{{$t("footer.app.help_timeline_description")}}</h3>
              <p  v-if="$store.state.dashboard.contacts" class="category m-0">{{$t("footer.app.help_timeline_footer")}}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="container">
      <div class="footer-bottom">
        <div class="row">
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="main-footer-navigation">
              <h5 class="footer-title-nav">{{snippetFooterItems.footer_menu_1}}</h5>
              <ul>
                <li v-for="(item, i) in mainFooterItems" :key="item.id + i">
                  <a :href="item.url"
                     title="{{ item.title }}">{{ item.title }}
                    <span class="icon">
                      <img :src="$siteURL + '/build/frontend/images/ico-arrow-right.png'"/>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="offset-lg-2 col-lg-7 offset-md-0 col-md-12 offset-sm-0 col-sm-12">
            <div class="footer-bottom-navs">
              <div class="secondary-footer-navigation">
                <h5 class="footer-title-nav">{{ snippetFooterItems.footer_menu_2 }}</h5>
                <ul>
                  <li v-for="(item, i) in secondFooterItems" :key="item.id + i">
                    <a :href="item.url">
                      {{ item.title }}
                    </a>
                  </li>
                </ul>
              </div>
              <div class="secondary-footer-navigation">
                <h5 class="footer-title-nav">{{snippetFooterItems.footer_menu_3 }}</h5>
                <ul>
                  <li v-for="(item, i) in thirdFooterItems" :key="item.id + i">
                    <a :href="item.url" title="{{ item.title }}">
                      {{ item.title }}
                    </a>
                  </li>
                </ul>
              </div>
              <div class="contacts">
                <h5 class="footer-title-nav">{{snippetFooterItems.footer_menu_4 }}</h5>
                <p class="footer-contacts">
                  {{snippetFooterItems.footer_menu_4_content}}
                </p>
                <div class="social-icons">
                  <a :href="snippetFooterItems.facebook">
                    <img :src="$siteURL + '/build/frontend/images/ico-facebook.png'"/>
                  </a>
                  <a :href="snippetFooterItems.instagram">
                    <img :src="$siteURL + '/build/frontend/images/ico-ig.png'"/>
                  </a>
                  <a :href="snippetFooterItems.linkedin">
                    <img :src="$siteURL + '/build/frontend/images/ico-linkedin.png'"/>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <chat v-if="$store.state.dashboard.chat && $store.state.dashboard.chat.enabled" />
</template>

<script>
import Chat from "@/components/Chat";
import mainFooterItemList from '../assets/json/main_footer_items_it.json'
import mainSubFooterItemList from '../assets/json/main_footer_items_sub_it.json'
import mainPrivateFooterItemList from '../assets/json/main_private_footer_items_it.json'
import secondaryItemList from '../assets/json/secondary_footer_items_it.json'
import thirdItemList from '../assets/json/third_footer_items_it.json'

export default {
  name: 'Footer',
  props: ['type'],
  emits: ['onLoaded'],
  data: function () {
    return {
      loaded: 0,
      loadedCall: 5,
      snippetFooterItems: '',
      mainFooterItems: '',
      secondFooterItems: '',
      thirdFooterItems: '',
      snippetPrivateFooterItems: '',
      blockAppPages: ['PersonalArea','Bill', 'Payments', 'Bills', 'Profile', 'ClearYourBill','ContractList','Notification','AutoReadingLogged','AutoReading','AutoReadingEE','AutoReadingGas']
    }
  },
  watch: {
    loaded: function(newVal) {
      if(newVal === this.loadedCall) {
        this.$emit('onLoaded', true)
        
      }
    }
  },
  computed: {
    appBlock: function () {
      if (this.blockAppPages.includes(this.$route.name)) {
        return true
      }
      return false
    }
  },
  components: {
    Chat
  },
  methods: {
    loadMenu: function() {
      this.snippetFooterItems = '';
      this.mainFooterItems = '';
      this.secondFooterItems = '';
      this.thirdFooterItems = '';
      this.snippetPrivateFooterItems = '';

      this.snippetFooterItems = mainFooterItemList.content;
      this.mainFooterItems = mainSubFooterItemList.items;
      this.secondFooterItems = secondaryItemList.items;
      this.thirdFooterItems = thirdItemList.items;
      this.snippetPrivateFooterItems = mainPrivateFooterItemList.content;
    }
  },
  created() {
    this.loadMenu();    
    this.loaded = 5
  },
  mounted() {
   
  }
}
</script>
<style lang="scss" scoped>
.app-stores {
  padding: 40px 0 50px;
  background: linear-gradient(265deg, rgba(160,217,198,1) 10%, rgba(21,182,202,1) 94%);
  color: #fff;

  .medium-text {
    margin-bottom: 8px;
  }

  .small-text {
    margin-bottom: 0;
  }

  .stores {
    margin-top: 20px;

    @include bp($lg) {
      display: flex;
      flex-wrap: wrap;
      margin-top: 0;
      justify-content: flex-end;
    }

    .play-store {
      margin-top: 10px;

      @include bp($lg) {
        margin-top: 0;
        margin-left: 33px;
      }
    }
  }
}
</style>