import { createRouter, createWebHistory } from 'vue-router'
import Register from "../components/Register";
import Login from "../components/Login";
import Logged from "../components/Logged";
import PersonalArea from "../components/PersonalArea";
import Maintenance from "@/components/Maintenance";
import store from '../store/store'
import Bill from "@/components/Bill";
import Bills from "@/components/Bills";
import Payments from "@/components/Payments";
import Profile from "@/components/Profile";
import ClearYourBill from "@/components/ClearYourBill";
import NotificationPage from "@/components/Notification";
import Help from "@/components/Help";
import ResetPassword from "@/components/ResetPassword";
import AutoReading from "@/components/AutoReading";
import SetResetPassword from "@/components/SetResetPassword";
import Success from "@/components/Success";
import ConfirmEmail from "@/components/ConfirmEmail";
import ConfirmPhone from "@/components/ConfirmPhone";
import EditPhone from "@/components/EditPhone";
import EditPassword from "@/components/EditPassword";
import EditPayment from "@/components/EditPayment";
import EditAddress from "@/components/EditAddress";
import EditEmail from "@/components/EditEmail";
import AutoReadingEE from "@/components/AutoReadingEE";
import AutoReadingGas from "@/components/AutoReadingGas";
import ContractList from "@/components/ContractList";
import AutoReadingLogged from "@/components/AutoReadingLogged";
import SSOLogin from "@/components/SSOLogin";
import Coupon from "@/components/Coupon";

const routes = [
    {
        path: '/contract-list',
        name: 'ContractList',
        component: ContractList,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/',
        name: 'PersonalArea',
        component: PersonalArea,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/registrazione',
        name: 'Registration',
        component: Register,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/sso/:code',
        name: 'SSOLogin',
        component: SSOLogin,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/coupon/:code',
        name: 'Coupon',
        component: Coupon,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/auto-reading-private',
        name: 'AutoReadingLogged',
        component: AutoReadingLogged,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/auto-reading',
        name: 'AutoReading',
        component: AutoReading,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/auto-reading-ee',
        name: 'AutoReadingEE',
        component: AutoReadingEE,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/auto-reading-gas',
        name: 'AutoReadingGas',
        component: AutoReadingGas,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/imposta-password',
        name: 'SetResetPassword',
        component: SetResetPassword,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/password-cambiata',
        name: 'PasswordChanged',
        component: Success,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/logged',
        name: 'Logged',
        component: Logged,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/maintenance',
        name: 'Maintenance',
        component: Maintenance,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/conferma-email',
        name: 'ConfirmEmail',
        component: ConfirmEmail,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/conferma-telefono',
        name: 'ConfirmPhone',
        component: ConfirmPhone,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/bolletta/:code/:id',
        name: 'Bill',
        component: Bill,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/pagamenti-fatture',
        name: 'Payments',
        component: Payments,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/bollette/:code/:year/:id',
        name: 'Bills',
        component: Bills,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/profilo',
        name: 'Profile',
        component: Profile,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/azzera-bolletta',
        name: 'ClearYourBill',
        component: ClearYourBill,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/assistenza',
        name: 'Help',
        component: Help,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/notifiche',
        name: 'Notification',
        component: NotificationPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/modifica-telefono',
        name: 'EditPhone',
        component: EditPhone,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/modifica-password',
        name: 'EditPassword',
        component: EditPassword,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/modifica-email',
        name: 'EditEmail',
        component: EditEmail,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/modifica-metodo-pagamento',
        name: 'EditPayment',
        component: EditPayment,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/modifica-indirizzo',
        name: 'EditAddress',
        component: EditAddress,
        meta: {
            requiresAuth: true
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior () {
        return {
            top: 0,
            behavior: 'smooth',
        };
    }
})

router.beforeEach((to, from) => {
    if ((from.name === 'Payments' && to.name === 'Bill') || (from.name === 'Bills' && to.name === 'Bill')) {
        store.commit('setShowHistory', false)
        store.commit('setShowBack', true)
    } else {
        store.commit('setShowHistory', true)
        store.commit('setShowBack', false)
    }

    if (to.name === 'Bill') {
    // if ((from.name !== 'Bills' && from.name !== 'Payments') && to.name === 'Bill') {
        store.commit('setActiveHome', true)
    } else {
        store.commit('setActiveHome', false)
    }

    if (from.name !== 'Bill' && to.name !== 'Help') {
        store.commit('setHelp', {})
    }

    if (from.name !== 'ResetPassword' && to.name !== 'SetResetPassword') {
        store.commit('setResetUsername', '')
    }

    if (to.meta.requiresAuth && !store.state.accessToken) {
        return {
            path: '/login'
        }
    }

    setTimeout(() => {
        store.commit('setToast', {
            show: false
        })
    }, 3000)

})

export default router