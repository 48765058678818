<template>
  <div class="chat" @click="open = !open">
    <img :src="icoHelp">
  </div>
  <div class="chat-popup" v-if="open">
    <img src="../assets/ico-close.png" @click="open = false">
    <iframe :src="$store.state.dashboard.chat.link"></iframe>
  </div>
</template>

<script>
import icoHelp from '../assets/ico-help.svg'
export default {
  name: 'Chat',
  data: function () {
    return {
      icoHelp: icoHelp,
      open: false
    }
  },
}
</script>
<style lang="scss" scoped>
.chat {
  border-radius: 50%;
  box-shadow: 0 0 10px 0 rgba(119, 119, 119, 0.5);
  background-image: var(--gradient-chat);
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  cursor: pointer;
  padding: 15px;

  @include bp($md) {
    padding: 25px;
  }

  img {
    width: 25px;

    @include bp($md) {
      width: 43px;
    }
  }

  &-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255, .8);
    z-index: 100;

    img {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }

    iframe {
      width: 90%;
      height: 90%;
      top: 5%;
      left: 5%;
      position: absolute;
    }
  }
}
</style>