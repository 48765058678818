<template>
  <div class="c-popup">
    <div class="c-popup-content" style="padding: 20px 0px 0px 0px;">
      <div style="width: 100%;">
        <span v-if="this.windowWidth < 480" @click="this.$store.commit('showInvoiceMenu',false)" style="cursor: pointer;position: absolute;right: 12px;">
          <img class="is-close" :src="$siteURL + '/build/frontend/images/ico-close.png'" />
        </span>
        <span v-if="this.windowWidth >= 480" @click="this.$store.commit('showInvoiceMenu',false)" style="cursor: pointer;position: absolute;right: 30px;">
          <img class="is-close" :src="$siteURL + '/build/frontend/images/ico-close.png'" />
        </span>
      </div>
      <div style="max-width: 450px;margin: auto;padding: 42px 12px 27px;display: flex;flex-direction: column;">

        <span v-if="this.windowWidth < 480" style="font-size: 18px;color: #002626;text-align: center;">
          {{ $t('menuInvoice.title') }}        
        </span>
        <span v-if="this.windowWidth >= 480" style="font-size: 20px;color: #002626;text-align: center;">
          {{ $t('menuInvoice.title') }}        
        </span>
        <div @click="this.openInvoicePDF(true)" :style="[
          code == 'EE' ?
          {'cursor':'pointer','display': 'flex','align-items': 'center','justify-content': 'center','width': '100%','margin-top': '30px','height': '50px','border-style': 'solid','border-width': '2px','border-color': '#f19711','border-radius': '4px'}
          :
          {'cursor':'pointer','display': 'flex','align-items': 'center','justify-content': 'center','width': '100%','margin-top': '30px','height': '50px','border-style': 'solid','border-width': '2px','border-color': '#2279C1','border-radius': '4px'}
          ]">
          <span :style="[code == 'EE' ? {'font-weight': 'bold','color': '#f19711'} : {'font-weight': 'bold','color': '#2279C1'}]">
            {{ $t('menuInvoice.button_1') }} 
          </span>
        </div>
        <div @click="this.openInvoicePDF(false)"
        :style="[
          code == 'EE' ?
          {'margin-bottom': '30px','cursor':'pointer','display': 'flex','align-items': 'center','justify-content': 'center','width': '100%','margin-top': '10px','height': '50px','border-style': 'solid','border-width': '2px','border-color': '#f19711','border-radius': '4px'}
          :
          {'margin-bottom': '30px','cursor':'pointer','display': 'flex','align-items': 'center','justify-content': 'center','width': '100%','margin-top': '10px','height': '50px','border-style': 'solid','border-width': '2px','border-color': '#2279C1','border-radius': '4px'}
          ]"
        >
          <span :style="[code == 'EE' ? {'font-weight': 'bold','color': '#f19711'} : {'font-weight': 'bold','color': '#2279C1'}]">
            {{ $t('menuInvoice.button_2') }} 
          </span>
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import icoArrowRight from '../assets/ico-arrow-right.png'

export default {
  name: 'PopupInvoiceMenu',
  props: {
    code: String,
    urlSmallPdf:String,
    urlPdf:String,
  },
  data: function () {
    return {
      windowWidth: window.innerWidth,
      icoArrowRight: icoArrowRight
    }
  },
  computed: {
  },
  methods: {
    openInvoicePDF: function(isSmall) {
      this.$store.commit('showInvoiceMenu',false);

      if (isSmall) {
        window.open(this.urlSmallPdf, '_blank');
      } else {
        window.open(this.urlPdf, '_blank');
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-popup {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.5);
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    z-index: 1;
  }

  &-content {
    z-index: 2;
    position: relative;
    width: 100%;
    max-width: 630px;
    border-radius: 8px;
    border: solid 1px #dcdcdc;
    background-color: var(--white);
    max-height: 80%;
    overflow: auto;
    
    padding: 60px 20px;
    width: 90%;

    @include bp($md) {
      padding: 60px 100px;
    }
    
  }
  .setreset {
  &-link {
    
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    color: var(--black);
    text-decoration: underline;
    cursor: pointer;
  }

  &-text {
    margin: 35px 0 0;
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
    color: var(--title-color);
  }
}
}

</style>