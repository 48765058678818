<template>
  <div class="notification-block">
    <span class="notification-block-unread" v-if="!notification.read"></span>
    <div class="notification-block-day">{{ $moment(notification.notificationDate).lang($store.state.locale).fromNow() }}</div>
    <div class="notification-block-text">{{ notification.message }}</div>
  </div>
</template>

<script>
export default {
  name: 'NotificationBlock',
  props: {
    notification: Object
  },
  data: function () {
    return {
    }
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.notification-block {
  position: relative;
  background-color: var(--white);
  padding: 20px;
  margin-bottom: 16px;

  &-day {
    opacity: 0.8;
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    color: var(--title-color);
    text-transform: uppercase;
  }

  &-text {
    margin: 8px 0 0;
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    color: var(--black);
  }

  &-unread {
    display: block;
    width: 12px;
    height: 12px;
    background-color: var(--unread);
    border-radius: 50%;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
</style>