<template>
  <div class="container">
    <div class="container-app">
      <div class="row">
        <div class="col-md-12 col-lg-6 offset-lg-3 mt-md-4">
          <div class="rl-box m-auto">
            <h2 class="title-small">{{ $t("confirmEmail.title")}}</h2>
            <p class="rl-description">{{ $t("confirmEmail.desc")}}</p>

            <form @submit="confirm">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-floating">
                    <input type="text" class="form-control" id="username" :value="$store.state.userRegistered.email" disabled>
                    <label for="username">{{ $t("confirmEmail.email")}}</label>
                  </div>
                </div>
                <div class="col-md-12">
                  <hr class="separator">
                </div>
                <div class="col-md-12">
                  <div class="form-floating">
                    <input type="number" class="form-control" :class="{'is-invalid': errors.otp}" id="otp" v-model="otp" :placeholder="$t('confirmEmail.otp')">
                    <label for="otp">{{ $t("confirmEmail.otp")}}</label>
                    <div class="invalid-feedback" v-if="errors.otp">
                      {{ $t("confirmEmail.errorOtp")}}
                    </div>
                  </div>
                  <div class="setreset-text">{{ $t("confirmEmail.textOtp")}}</div>
                  <div class="setreset-link" @click="resend">{{ $t("confirmEmail.linkOtp")}}</div>
                </div>
              </div>
              <button type="submit" class="btn btn-big text-uppercase w-100 mt-4" :disabled="!validReset">{{ $t("confirmEmail.reset")}}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'ConfirmEmail',
  data: function () {
    return {
      otp: '',
      errors: {
        otp: false
      },
    }
  },
  computed: {
    validReset(){
      let valid = true
      if(!this.otp) {
        valid = false
      }
      return valid
    }
  },
  methods: {
    resend: function() {
      if (this.$store.state.userRegistered.token) {
        let provisionalHeaders = {
          headers: {
            Authorization: 'Bearer ' + this.$store.state.userRegistered.token,
          }
        }
        this.$store.commit('setLoader', true)
        this.axios.post(process.env.VUE_APP_API_AUTH_URL + 'validate-email', {}, provisionalHeaders)
          .then(async () => {
            this.$store.commit('setToast', {
              show: true,
              type: 'success',
              text: this.$t('confirmEmail.codeSent'),
              timeout: 3000
            })
          }).finally(() => {
            this.$store.commit('setLoader', false)
          });
      } else {
        this.$router.push('/login')
      }
    },
    confirm: function(ev) {
      ev.preventDefault()

      // Reset errors
      for (const errorsKey in this.errors) {
        this.errors[errorsKey] = false
      }

      let provisionalHeaders = {
        headers: {
          Authorization: 'Bearer ' + this.$store.state.userRegistered.token,
        }
      }

      this.$store.commit('setLoader', true)
      this.axios.patch(process.env.VUE_APP_API_AUTH_URL + 'complete-email-validation', {
        OTP: this.otp
      }, provisionalHeaders).then(() => {
        this.axios.post(process.env.VUE_APP_API_AUTH_URL + 'validate-phone-number', {}, provisionalHeaders)
          .then(async () => {
            this.$router.push('/conferma-telefono')
          }).finally(() => {
            this.$store.commit('setLoader', false)
          });
      }).catch(() => {
        this.errors.otp = true
        this.$store.commit('setLoader', false)
      });

    }
  },
  created() {

  },
  mounted() {
  }
}
</script>
<style lang="scss" scoped>
.form-password {
  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    cursor: pointer;
  }
}

.container-app {
  @include bp($md) {
    padding: 40px 0 80px;
  }
}

.separator {
  border-color: var(--light-grey);
  opacity: .1;
  margin: 24px 0;
}

.setreset {
  &-link {
    margin: 5px 0 0;
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    color: var(--black);
    text-decoration: underline;
    cursor: pointer;
  }

  &-text {
    margin: 35px 0 0;
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
    color: var(--title-color);
  }
}
</style>