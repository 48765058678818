<template>
  <div class="container">
    <div class="container-app">
      <div class="row">
        <div class="col-md-12 mb-3">
          <back />
        </div>
        <div class="col-md-12 col-lg-6 offset-lg-3">
          <div class="rl-box m-auto">
            <h2 class="title-small">{{ $t("register.title")}}</h2>
            <p class="rl-description">{{ $t("register.desc")}}</p>

            <form @submit="register">
              <div class="row">
                <div class="col-md-12 mb-2">
                  <div class="form-floating">
                    <input type="text" class="form-control" style="text-transform: capitalize;" :class="{'is-invalid': errors.name}" id="name" v-model="name" :placeholder="$t('register.name')" :disabled="$store.state.register.name">
                    <label for="name">{{ $t("register.name")}}</label>
                    <div class="invalid-feedback" v-if="errors.name">
                      {{ $t("register.errorName")}}
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mb-2">
                  <div class="form-floating">
                    <input type="text" class="form-control" style="text-transform: capitalize;"  :class="{'is-invalid': errors.lastname}" id="vat" v-model="lastname" :placeholder="$t('register.lastname')" :disabled="$store.state.register.lastname">
                    <label for="lastname">{{ $t("register.lastname")}}</label>
                    <div class="invalid-feedback" v-if="errors.lastname">
                      {{ $t("register.errorLastname")}}
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-floating-inline">
                    <div class="form-floating form-floating-flex">
                      <input type="tel" class="form-control" :class="{'is-invalid': errors.prefix}" id="prefix" v-model="prefix" :placeholder="$t('register.prefix')" :disabled="$store.state.register.prefix">
                      <label for="prefix">{{ $t("register.prefix")}}</label>
                    </div>
                    <div class="form-floating form-floating-flex">
                      <input type="tel" v-on:blur="checkPhoneNumber" class="form-control" :class="{'is-invalid': errors.phone}" id="phone" v-model="phone" :placeholder="$t('register.phone')" :disabled="$store.state.register.phone">
                      <label for="phone">{{ $t("register.phone")}}</label>
                    </div>
                  </div>
                  <div class="invalid-feedback" v-if="errors.prefix || errors.phone">
                    {{ $t("register.mandatoryField")}}
                  </div>
                </div>
                <div class="col-md-12">
                  <hr class="separator">
                </div>
                <div class="col-md-12 mb-2">
                  <div class="form-floating">
                    <input type="text" class="form-control" style="text-transform:uppercase;" :class="{'is-invalid': errors.friendCode}" id="friendcode" v-model="friendCode" :placeholder="$t('register.friendcode')" :disabled="$store.state.register.friendcode">
                    <label for="name">{{ $t("register.friendcode")}}</label>
                    <div class="invalid-feedback" v-if="errors.friendCode">
                      {{ $t("register.errorVat")}}
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <hr class="separator">
                </div>
                <div class="col-md-12 mb-2">
                  <div class="form-floating">
                    <input type="text" class="form-control" :class="{'is-invalid': errors.email}" id="email" v-model="email" :placeholder="$t('register.email')">
                    <label for="email">{{ $t("register.email")}}</label>
                    <div class="invalid-feedback" v-if="errors.email">
                      {{ $t("register.errorEmail")}}
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-floating">
                    <input type="text" class="form-control" :class="{'is-invalid': errors.confirmEmail}" id="confirmEmail" v-model="confirmEmail" :placeholder="$t('register.confirmEmail')">
                    <label for="confirmEmail">{{ $t("register.confirmEmail")}}</label>
                    <div class="invalid-feedback" v-if="errors.confirmEmail">
                      {{ $t("register.errorConfirmEmail")}}
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <hr class="separator">
                </div>
                <div class="col-md-12 mb-2">
                  <div class="form-floating">
                    <input type="password" class="form-control" :class="{'is-invalid': errors.password}" id="password" v-model="password" :placeholder="$t('register.password')">
                    <label for="password">{{ $t("register.password")}}</label>
                    <div class="invalid-feedback" v-if="errors.password">
                      {{ $t("register.errorPassword")}}
                    </div>
                  </div>
                  <div class="setreset-text mb-3">{{ $t("register.textPassword")}}</div>
                  <div class="form-floating">
                    <input type="password" class="form-control" :class="{'is-invalid': errors.confirmPassword}" id="confirmPassword" v-model="confirmPassword" :placeholder="$t('register.confirmPassword')">
                    <label for="confirmPassword">{{ $t("register.confirmPassword")}}</label>
                    <div class="invalid-feedback" v-if="errors.formatConfirmPassword">
                      {{ $t("register.errorFormatConfirmPassword")}}
                    </div>
                    <div class="invalid-feedback" v-if="!errors.formatConfirmPassword && errors.confirmPassword">
                      {{ $t("register.errorConfirmPassword")}}
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <hr class="separator">
                </div>
                <div class="col-md-12 mb-3">
                  <template v-for="p in $store.state.dashboard.privacy.policyElements" :key="'policy-' + p.elementID">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" :class="{'is-invalid': errors.privacy[p.elementID]}" :id="'privacy-' + p.elementID" v-model="privacy[p.elementID]">
                      <label class="form-check-label" :for="'privacy-' + p.elementID">{{ p.acceptanceText }}</label><span class="privacy-link" @click="() => read(p.policy)">{{ p.linkText }}</span>
                    </div>
                    <div class="invalid-feedback invalid-feedback-checkbox" v-if="errors.privacy[p.elementID]">
                      {{ $t("register.errorMandatory")}}
                    </div>
                  </template>
                </div>
              </div>
              <div class="invalid-feedback" v-if="errors.apiUserExist">
                {{ $t("register.errorUserExists")}}
              </div>
              <button type="submit" class="btn btn-big text-uppercase w-100 mt-3">{{ $t("register.continue")}}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <popup @close="showPopup = false" v-if="showPopup">
      <template #text><div v-html="popupContent"></div></template>
    </popup>
  </div>

</template>

<script>
import Back from "@/components/Back";
import Popup from "@/components/Popup";
export default {
  name: 'ResetPassword',
  data: function () {
    let privacy = {}
    let errorPrivacy = {}
    this.$store.state.dashboard.privacy.policyElements.map((element) => {
      privacy[element.elementID] = false
      errorPrivacy[element.elementID] = false
    })

    return {
      popupContent: '',
      showPopup: false,
      code: this.$store.state.register.code,
      vat: this.$store.state.register.vat,
      name:'',
      lastname:'',
      friendCode: '',
      prefix: '+39',
      phone: '',
      email: this.$store.state.register.email,
      confirmEmail: '',
      password: '',
      confirmPassword: '',
      privacy: privacy,
      errors: {
        code: false,
        vat: false,
        name:false,
        lastname:false,
        friendCode:false,
        prefix: false,
        phone: false,
        email: false,
        confirmEmail: false,
        password: false,
        formatConfirmPassword:false,
        confirmPassword: false,
        apiUserExist: false,
        privacy: errorPrivacy
      },
    }
  },
  watch: {
    privacy: {
      handler(newValue) {
        for (const newValueKey in newValue) {
          if (newValue[newValueKey]) {
            this.errors.privacy[newValueKey] = false
          }
        }
      },
      deep: true
    }
  },
  computed: {
  },
  components: {
    Popup,
    Back
  },
  methods: {
    read: function(html) {
      this.popupContent = html
      this.showPopup = true
    },
    checkPhoneNumber() {
      this.phone = this.phone.split(" ").join("").trim();
      if (this.phone.trim().startsWith(this.prefix)) {
          this.phone = this.phone.replace(this.prefix,"");
      }
      
    },
    register: async function(ev) {
      ev.preventDefault()

      // Reset errors
      for (const errorsKey in this.errors) {
        if (typeof this.errors[errorsKey] === 'object') {
          for (const errorKey in this.errors[errorsKey]) {
            this.errors[errorsKey][errorKey] = false
          }
        } else {
          this.errors[errorsKey] = false
        }
      }

      // Validation
      if (!this.name) {
        this.errors.name = true
      }

      if (!this.lastname) {
        this.errors.lastname = true
      }


      if (!this.prefix) {
        this.errors.prefix = true
      }

      if (!this.phone) {
        this.errors.phone = true
      }

      if (this.email !== this.confirmEmail) {
        this.errors.confirmEmail = true
      }

      if(!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.email)) {
        this.errors.email = true
      }

      if (this.password !== this.confirmPassword) {
        this.errors.confirmPassword = true
      }

      if(!/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?!.*[^a-zA-Z0-9?=.*[*.!@$%^&:;<>,.?~_+-=|]).{8,20}/.test(this.password)) {
        this.errors.password = true
      }

      if(!/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?!.*[^a-zA-Z0-9?=.*[*.!@$%^&:;<>,.?~_+-=|]).{8,20}/.test(this.confirmPassword)) {
        this.errors.formatConfirmPassword = true
      }

      for (const privacyKey in this.privacy) {
        let policyElement = this.$store.state.dashboard.privacy.policyElements.find((el) => el.elementID == privacyKey)
        if (policyElement.mandatory && !this.privacy[policyElement.elementID]) {
          this.errors.privacy[policyElement.elementID] = true
        }
      }

      let error = false
      for (const errorsKey in this.errors) {
        if (typeof this.errors[errorsKey] === 'object') {
          for (const errorKey in this.errors[errorsKey]) {
            if (this.errors[errorsKey][errorKey]) {
              error = true
            }
          }
        } else {
          if (this.errors[errorsKey]) {
            error = true
          }
        }
      }

      if (!error) {
        this.$store.commit('setLoader', true)
        let privacyValues = []
        for (const elementID in this.privacy) {
          let obj = {
            PolicyElementID: elementID,
            PolicyElementValue: this.privacy[elementID]
          }
          privacyValues.push(obj)
        }

        await this.axios.post(process.env.VUE_APP_API_AUTH_URL + 'register', {
          Email: this.email,
          Password: this.password,
          PhoneNumber: this.prefix + '' + this.phone,
          Name: this.name,
          Surname: this.lastname,
          FriendCode:this.friendCode,
          PrivacyValues: privacyValues
        }).then(async (response) => {
          let provisionalToken = response.data.token
          let provisionalHeaders = {
            headers: {
              Authorization: 'Bearer ' + provisionalToken,
            }
          }
          this.$store.commit('setUserRegistered', {name:this.name,lastname:this.lastname,friendCode: this.friendCode, email: this.email, phone: this.prefix + '' + this.phone, password: this.password, token: provisionalToken })

          await this.axios.post(process.env.VUE_APP_API_AUTH_URL + 'validate-email', {}, provisionalHeaders)
          .then(async () => {
            this.$router.push('/conferma-email')
          });
        }).catch(() => {
          this.errors.apiUserExist = true
        });
        this.$store.commit('setLoader', false)
      }

    }
  },
  created() {
    if (!this.$store.state.register.email) {
      this.$router.push('/login')
    }
    
  },
  mounted() {
    this.friendCode = this.$store.state.coupon;
    
  }
}
</script>
<style lang="scss" scoped>
.form-password {
  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    cursor: pointer;
  }
}

.container-app {
  @include bp($md) {
    padding: 40px 0 80px;
  }
}

.separator {
  border-color: var(--light-grey);
  opacity: .1;
  margin: 24px 0;
}

.setreset {
  &-link {
    margin: 5px 0 0;
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    color: var(--black);
    text-decoration: underline;
    cursor: pointer;
  }

  &-text {
    margin: 35px 0 0;
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
    color: var(--title-color);
  }
}

.form-floating-inline {
  display: flex;

  > *:first-child {
    width: 30%;
  }

  > *:last-child {
    width: calc(70% + 1px);
    margin-left: -1px;
  }
}

.form-floating-flex {
  //flex: 1 1 auto;

  .form-control {
    min-width: 0;
    width: 100%;
  }
}

.privacy-link {
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  color: #002626;
  margin-left: 10px;
  display: block;
  cursor: pointer;
}
</style>