<template>
  <div class="profile-block">
    <h4 v-if="title" class="profile-block-title">{{ title }}</h4>
    <div v-if="text" class="profile-block-text">{{ text }}</div>
    <c-link @click="$emit('edit')" v-if="edit">{{ $t('profileBlock.edit') }}</c-link>
    <slot />
  </div>
</template>

<script>
import CLink from "@/components/CLink";
export default {
  name: 'ProfileBlock',
  components: {CLink},
  props: {
    title: String,
    text: String,
    edit: Boolean
  },
  data: function () {
    return {
    }
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.profile-block {
  background-color: var(--white);
  padding: 20px;

  &-title {
    margin: 0 0 12px;
    opacity: 0.8;
    font-size: 12px;
    font-weight: bold;
    color: var(--title-color);
    text-transform: uppercase;
  }

  &-text {
    margin: 0 0 25px;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: normal;
    color: var(--black);
  }
}
</style>