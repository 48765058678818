<template>
    <div class="container">
      <div class="row help-row">
        <div class="col-lg-4 col-xl-4 mb-5 mb-lg-0">
          <div class="is-mobile" style="height: 20px;"></div>
          <subtitle style="font-size: 24px;color: #002626;">{{ $t("autoReadingLogged.title") }}</subtitle>
          <div style="height: 23px;"></div>
          <div>
              <span style="font-size: 16px;color:#002626;font-weight: 300;">
                {{ this.$store.state.dashboard.selfReadingInfo ? this.$store.state.dashboard.selfReadingInfo.timeWindowsInfoMessage : ''}}
              </span>
              <!-- <div style="height: 23px;"></div>
              <span style="font-size: 16px;color: #002626;font-weight: 300;">
              {{ this.$store.state.dashboard.selfReadingInfo ? this.$store.state.dashboard.selfReadingInfo.timeWindowsInfoMessage : ''}}
              </span> -->
            </div>
        </div>
        <div class="col-lg-8 col-xl-6">
          <ul v-if="this.supplies.length > 0" style="padding-bottom: 30px;">
            <li v-for="item in this.supplies" :key="item.SupplyPoint" style="border-radius: 5px;padding-bottom: 20px;">
              <supply-auto-reading :supply="item" v-if="item.active" /> 
            </li>
          </ul>
       <div style="height: 97px;"></div>
        </div>
      </div>
    </div>
    <popup @close="() => this.closePopup()" v-if="$store.state.alertAutoReading" />
</template>

<script>
import Subtitle from "@/components/Subtitle";
import SupplyAutoReading from "@/components/SupplyAutoReading";
import Popup from "@/components/PopupEnableReading";

export default {
  name: 'AutoReadingLogged',
  components: {
    Subtitle,
    SupplyAutoReading,
    Popup
  },
  props: ['type'],
  data: function () {
    return {
      supplies: [],
    }
  },
  methods: {
    closePopup: function() {
      this.$store.commit('setAlertAutoReading', "")
    }
  },
  async created() {

    this.$store.commit('setLoader', true);

    await this.axios.get(process.env.VUE_APP_API_DWH_URL + 'supply-points', this.authDWHSetting)
        .then((response) => {
          this.supplies = response.data
          this.$store.commit('setLoader', false);
        }).catch(() => {
          this.$store.commit('setLoader', false);
        });
  },
  mounted() {
    
  }
}
</script>
<style lang="scss" scoped>
.help {
  background-color: var(--grey);
  padding: 0 0 24px;

  @include bp($md) {
    padding: 0 0 80px;
  }

  &-row {
    @include bp($md) {
      padding-top: 35px;
    }
  }

  &-subtitle {
    opacity: 0.5;
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    color: var(--title-color);
  }

  &-contacts {
    margin: 0;
    padding: 0;
    list-style: none;

    &-title {
      margin: 25px 0 12px;
      opacity: 0.72;
      font-size: 14px;
      font-weight: normal;
      line-height: 1;
      letter-spacing: normal;
      color: var(--black);
    }

    &-content {
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      color: var(--black);
    }

    &-hours {
      margin: 12px 0 18px;
      opacity: 0.48;
      font-size: 14px;
      line-height: 1;
      letter-spacing: normal;
      color: var(--black);
    }
  }

  &-form {
    &-title {
      opacity: 0.8;
      font-size: 12px;
      font-weight: bold;
      color: var(--title-color);
      text-transform: uppercase;
      margin: 0 0 25px;
    }
  }
}
</style>