<template>
  <div class="container">
    <div class="container-app">
      <div class="row">
        <div class="col-md-12 mb-3">
          <back />
        </div>
        <div class="col-md-12 col-lg-6 offset-lg-3">
          <div class="rl-box m-auto">
            <h2 class="title-small">{{ $t("editPassword.title")}}</h2>
            <p class="rl-description">{{ $t("editPassword.desc")}}</p>

            <form @submit="submit">
              <div class="row">
                <div class="col-md-12">
                  <password :name="$t('editPassword.password')" :error="errors.password" :error-message="$t('editPassword.errorPassword')" v-model="password" />
                </div>
                <div class="col-md-12">
                  <hr class="separator">
                </div>
                <div class="col-md-12">
                  <password :name="$t('editPassword.newPassword')" :error="errors.newPassword" :error-message="$t('editPassword.errorNewPassword')" v-model="newPassword" />
                  <div class="setreset-text">{{ $t("editPassword.textPassword")}}</div>
                  <password :name="$t('editPassword.confirmPassword')" :error="errors.confirmPassword" :error-message="$t('editPassword.errorConfirmPassword')" v-model="confirmPassword" />
                </div>
              </div>
              <button type="submit" class="btn btn-big text-uppercase w-100 mt-3" :disabled="!valid">{{ $t("editPassword.edit")}}</button>
            </form>

          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Back from "@/components/Back";
import Password from "@/components/Password";
export default {
  name: 'EditPassword',
  data: function () {
    return {
      password: '',
      newPassword: '',
      confirmPassword: '',
      errors: {
        password: false,
        newPassword: false,
        confirmPassword: false,
      }
    }
  },
  computed: {
    valid(){
      let valid = true
      if(!this.password) {
        valid = false
      }
      if(!this.newPassword) {
        valid = false
      }
      if(!this.confirmPassword) {
        valid = false
      }
      return valid
    }
  },
  components: {
    Password,
    Back
  },
  methods: {
    submit: function(ev) {
      ev.preventDefault()

      // Reset errors
      for (const errorsKey in this.errors) {
        this.errors[errorsKey] = false
      }

      // Validation
      if(!/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?!.*[^a-zA-Z0-9?=.*[*.!@$%^&:;<>,.?~_+-=|]).{8,20}/.test(this.password)) {
        this.errors.password = true
      }

      if(!/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?!.*[^a-zA-Z0-9?=.*[*.!@$%^&:;<>,.?~_+-=|]).{8,20}/.test(this.newPassword)) {
        this.errors.newPassword = true
      }

      if (this.newPassword !== this.confirmPassword) {
        this.errors.confirmPassword = true
      }

      let error = false
      for (const errorsKey in this.errors) {
        if (this.errors[errorsKey]) {
          error = true
        }
      }

      if (!error) {
        this.$store.commit('setLoader', true)

        this.axios.post(process.env.VUE_APP_API_AUTH_URL + 'login', {
          "Email": this.$store.state.user.email,
          "Password": this.password
        }).then(async (response) => {
          if (response.data.tokenType === 'AccessToken') {
            await this.updatePassword()
          } else {
            this.errors.password = true
            this.$store.commit('setLoader', false)
          }
        }).catch(() => {
          this.errors.password = true
          this.$store.commit('setLoader', false)
        });
      }
    },
    updatePassword: function(){
      // Change password
      this.axios.patch(process.env.VUE_APP_API_AUTH_URL + 'change-password', {
        NewPassword: this.newPassword
      }, this.authSetting).then(() => {
        this.$store.commit('setToast', {
          show: true,
          type: 'success',
          text: this.$t('editPassword.confirmMessage'),
          timeout: 3000
        })
        this.$router.back()
      }).catch(() => {
        this.errors.newPassword = true
      }).finally(() => {
        this.$store.commit('setLoader', false)
      });
    }
  },
  created() {

  },
  mounted() {
  }
}
</script>
<style lang="scss" scoped>
.form-password {
  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    cursor: pointer;
  }
}

.container-app {
  padding: 24px 0;

  @include bp($md) {
    padding: 40px 0 80px;
  }
}

.separator {
  border-color: var(--light-grey);
  opacity: .1;
  margin: 24px 0;
}

.form-floating-inline {
  display: flex;

  > *:first-child {
    width: 30%;
  }

  > *:last-child {
    width: calc(70% + 1px);
    margin-left: -1px;
  }
}

.form-floating-flex {
  //flex: 1 1 auto;

  .form-control {
    min-width: 0;
    width: 100%;
  }
}

.setreset {
  &-link {
    margin: 5px 0 0;
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    color: var(--black);
    text-decoration: underline;
    cursor: pointer;
  }

  &-text {
    margin: 15px 0 25px;
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
    color: var(--title-color);
  }
}

</style>