<template>
  <div class="c-textarea" :class="{ 'c-textarea--error': error }">
    <textarea ref="textarea" @input="input" :value="modelValue"></textarea>
    <div class="c-textarea-error" v-if="error">{{ errorMessage }}</div>
  </div>
</template>

<script>
export default {
  name: 'CTextarea',
  props: {
    modelValue: String,
    errorMessage: String,
    error: Boolean,
  },
  emits: ['update:modelValue'],
  methods: {
    input: function(){
      this.$emit('update:modelValue', this.$refs.textarea.value)
    }
  },
  data: function () {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.c-textarea {
  &-error {
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    color: var(--bs-danger);
    padding: 0 15px;
    margin-top: 5px;
  }

  textarea {
    position: relative;
    padding: 15px;
    background-color: var(--white);
    outline: none!important;
    min-height: 320px;
    display: block;
    width: 100%;
    border: solid 1px var(--light-grey-3);
  }

  &--error {
    textarea {
      border-color: var(--bs-danger);
    }
  }

}
</style>