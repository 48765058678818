<template>
  <div class="container">
    <div class="container-app">
      <div class="row">
        <div class="col-md-12 mb-3">
          <back />
        </div>
        <div class="col-md-12 col-lg-6 offset-lg-3">
          <div class="rl-box m-auto">
            <h2 class="title-small">{{ $t("editEmail.title")}}</h2>
            <p class="rl-description" v-if="step == 1">{{ $t("editEmail.desc")}}</p>

            <form @submit="submit" v-if="step == 1">
              <div class="row">
                <div class="col-md-12">
                  <password :name="$t('editEmail.password')" :error="errors.password" :error-message="$t('editEmail.errorPassword')" v-model="password" />
                </div>
                <div class="col-md-12">
                  <hr class="separator">
                </div>
                <div class="col-md-12 mb-3">
                  <div class="form-floating">
                    <input type="email" class="form-control" :class="{'is-invalid': errors.email}" id="email" v-model="email" :placeholder="$t('editEmail.email')">
                    <label for="email">{{ $t("editEmail.email")}}</label>
                    <div class="invalid-feedback" v-if="errors.email">
                      {{ $t("editEmail.errorEmail")}}
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-floating">
                    <input type="email" class="form-control" :class="{'is-invalid': errors.confirmEmail}" id="confirmEmail" v-model="confirmEmail" :placeholder="$t('editEmail.confirmEmail')">
                    <label for="confirmEmail">{{ $t("editEmail.confirmEmail")}}</label>
                    <div class="invalid-feedback" v-if="errors.confirmEmail">
                      {{ $t("editEmail.errorConfirmEmail")}}
                    </div>
                  </div>
                </div>

              </div>
              <button type="submit" class="btn btn-big text-uppercase w-100 mt-3" :disabled="!validReset">{{ $t("editEmail.edit")}}</button>
            </form>

            <form @submit="confirm" v-if="step == 2">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-floating">
                    <input type="text" class="form-control" id="username" :value="email" disabled>
                    <label for="username">{{ $t("editEmail.email")}}</label>
                  </div>
                </div>
                <div class="col-md-12">
                  <hr class="separator">
                </div>
                <div class="col-md-12">
                  <div class="form-floating">
                    <input type="number" class="form-control" :class="{'is-invalid': errorsConfirm.otp}" id="otp" v-model="otp" :placeholder="$t('editEmail.otp')">
                    <label for="otp">{{ $t("editEmail.otp")}}</label>
                    <div class="invalid-feedback" v-if="errorsConfirm.otp">
                      {{ $t("editEmail.errorOtp")}}
                    </div>
                  </div>
                  <div class="setreset-text">{{ $t("editEmail.textOtp")}}</div>
                  <div class="setreset-link" @click="() => sendOtp(true)">{{ $t("editEmail.linkOtp")}}</div>
                </div>
              </div>
              <button type="submit" class="btn btn-big text-uppercase w-100 mt-4" :disabled="!validOtp">{{ $t("editEmail.confirm")}}</button>
            </form>

          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Back from "@/components/Back";
import Password from "@/components/Password";
export default {
  name: 'EditEmail',
  data: function () {
    return {
      step: 1,
      password: '',
      email: '',
      confirmEmail: '',
      otp: '',
      errors: {
        password: false,
        email: false,
        confirmEmail: false,
      },
      errorsConfirm: {
        otp: false
      },
    }
  },
  computed: {
    validReset(){
      let valid = true
      if(!this.password) {
        valid = false
      }
      if(!this.email) {
        valid = false
      }
      if(!this.confirmEmail) {
        valid = false
      }
      return valid
    },
    validOtp(){
      let valid = true
      if(!this.otp) {
        valid = false
      }
      return valid
    }
  },
  components: {
    Password,
    Back
  },
  methods: {
    submit: function(ev) {
      ev.preventDefault()

      // Reset errors
      for (const errorsKey in this.errors) {
        this.errors[errorsKey] = false
      }

      // Validation
      if (this.email !== this.confirmEmail) {
        this.errors.confirmEmail = true
      }

      if(!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.email)) {
        this.errors.email = true
      }

      if(!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/.test(this.password)) {
        this.errors.password = true
      }

      let error = false
      for (const errorsKey in this.errors) {
        if (this.errors[errorsKey]) {
          error = true
        }
      }

      if (!error) {
        this.$store.commit('setLoader', true)

        this.axios.post(process.env.VUE_APP_API_AUTH_URL + 'login', {
          "Email": this.$store.state.user.email,
          "Password": this.password
        }).then(async (response) => {
          if (response.data.tokenType === 'AccessToken') {
            await this.sendOtp()
          } else {
            this.errors.password = true
            this.$store.commit('setLoader', false)
          }
        }).catch(() => {
          this.errors.password = true
          this.$store.commit('setLoader', false)
        });
      }
    },
    confirm: function(ev){
      ev.preventDefault()
      this.$store.commit('setLoader', true)
      this.errorsConfirm.otp = false

      // Confirm phone number by OTP
      this.axios.patch(process.env.VUE_APP_API_AUTH_URL + 'complete-change-email', {
        OTP: this.otp
      }, this.authSetting).then(() => {
        this.axios.get(process.env.VUE_APP_API_AUTH_URL + 'user-profile', this.authSetting)
          .then((response) => {
            this.$store.commit('setUser', response.data)

            this.$store.commit('setToast', {
              show: true,
              type: 'success',
              text: this.$t('editEmail.confirmMessage'),
              timeout: 3000
            })
            this.$router.back()
            this.$store.commit('setLoader', false)
          }).catch(() => {});

      }).catch(() => {
        this.errorsConfirm.otp = true
        this.$store.commit('setLoader', false)
      });
    },
    sendOtp: function(loader){
      if (loader) {
        this.$store.commit('setLoader', true)
      }

      // Change phone number
      this.axios.patch(process.env.VUE_APP_API_AUTH_URL + 'change-email', {
        NewEmail: this.email
      }, this.authSetting).then(() => {
        this.step = 2
      }).catch(() => {
        this.errors.email = true
      }).finally(() => {
        this.$store.commit('setLoader', false)
      });
    }
  },
  created() {

  },
  mounted() {
  }
}
</script>
<style lang="scss" scoped>
.form-password {
  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    cursor: pointer;
  }
}

.container-app {
  padding: 24px 0;

  @include bp($md) {
    padding: 40px 0 80px;
  }
}

.separator {
  border-color: var(--light-grey);
  opacity: .1;
  margin: 24px 0;
}

.form-floating-inline {
  display: flex;

  > *:first-child {
    width: 30%;
  }

  > *:last-child {
    width: calc(70% + 1px);
    margin-left: -1px;
  }
}

.form-floating-flex {
  //flex: 1 1 auto;

  .form-control {
    min-width: 0;
    width: 100%;
  }
}

.setreset {
  &-link {
    margin: 5px 0 0;
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    color: var(--black);
    text-decoration: underline;
    cursor: pointer;
  }

  &-text {
    margin: 35px 0 0;
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
    color: var(--title-color);
  }
}

</style>