<template>
  <div class="c-toast" :class="'c-toast--' + $store.state.toast.type">
    <div class="c-toast-text">
      <img :src="ico">
      {{ $store.state.toast.text }}
    </div>
  </div>
</template>

<script>
import icoCheck from '../assets/illustration-check.svg'
import icoDanger from '../assets/illustration-danger.svg'
export default {
  name: 'Toast',
  data: function () {
    return {
      ico: this.$store.state.toast.type === 'success' ? icoCheck : icoDanger
    }
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.c-toast {
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  color: var(--title-color);
  max-width: 1140px;
  width: calc(100% - 20px);
  position: absolute;
  top: 90px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  text-align: center;
  padding: 20px;
  border-radius: 8px;

  @include bp($sm) {
    max-width: 540px;
  }

  @include bp($md) {
    max-width: 720px;
  }

  @include bp($lg) {
    max-width: 960px;
  }

  @include bp($xl) {
    max-width: 1140px;
  }

  @include bp($xxl) {
    max-width: 1320px;
  }

  img {
    margin-right: 10px;
  }

  &--success {
    background-color: var(--toast-success);
  }

  &--danger {
    background-color: var(--toast-danger);
  }
}
</style>