const messages = {
  it: {
    header: {
      logoutText: 'Sei sicuro di voler uscire?',
      logoutBtn: 'Esci',
      menu_notification: 'Notifiche',
      menu_cod_cliente: 'cod. cliente',
      menu_contract_list: 'I tuoi contratti',
      menu_contract_logout: 'Esci',
    },
    footer: {
      app: {
        title: 'Tieni sotto controllo la tua energia, dove e quando vuoi',
        subtitle: 'Scarica la nostra app sul tuo telefono.',
        help_title: 'Siamo qui per aiutarti',
        help_green_number: 'Numero verde',
        help_mobile_number: 'Oppure da cellulare',
        help_timeline_title: 'Orari servizio clienti',
        help_timeline_description: '9:00 / 18:00',
        help_timeline_footer: 'Lunedì - Venerdì',
      }
    },
    login: {
      login: 'Accedi',
      loginDesc: 'Accedi nella tua area personale con le tue credenziali',
      email: 'Indirizzo e-mail',
      password: 'Password',
      remember: 'Ricordami',
      forgot: 'Non ricordo la password',
      emailNotValid: 'Indirizzo e-mail non valido',
      passwordNotValid: 'La password deve essere compresa tra gli 8 e 20 caratteri tra cui una maiuscola, una minuscola e un numero',
      emailPasswordNotValid: "L'email o la password non sono corrette. Riprova",
      accountBlocked: "L' accesso all' utenza è stato bloccato per ragioni di sicurezza. Ti invitiamo a Contattare il Servizio Clienti per sbloccare il tuo account.",
      register: 'Registrati',
      registerDesc: 'Inserisci i dati che ti vengono richiesti per completare la registrazione.',
      clientCode: 'Codice cliente',
      or: 'oppure',
      vatOrFiscalCode: 'Codice Fiscale o Partita Iva',
      continue: 'Continua',
      mandatoryField: 'Compila il campo per continuare',
      userInvalid: "Questi dati sono già in uso",
      registration: "Ops, si è presentato un errore. Riprova."
    },
    popupUserContractList: {
      title:'Seleziona un contratto per accedere alle tue letture',
      item_code_customer:"Cod. cliente",
      item_type_business:"Business",
      item_type_domestic:"Domestico",
      item_type_cond:"Condomini",
      button_add_contract:"AGGIUNGI CONTRATTO",
      or: 'oppure',
      support_title:"Non trovi il tuo contratto?",
      support_subtitle_1:"Contatta il ",
      support_subtitle_2:"Call Center",
      text_back:'Indietro',
      add_contract_title:"Aggiungi contratto",
      add_contract_subtitle:"Inserisci i dati richiesti per poter associare un contratto già presente in Luce e Gas Italia Spa.",
      code:"Codice cliente",
      errorCode:"Inserisci il Codice Cliente",
      vat:"Codice Fiscale o Partita Iva",
      errorVat:"Inserisci il Codice Fiscale o Partita Iva",
      continue:"CONTINUA",
      errorNoContract:"Non esiste nessun contratto con questo codice cliente e partita iva o  codice fiscale",
      errorNoConnect:"Non è possibile associare questo contratto alla tua utenza",
      errorServer:"Ops, si è presentato un errore. Riprova.",
      add_contract_confirm_title:"Conferma",
      add_contract_confirm_subtitle:"Inserisci il codice OTP che ti abbiamo inviato all’indirizzo e-mail ({email}) per aggiungere il contratto",
      otp: "Codice OTP",
      errorOtp: "Codice OTP non valido",
      textOtp: "Non hai ricevuto il codice OTP?",
      linkOtp: "Invia di nuovo",
      reset: "Conferma",
      add_contract_confirm_success_title:"Ottimo!",
      add_contract_confirm_success_subtitle:"Il tuo contratto è stato aggiunto.",
      add_contract_confirm_success_back:"TORNA AI CONTRATTI",
      add_contract_empty_list:"Non risultano contratti attivi",
    },
    register: {
      title: 'Registrati',
      desc: 'Completa la tua registrazione',
      code: 'Codice Cliente',
      vat: 'Partita IVA',
      name: 'Nome',
      lastname: 'Cognome',
      prefix: 'Prefisso',
      phone: 'Numero di telefono',
      email: 'Indirizzo e-mail',
      friendcode: 'Codice Porta un amico (opzionale)',
      confirmEmail: 'Conferma e-mail',
      password: 'Inserisci password',
      textPassword: 'La password deve essere di almeno 8 caratteri con una lettera maiuscola ed un numero',
      confirmPassword: 'Conferma password',
      continue: 'Continua',
      mandatoryField: 'Compila il campo per continuare',
      errorEmail: "Indirizzo e-mail non valido",
      errorConfirmEmail: "Le due email non corrispondono",
      errorPassword: "La password deve essere di almeno 8 caratteri con una lettera maiuscola ed un numero",
      errorFormatConfirmPassword: "La password deve essere di almeno 8 caratteri con una lettera maiuscola ed un numero",
      errorConfirmPassword: "Le password inserite non corrispondono",
      errorUserEmailExists: "Esiste già un utente registrato con questa email",
      errorUserExists: "Esiste già un utente con i dati inseriti",
      errorMandatory: "Campo obbligatorio",
      errorName:"Compila il campo per continuare",
      errorLastname:"Compila il campo per continuare"
    },
    home: {
      hello: 'Ciao',
      clientCode: 'Codice Cliente',
      yourHome: 'Sede Legale / Indirizzo di residenza',
      yourData: 'I tuoi dati',
      payments: 'Pagamenti e fatture',
      showPayments: 'Vedi tutte le bollette',
      discountTitle: 'Porta un amico',
      discountText: "Porta i tuoi amici in <i>Luce e Gas Italia</i> e risparmi <strong>24 €/anno per ogni fornitura da essi attivata</strong>. Gli importi verranno corrisposti su base mensile: 2 €/utenza/mese finché entrambi restate in fornitura con noi. L’offerta è senza limiti: più amici ci presenti, più bonus ottieni, fino ad azzerare la tua bolletta (IVA ed accise escluse).",
      discountNotes: 'Scopri di più',
      discountBox1Title: 'Azzera il costo del CANONE RAI',
      discountBox1Text: 'Porta 2 amici ed azzeri il costo del CANONE RAI',
      discountBox2Title: 'Azzera anche la Quota ENERGIA',
      discountBox2Text: 'Porta 8 amici ed azzeri anche la Quota ENERGIA',
      discountBox3Title: 'Azzera la tua bolletta',
      discountBox3Text: 'Porta 13 amici ed azzeri la tua bolletta',
      discountBox3Notes: '*IVA ed accise escluse',
      discountTerms: '*stime calcolate in base alle medie di consumo nazionali 2020.',
      autoReadingDescription:"Effettua l’autolettura del contatore"
    },
    payment: {
      regular: 'I pagamenti sono regolari',
      notRegular: 'I pagamenti non sono regolari',
      support_title:"Per consultare e scaricare eventuali fatture antecedenti al 2020,",
      support_subtitle_1:"Contatta il ",
      support_subtitle_2:"Call Center",
    },
    maintenance: {
      title: 'Sito in manutenzione',
      desc: 'Stiamo lavorando per tornare presto online'
    },
    supply: {
      closed:'Cessata',
      number: 'N° fornitura',
      active: 'Fornitura {type} attiva',
      not_active:'Fornitura {type}',
      expiry: 'Scadenza',
      lastInvoice:'Ultima Bolletta',
      supplyEE: 'Luce',
      supplyGas: 'Gas',
      paymentOK: 'Pagamento effettuato',
      paymentNegative: 'Compensata',
      paymentKO: 'Pagamento mancante',
      paymentPartial:'Pagamento Parziale',
      invoiceNumber: 'Fattura N° {number} del {date}',
      invoiceNumberNoDate: 'Fattura N° {number}',
      paymentBplInvoiceNumber:'Fattura N° {number} del {date}',
      paymentBpl: 'Il pagamento dovrà essere effettuato tramite bollettino entro il {date}.',
      paymentBplKo: 'Non ci risulta ancora il pagamento tramite bollettino entro il {date}.',
      paymentBplOk: 'Effettuato tramite bollettino il {date}.',
      paymentRidInvoiceNumber:'Fattura N° {number} del {date}',
      paymentRid: 'Il pagamento verrà effettuato tramite addebito sul conto corrente il {date}. ',
      paymentRidKo: 'Non ci risulta ancora il pagamento tramite addebito sul conto corrente il {date}.',
      paymentRidOk: 'Effettuato tramite addebito sul conto corrente il {date}.'
    },
    bill: {
      view:"Consulta",
      invoiceTitle:"Numero fattura:",
      invoiceDate:"Data emissione:",
      invoicePeriod:"Periodo di competenze:",
      invoiceAddress:"Indirizzo di fornitura:",
      titleHistory: "Storico bollette",
      noBills: "Non ci sono fatture nell'anno selezionato",
      bills: 'Bollette',
      manage: 'Gestisci',
      download: 'Scarica',
      supply: 'Fornitura',
      supplyEE: 'Luce',
      supplyGas: 'Gas',
      active:' Attiva',
      not_active:' Cessata',
      expiry: 'Scadenza',
      lastInvoice:'',
      consumption: 'Consumi',
      consumptionDesc: "I tuoi consumi rispetto allo stesso mese dell'anno precedente",
      consumptionAverage: 'Media ultimi 3 mesi',
      consumptionPeak: 'Picco di potenza',
      consumptionMessage: 'Complimenti, Non hai mai superato la potenza impegnata',
      showHistory: 'Vedi storico bollette',
      eventsTitle: 'Cosa succede alla mia fornitura',
      contract: 'Contratto',
      paymentType: 'Metodo di pagamento',
      paymentTypeBank: 'Addebito su conto corrente',
      paymentTypeBLP: "Pagamento con Bollettino Postale",
      contact: 'Contattaci',
      voltureTitle: 'Richiedi una voltura',
      voltureDesc: 'Se vuoi cambiare la persona a cui è intestata la tua fornitura',
      addressTitle: 'Modifica residenza',
      addressDesc: 'Se hai cambiato la residenza e devi modificare il contratto',
      verifyTitle: 'Verifica',
      verifyDesc: 'Se vuoi accertarti del corretto funzionamento della tua fornitura',
      powerTitle: 'Modifica potenza',
      powerDesc: 'Se hai bisogno di ridurre o aumentare la potenza impegnata del tuo contatore',
      moveTitle: 'Sposta contatore',
      moveDesc: 'Se hai deciso di trasferirlo in un altro punto della casa',
      removeTitle: 'Disattiva contatore',
      removeDesc: 'Se hai deciso di interrompere la tua fornitura',
      counter: 'Contatore',
      yourCounter: 'Il Tuo contatore',
      pdr: 'PDR',
      pod: 'POD',
      fiscalCode: 'Codice fiscale',
      address: 'Indirizzo',
    },
    payments: {
      title: "Pagamenti e fatture"
    },
    clearYourBill: {
      share:"Condividi",
      title: "Porta un amico",
      subtitle: "Ogni amico che porti in Luce e Gas Italia si traduce per te in uno sconto in bolletta",
      yourCode: "Il tuo codice",
      activeFriends: "Forniture di amici attive",
      facebook: "FACEBOOK",
      linkedin: "LINKEDIN",
      copy: "COPIA LINK",
      howItWorks1: "Come funziona",
      howItWorks2: "Ogni amico che porti in Luce e Gas Italia si traduce per te in uno sconto in bolletta: <strong>24 € all'anno</strong> per ogni sua utenza in fornitura, corrisposti su base mensile (2,00 €/utenza/mese finché entrambi restate in fornitura con noi)",
      howItWorks3: "Ad esempio",
      howItWorks4: "Porti 1 amico ed attiva <strong>un contratto di Luce</strong> e <strong>un contratto di Gas</strong>",
      howItWorks5: "Cosa ottieni",
      howItWorks6: "24 €",
      howItWorks7: "24 €",
      howItWorks8: "48 €",
      howItWorks9: "anno di sconto",
      howItWorks10: "Più amici porti e più contratti essi attivano, <strong>maggiore sarà lo sconto per te</strong>, fino ad azzerare la tua bolletta (ad accezione dell'IVA, delle accise e di eventuali depositi cauzionali).",
      howItWorks11: "Come funziona",
      howItWorks12: "Invia il tuo codice ad un amico",
      howItWorks13: "Chi riceve il codice dovrà inserirlo prima di caricare la bolletta e completare la sottoscrizione di una o più forniture",
      howItWorks14: "Ogni volta che un amico attiva una fornitura legata al tuo codice ti verrà riconosciuto uno sconto per i 12 mesi successivi.",
      howItWorks15: "A quale fornitura verrà applicato",
      howItWorks16: "Puoi decidere su quale tua utenza usufruire della promozione",
      howItWorks17: "A cosa non si applica lo sconto",
      howItWorks18: "Lo sconto non si applica su IVA, accise ed eventuali depositi cauzionali presenti in bolletta."
    },
    autoReading: {
      gasTitle: "Autolettura contatore gas",
      eeTitle: "Autolettura contatore luce",
      button: "Procedi",
      alertTitleEE:"Autolettura contatore luce",
      alertDescriptionEE:"Gentile cliente, è possibile eseguire le auto-letture Energia elettrica unicamente gli ultimi 5 giorni del mese. Ti invitiamo quindi a riprovare nel periodo indicato e ti auguriamo Buona Giornata.",
      alertTitleGas:"Autolettura contatore gas",
      alertDescriptionGas:"Gentile cliente, è possibile eseguire le auto-letture Gas unicamente gli ultimi 5 giorni del mese. Ti invitiamo quindi a riprovare nel periodo indicato e ti auguriamo Buona Giornata."
    },
    autoReadingEE: {
      title: "Autolettura contatore luce",  
      subtitle: "Verranno ritenuti validi ai fini della fatturazione solamente i moduli inviati entro l’ultimo giorno del mese commerciale di fornitura",  
      placeholder_clientId:"Codice cliente",
      placeholder_pod:"Codice POD",
      placeholder_reading1:"Lettura a1 periodo corrente",
      placeholder_reading2:"Lettura a2 periodo corrente",
      placeholder_reading3:"Lettura a3 periodo corrente",
      title_clientId:"Riportata sulla prima pagina della fattura",
      title_pod:"Riportata sulla prima pagina della fattura",
      errorClientId:"Inserire il codice cliente",
      errorPOD:"Inserire il codice POD",
      errorReading1:"Inserire periodo lettura a1",
      errorReading2:"Inserire periodo lettura a2",
      errorReading3:"Inserire periodo lettura a3",
      button_send_reading:"INVIA AUTOLETTURA",
      errorReadingEE:"Ops, si è presentato un errore. Riprova.",
      info_title:"Come fare?",
      info_description:"Premere più volte il pulsante indicato per visualizzare tutte le letture necessarie",
      confirmSended:"La tua autolettura è stata inviata",
      success_title:"Ottimo!",
      success_description:"La tua autolettura è stata inviata",
      button_send_reading_back:"TORNA ALLE AUTOLETTURE"
    },
    autoReadingGas: {
      title: "Autolettura contatore gas",  
      subtitle: "Verranno ritenuti validi ai fini della fatturazione solamente i moduli inviati entro l’ultimo giorno del mese commerciale di fornitura",  
      placeholder_clientId:"Codice cliente",
      placeholder_pdr:"Codice PDR",
      placeholder_reading:"Lettura rilevata",
      title_clientId:"Riportata sulla prima pagina della fattura",
      title_pdr:"Riportata sulla prima pagina della fattura",
      errorClientId:"Inserire il codice cliente",
      errorPDR:"Inserire il codice PDR",
      errorReading:"Inserire periodo lettura",
      button_send_reading:"INVIA AUTOLETTURA",
      errorReadingGas:"Ops, si è presentato un errore. Riprova.",
      info_title:"Come fare?",
      info_description:"Inserire solo i numeri letti prima della virgola, tralasciando i decimali (che sul contatore sono evidenziati in rosso)",
      confirmSended:"La tua autolettura è stata inviata",
      success_title:"Ottimo!",
      success_description:"La tua autolettura è stata inviata",
      button_send_reading_back:"TORNA ALLE AUTOLETTURE"
    },
    friend: {
      active: "Attivo",
      discount: "Sconto mensile",
      text: "Gli sconti luce e gas saranno applicati mensilmente fino al {date} nelle rispettive bollette."
    },
    notification: {
      title: "Notifiche",
      subtitle: "Non sono presenti notifiche",
      more: "Mostra di più"
    },
    help: {
      title: "Assistenza",
      subtitle: "Siamo qui per aiutarti.<br>Seleziona un argomento e descrivi il tuo problema.",
      number: "Numero verde",
      numberFromMobile: "Oppure da cellulare",
      chat: "Utilizza la chat",
      chatDays: "Lunedì - Venerdì",
      chatLink: "Chatta con noi",
      formTitle: "Richiedi assistenza per un argomento",
      formArgumentPlaceholder: "Seleziona un argomento",
      formProblemPlaceholder: "Descrivi il tuo problema",
      formSend: "Invia",
      errorArgument: "Seleziona un argomento",
      errorMessage: "Inserisci un messaggio",
      messageSuccess: "Messaggio inviato con successo",
      messageError: "Errore nell'invio della richiesta"
    },
    back: {
      message: "Torna indietro"
    },
    reset: {
      message: "Torna all'accesso",
      title: "Recupera password",
      desc: "Inserisci l'indirizzo e-mail che hai utilizzato in fase di registrazione.",
      email: "Indirizzo e-mail",
      reset: "Recupera",
      emailNotValid: "Indirizzo e-mail non valido",
    },
    setReset: {
      title: "Reset password",
      desc: "Inserisci il codice OTP che ti abbiamo inviato all'indirizzo e-mail indicato per procedere con il reset della password",
      email: "E-mail",
      otp: "Codice OTP",
      textOtp: "Non hai ricevuto il codice OTP?",
      linkOtp: "Invia di nuovo",
      password: "Inserisci nuova password",
      confirmPassword: "Conferma nuova password",
      textPassword: "La password deve essere di almeno 8 caratteri con una lettera maiuscola ed un numero\n",
      reset: "Reset password",
      codeSent: "Codice OTP inviato con successo",
      codeError: "Errore durante l'invio del codice",
      errorOtp: "Codice OTP non valido",
      errorPassword: "La password deve essere di almeno 8 caratteri con una lettera maiuscola ed un numero",
      errorConfirmPassword: "Le password inserite non corrispondono",
    },
    success: {
      title: 'Complimenti',
      desc: 'La password è stata aggiornata con successo',
      back: "Torna all'accesso",
    },
    confirmEmail: {
      title: 'Conferma e-mail',
      desc: 'Inserisci il codice OTP che ti abbiamo all’indirizzo e-mail indicato per procedere con la registrazione.',
      email: "E-mail",
      otp: "Codice OTP",
      textOtp: "Non hai ricevuto il codice OTP?",
      linkOtp: "Invia di nuovo",
      reset: "Conferma",
      codeSent: "Codice OTP inviato con successo",
      errorOtp: "Codice OTP non valido"
    },
    confirmPhone: {
      title: 'Conferma numero di telefono',
      desc: 'Inserisci il codice OTP che ti abbiamo al numero di telefono indicato per procedere con la registrazione.',
      prefix: "Prefisso",
      phone: "Numero di telefono",
      otp: "Codice OTP",
      textOtp: "Non hai ricevuto il codice OTP?",
      linkOtp: "Invia di nuovo",
      reset: "Conferma",
      codeSent: "Codice OTP inviato con successo",
      errorOtp: "Codice OTP non valido"
    },
    profile: {
      title: "Il tuo profilo",
      hello: "Ciao",
      code: "Codice Cliente",
      nameLastname: "Intestatario",
      email: "E-mail",
      manage: "Gestisci",
      contact: "Contattaci",
      phone: "Numero di telefono",
      password: "Password",
      payment: "Metodo di pagamento",
      address: "Indirizzo di recapito",
      paymentGas: "PDR",
      paymentEE: "POD",
      paymentRID: "Addebito su conto corrente",
      paymentBLP: "Pagamento con Bollettino Postale",
    },
    paymentBlock: {
      manage: "Gestisci",
    },
    profileBlock: {
      edit: 'Modifica'
    },
    editPhone: {
      title: "Modifica numero di telefono",
      desc: "Per essere sicuri che sia tu a richiedere la modifica del numero di telefono ti chiediamo di inserire la tua password",
      password: "Password",
      errorPassword: "Password errata",
      newNumber: "Nuovo numero",
      prefix: "Prefisso",
      phone: "Numero di telefono",
      mandatoryField: "Campo obbligatorio",
      edit: "Modifica",
      otp: "Codice OTP",
      errorOtp: "Codice OTP non valido",
      textOtp: "Non hai ricevuto il codice OTP?",
      linkOtp: "Invia di nuovo",
      confirm: "Conferma",
      confirmMessage: "Il numero di telefono è stato aggiornato con successo"
    },
    editEmail: {
      title: "Modifica e-mail",
      desc: "Per essere sicuri che sia tu a richiedere la modifica del numero di telefono ti chiediamo di inserire la tua password",
      password: "Password",
      errorPassword: "Password errata",
      email: "Nuova e-mail",
      confirmEmail: "Conferma nuova e-mail",
      edit: "Modifica",
      errorEmail: "Indirizzo e-mail non valido",
      errorConfirmEmail: "Le due email non corrispondono",
      otp: "Codice OTP",
      errorOtp: "Codice OTP non valido",
      textOtp: "Non hai ricevuto il codice OTP?",
      linkOtp: "Invia di nuovo",
      confirm: "Conferma",
      confirmMessage: "L'e-mail è stata aggiornata con successo"
    },
    editPassword: {
      title: "Modifica password",
      desc: "Per essere sicuri che sia tu a richiedere la modifica della password ti chiediamo di inserire la tua password attuale.",
      password: "Password attuale",
      errorPassword: "Password errata",
      newPassword: "Nuova password",
      errorNewPassword: "La password deve essere di almeno 8 caratteri con una lettera maiuscola ed un numero",
      textPassword: "La password deve essere di almeno 8 caratteri con una lettera maiuscola ed un numero\n",
      confirmPassword: "Conferma nuova password",
      errorConfirmPassword: "Le password inserite non corrispondono",
      edit: "Modifica",
      confirmMessage: "La password è stata aggiornata con successo"
    },
    editPayment: {
      title: "Modifica metodo di pagamento",
      desc: "Inserisci i dati del conto corrente",
      bank: "Banca",
      errorBank: "Campo non valido",
      iban: "IBAN",
      errorIban: "IBAN non valido",
      edit: "Modifica",
      confirmMessage: "I dati bancari sono stati aggiornati con successo"
    },
    editAddress: {
      title: "Modifica indirizzo di recapito",
      desc: "Qui è dove ti verranno recapitate le comunicazioni.",
      addressPhysics: "Indirizzo di recapito",
      address: "Indirizzo",
      errorAddress: "Campo non valido",
      number: "Numero civico",
      errorNumber: "Campo non valido",
      city: "Comune",
      errorCity: "Campo non valido",
      zip: "CAP",
      errorZip: "Campo non valido",
      or: "Oppure",
      addressOnline: "Indirizzo e-mail (di recapito)",
      email: "E-mail",
      errorEmail: "Campo non valido",
      confirmMessage: "L'indirizzo di recapito è stato aggiornato con successo",
      edit: "Modifica",
    },
    contractList: {
      title: "I tuoi contratti",
      subtitle: "Gestisci i tuoi contratti",
      support_title:"Non trovi il tuo contratto?",
      support_subtitle_1:"Contatta il ",
      support_subtitle_2:"Call Center",
      button_add_contract:"AGGIUNGI CONTRATTO",
    },
    popupDeleteUserContract: {
      title:"Dissocia contratto",
      subtitle: "Dissociando questo contratto non potrai più accedere alle sue letture.", 
      subtitle2: "Sei sicuro di voler dissociare contratto con il codice {customerCode}?",
      button:'DISSOCIA',
      not_now:"Non adesso",
      errorServer:"Ops, si è presentato un errore. Riprova.",
      confirmMessage: "Il contratto è stato dissociato"
    },
    autoReadingLogged: {
      title:"Effettua autoletture",
      subtitle:"L’autolettura può essere fatta negli ultimi 5 giorni del mese.", 
      subtitle2:"In caso di mancata comunicazione, la bolletta sarà basata sui dati forniti dal Distributore o su una stima dei consumi abituali, che però potrebbe non corrispondere esattamente a quanto hai utilizzato.",
      title_gas: "Fornitura Gas",
      title_ee: "Fornitura Luce",
    },
    menuInvoice: {
      title:"SCARICA IL PDF DELLA TUA BOLLETTA",
      button_1:"SINTETICA",
      button_2:"DETTAGLIO",
    }
  },
  en: {
  },
}

export default messages;