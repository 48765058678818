<template>
  <div>
    <div class="accordion" :class="{ 'accordion--open': open }">
      <div @click="$emit('select')" class="accordion-title">{{ title }}</div>
      <div class="accordion-content" v-if="open">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  props: {
    title: String,
    open: Boolean,
  },
  methods: {
  },
  data: function () {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.accordion {
  &-title {
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    color: var(--title-color);
    position: relative;
    padding-left: 50px;
    min-height: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 34px;

    &::before {
      content: '';
      width: 30px;
      height: 30px;
      border: solid 1px var(--light-grey-3);
      background-color: var(--white);
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &--open {
    .accordion-title::after {
      content: '';
      width: 20px;
      height: 20px;
      border: solid 1px var(--light-grey-3);
      background-color: var(--primary-color);
      border-radius: 50%;
      position: absolute;
      top: 5px;
      left: 5px;
    }
  }
}
</style>