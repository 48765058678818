<template>
  <div class="history-event" :class="'history-event--' + event.supplyTypeCode.toLowerCase()">
    <div class="history-event-icon">
      <div class="history-event-icon-img">
        <img :src="icon">
      </div>
    </div>
    <div class="history-event-date">{{ $moment(event.eventDate).lang($store.state.locale).format('MMMM') }} {{ $moment(event.eventDate).format('YYYY') }}</div>
    <div class="history-event-desc">{{ event.message }}</div>
    <div style="height: 10px;"></div>
  </div>
</template>

<script>
import icoReminder from '../assets/ico-reminder.svg'
import icoChange from '../assets/ico-change.svg'
import icoStart from '../assets/ico-start.svg'
import icoContatore from '../assets/ico-contatore.svg'
import icoPayments from '../assets/ico-payments.svg'
import icoShipping from '../assets/ico-shipping.svg'
import icoPower from '../assets/ico-power.svg'
import icoConnection from '../assets/ico-connection.svg'
import icoDocument from '../assets/ico-document.svg'
import icoNotice from '../assets/ico-notice.svg'

export default {
  name: 'HistoryEvent',
  props: ['event'],
  data: function () {
    let icon = ''
    switch (this.event.eventTypeCode) {
      case "MESSA_MORA":
        icon = icoNotice;
        break;
      case "SCADENZA_FAT":
        icon = icoReminder;
        break;
      case "CAMBIO_PRODOTTO":
        icon = icoChange;
        break;
      case "NUOVA_FORNITURA":
        icon = icoStart;
        break;
      case "AUTOLETTURA":
        icon = icoContatore;
        break;
      case "CAMBIO_MOD_PAGAMENTO":
        icon = icoPayments;
        break;
      case "CAMBIO_MOD_SPEDIZIONE":
        icon = icoShipping;
        break;
      case "CAMBIO_POTENZA":
        icon = icoPower;
        break;
      case "CESSAZIONE":
        icon = icoContatore;
        break;
      case "CONNESSIONE":
        icon = icoConnection;
        break;
      case "EMISSIONE_FAT":
        icon = icoDocument;
        break;
    }

    return {
      icon: icon
    }
  }
}
</script>
<style lang="scss" scoped>
.history-event {
  max-width: 230px;
  width: 100%;

  &--gas {
    .history-event-icon-img {
      background-image: var(--gradient-gas);
    }
  }

  &-icon {
    &-img {
      border-radius: 50%;
      padding: 20px;
      background-image: var(--gradient-energy);
      display: inline-block;
      position: relative;

      img {
        width: 20px;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
  }

  &-date {
    margin: 10px 0 7px;
    opacity: 0.8;
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    text-transform: uppercase;
    color: var(--title-color);
  }

  &-desc {
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    color: var(--black);
  }
}
</style>