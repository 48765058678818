<template>
  <div class="container">
    <div class="container-app">
      <div class="row">
        <div class="col-md-12 col-lg-6 border-end border-2 border-light border-desktop">
          <div class="rl-box">
            <h2 class="title-small">{{ $t("login.login")}}</h2>
            <p class="rl-description">{{ $t("login.loginDesc")}}</p>

            <form @submit="login">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <div class="form-floating">
                    <input type="email" class="form-control form-control-min" :class="{'is-invalid': errors.username}" id="username" placeholder="Username o Indirizzo e-mail" v-model="username">
                    <label for="username">{{ $t("login.email")}}</label>
                    <div class="invalid-feedback" v-if="errors.username && !errors.password && !errors.accountBlocked && !errors.login">
                      {{ $t("login.emailNotValid")}}
                    </div>
                    <!-- <div class="invalid-feedback" v-if="errors.username && errors.password">
                      {{ $t("login.emailPasswordNotValid")}}
                    </div> -->
                  </div>
                </div>
                <div class="col-md-12 mb-3">
                  <password :name="$t('login.password')" :error="errors.password" v-model="password" />
                  <div class="invalid-feedback" v-if="errors.formatPassword">
                      {{ $t("login.passwordNotValid")}}
                  </div>
                  <div class="invalid-feedback" v-if="errors.login && !errors.accountBlocked">
                      {{ $t("login.emailPasswordNotValid")}}
                  </div>
                  <div class="invalid-feedback" v-if="errors.accountBlocked && !errors.login">
                      {{ $t("login.accountBlocked")}}
                  </div>
                </div>
                <div class="col-md-12 mb-3">
                  <div class="form-check">
                    <div class="d-flex justify-content-between">
                      <div>
                        <input type="checkbox" class="form-check-input" id="remember">
                        <label class="form-check-label" for="remember">{{ $t("login.remember")}}</label>
                      </div>
                      <div>
                        <router-link to="/reset-password" class="link-bold">{{ $t("login.forgot") }}</router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn btn-big text-uppercase w-100" :disabled="!validLogin">{{ $t("login.login")}}</button>
            </form>
          </div>
        </div>

        <div class="col-md-12 col-lg-6 border-start border-2 border-light border-desktop">
          <div class="rl-box rl-box-right">
            <h2 class="title-small">{{ $t("login.register")}}</h2>
            <p class="rl-description">{{ $t("login.registerDesc")}}</p>

            <form @submit="register">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <div class="form-floating">
                    <input type="email" class="form-control form-control-min" :class="{'is-invalid': errors.email || errors.registration}" id="email" placeholder="Indirizzo e-mail" v-model="email">
                    <label for="username">{{ $t("login.email")}}</label>
                    <div class="invalid-feedback" v-if="errors.email">
                      {{ $t("login.emailNotValid")}}
                    </div>
                    <div class="invalid-feedback" v-if="errors.email">
                      {{ $t("login.emailPasswordNotValid")}}
                    </div>
                    <div class="invalid-feedback" v-if="errors.userValid">
                      {{ $t("register.errorUserEmailExists")}}
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn btn-big text-uppercase w-100" :disabled="!validRegister">{{ $t("login.continue")}}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import iconHide from '../assets/ico-hide.svg'
import iconShow from '../assets/ico-show.svg'
import Password from "@/components/Password";
export default {
  name: 'Login',
  data: function () {
    return {
      username: '',
      password: '',
      email: '',
      errors: {
        username: false,
        password: false,
        formatPassword:false,
        email: false,
        userValid: false,
        registration: false,
        accountBlocked: false,
        login:false,
      },
      iconHide: iconHide,
      iconShow: iconShow,
    }
  },
  computed: {
    validLogin(){
      let valid = true
      if(!this.username) {
        valid = false
      }
      if(!this.password) {
        valid = false
      }
      return valid
    },
    validRegister(){
      let valid = true

      if(!this.email) {
        valid = false
      }
      return valid
    }
  },
  components: {
    Password
  },
  methods: {
    checkUser: async function(userData) {
      this.$store.commit('setUserRegistered', { email: this.username, phone: userData.phoneNumber, password: this.password, token: userData.token })
      let provisionalHeaders = {
        headers: {
          Authorization: 'Bearer ' + userData.token,
        }
      }
      if (!userData.emailConfirmed) {
        await this.axios.post(process.env.VUE_APP_API_AUTH_URL + 'validate-email', {}, provisionalHeaders)
          .then(async () => {
            this.$router.push('/conferma-email')
          }).finally(() => {
            this.$store.commit('setLoader', false)
          });
        return
      }
      if (!userData.phoneConfirmed) {
        await this.axios.post(process.env.VUE_APP_API_AUTH_URL + 'validate-phone-number', {}, provisionalHeaders)
          .then(async () => {
            this.$router.push('/conferma-telefono')
          }).finally(() => {
            this.$store.commit('setLoader', false)
          });
      }
    },
    validateEmail: function(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    validatePassword: function(password) {
      const re = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?!.*[^a-zA-Z0-9?=.*[*.!@$%^&:;<>,.?~_+-=|]).{8,20}/;
      return re.test(password);
    },
    register: function (ev) {

      ev.preventDefault()
      this.errors.email = false
      this.errors.userValid = false
      this.errors.registration = false;
      this.errors.password = false;

      let error = false
      if (!this.email) {
        this.errors.email = true
        error = true
      }

      if (!this.validateEmail(this.email)) {
        this.errors.email = true
        error = true
      }
      
      if (!error) {
        this.$store.commit('setLoader', true)
        this.errors.userValid = false
        this.axios.get(process.env.VUE_APP_API_AUTH_URL + 'user-is-valid-to-register?email=' + this.email)
          .then((response) => {
            if (response.data.result) {
              this.$store.commit('setRegister', { email:this.email})
              this.$router.push({ name: 'Registration' })
            } else {
              this.errors.userValid = true
            }
          }).catch((e) => {

            console.log(e,'_aa_xxx');

            this.errors.registration = true
          }).finally(() => {
            this.$store.commit('setLoader', false)
          });
      }
    },
    login: function(ev) {
      ev.preventDefault()
      this.errors.username = false
      this.errors.password = false
      this.errors.formatPassword = false;
      this.errors.login = false
      this.errors.accountBlocked = false

      if (!this.validateEmail(this.username)) {
        this.errors.username = true
        return;
      }

      if (!this.validatePassword(this.password)) {
        this.errors.formatPassword = true
        return;
      }

      this.$store.commit('setLoader', true)

      this.axios.post(process.env.VUE_APP_API_AUTH_URL + 'login', {
        "Email": this.username,
        "Password": this.password
      }).then((response) => {

       

        if (response.data.tokenType === 'AccessToken') {
          this.$store.commit('setAccessToken', response.data.token)
          this.$store.commit('setType', 'private')
          this.$store.commit('showContractList', true)
          this.$router.push('/')
          this.$store.commit('setLoader', false)
        } else {
          this.checkUser(response.data)
        }
      }).catch(async (error) => {
        if (error.response.data.tokenType && error.response.data.tokenType === 'ProvisionalToken') {
          this.checkUser(error.response.data)
        } else {

          console.log(error.response,'_aa_xx');

          if (error.response.status == 423) {
            this.errors.accountBlocked = true;
          } else {
            this.errors.login = true;
          }

          this.errors.username = true
          this.errors.password = true
          this.$store.commit('setLoader', false)
        }
      });

    }
  },
  created() {

  },
  mounted() {
  }
}
</script>