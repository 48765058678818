<template>
  <span @click="goBack" class="go-back">
    <span>
      <img :src="icoArrowRight" alt="">
    </span>
    {{ text }}
  </span>
</template>

<script>
import icoArrowRight from '../assets/ico-arrow-right.png'
export default {
  name: 'Back',
  props: {
    message: String,
    before: {
      type: Function,
      default() {}
    }
  },
  data: function () {
    return {
      text: this.message ? this.message : this.$t('back.message'),
      icoArrowRight: icoArrowRight
    }
  },
  methods: {
    goBack: function() {
      this.before()

      if (this.$store.state.type == 'private') {
        this.$store.commit('setAutoReadingSupply', null);
      }

      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
.go-back {
  font-size: 13px;
  font-weight: normal;
  line-height: normal;
  color: var(--title-color);
  text-decoration: none;
  cursor: pointer;
  display: inline-block;

  img {
    width: 19px;
    height: auto;
    margin-right: 14px;
    transform: rotate(180deg);
  }
}
</style>