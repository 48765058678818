<template>
  <div class="container">
    <div class="container-app">
      <div class="row maintenance">
        <div class="col-md-12">
          <img :src="$siteURL + '/build/frontend/images/luce-e-gas-official.png'" alt="Luce e Gas Italia SpA" class="logo">
        </div>

        <div class="col-md-12 maintenance-block">
          <img :src="maintenanceImg" alt="Maintenance" class="maintenance-block-img">
          <h1 class="maintenance-block-title">{{ $t("maintenance.title")}}</h1>
          <p class="maintenance-block-text">{{ $t("maintenance.desc") }}</p>

          <div class="social-icons">
            <a v-if="snippetFooterItems.facebook" :href="$siteURL + snippetFooterItems.facebook">
              <img :src="$siteURL + '/build/frontend/images/ico-facebook.png'"/>
            </a>
            <a v-if="snippetFooterItems.instagram" :href="$siteURL + snippetFooterItems.instagram">
              <img :src="$siteURL + '/build/frontend/images/ico-ig.png'"/>
            </a>
            <a v-if="snippetFooterItems.linkedin" :href="$siteURL + snippetFooterItems.linkedin">
              <img :src="$siteURL + '/build/frontend/images/ico-linkedin.png'"/>
            </a>
            <a v-if="snippetFooterItems.twitter" :href="$siteURL + snippetFooterItems.twitter">
              <img :src="$siteURL + '/build/frontend/images/ico-twitter.png'"/>
            </a>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import maintenanceImg from '../assets/illustration-pc.svg'
export default {
  name: 'Maintenance',
  data: function () {
    return {
      maintenanceImg: maintenanceImg,
      snippetFooterItems: {}
    }
  },
  methods: {
  },
  created() {
    // this.axios.get(process.env.VUE_APP_SITE_URL + '/api/snippet-areas/settings_area')
    //   .then((response) => {
    //     this.snippetFooterItems = response.data.content;
    //   }).catch(() => {
    //   });
  },
  mounted() {
  }
}
</script>
<style scoped lang="scss">
.maintenance {
  text-align: center;

  .logo {
    width: 150px;
  }

  &-block {
    &-title {
      margin: 17px 0 19px;
      font-size: 34px;
      font-weight: 400;
      line-height: 1;
      color: var(--title-color);
    }

    &-text {
      margin: 0;
      font-size: 16px;
      font-weight: 300;
      line-height: 1;
      color: var(--text-color);
    }

    &-img {
      max-width: 93px;
      margin-top: 100px;
    }

    .social-icons {
      margin-top: 80px;

      a {
        margin: 0 3px;
      }
    }
  }
}
</style>