import { createStore } from 'vuex'

const defaultState = {
    locale: 'it',
    type: 'public',
    accessToken: '',
    accessTokenDWH: '',
    loader: false,
    supply: {},
    dashboard: {},
    user: {},
    showHistory: true,
    activeHome: false,
    showBack: false,
    toast: {},
    help: {},
    resetUsername: '',
    unreadNotification: 0,
    register: {
        code: null,
        vat: null
    },
    billsYear: null,
    userRegistered: {
        name:'',
        lastname:'',
        phone:'',
        friendCode:'',
        email: '',
        password: '',
        token: ''
    },
    history: [],
    alertAutoReading: false,
    showCList:false,
    userContracts:[],
    currentUserContract: null,
    showAddNewContract:false,
    showDContract:false,
    deleteCurrentContract:null,
    autoReadingSupply: null,
    autoReadingSended:[],
    coupon:'',
    showMenuInvoice:false,
    notes:[],
    titleNoteList:'',
    supplyPoints:[],
    filterInvoiceStatus:0,
    mainAddress:'',
}

const defaultSSOState = {
    locale: 'it',
    type: 'public',
    accessToken: '',
    accessTokenDWH: '',
    loader: false,
    supply: {},
    dashboard: {},
    user: {},
    showHistory: true,
    activeHome: false,
    showBack: false,
    toast: {},
    help: {},
    resetUsername: '',
    unreadNotification: 0,
    register: {
        code: null,
        vat: null
    },
    billsYear: null,
    userRegistered: {
        name:'',
        lastname:'',
        phone:'',
        friendCode:'',
        email: '',
        password: '',
        token: ''
    },
    history: [],
    alertAutoReading: false,
    showCList:false,
    userContracts:[],
    currentUserContract:undefined,
    showAddNewContract:false,
    showDContract:false,
    deleteCurrentContract:null,
    autoReadingSupply: null,
    autoReadingSended:[],
    coupon:'',
    showMenuInvoice:false,
    notes:[],
    titleNoteList:'',
    supplyPoints:[],
    filterInvoiceStatus:0,
    mainAddress:'',
}

const resetState = JSON.parse(JSON.stringify(defaultState))

const resetSSOState = JSON.parse(JSON.stringify(defaultSSOState))

const store = createStore({
    state () {
        return defaultState
    },
    getters: {
        accessToken: state => state.accessToken
    },
    mutations: {
        reset(state) {
            let dashboard = state.dashboard
            Object.assign(state, resetState);
            state.dashboard = dashboard;
        },
        resetSSO(state) {
            let dashboard = state.dashboard
            Object.assign(state, resetSSOState);
            state.dashboard = dashboard;
        },
        resetHistory(state) {
            state.history = []
        },
        pushHistory(state, val) {
            state.history.push(val)
            if (state.history.length > 5) {
                state.history.shift()
            }
        },
        setBillsYear(state, val) {
            state.billsYear = val
        },
        setUserRegistered(state, val) {
            state.userRegistered = val
        },
        setRegister(state, val) {
            state.register = val
        },
        setUnreadNotification(state, val) {
            state.unreadNotification = val
        },
        setResetUsername(state, val) {
            state.resetUsername = val
        },
        setHelp(state, val) {
            state.help = val
        },
        setToast(state, val) {
            state.toast = val
            setTimeout(() => {
                state.toast.show = false
            }, val.timeout)
        },
        setShowBack(state, val) {
            state.showBack = val
        },
        setActiveHome(state, val) {
            state.activeHome = val
        },
        setShowHistory(state, val) {
            state.showHistory = val
        },
        setDashboard(state, val) {
            state.dashboard = val
        },
        setSupply(state, val) {
            state.supply = val
        },
        setType(state, val) {
            state.type = val
        },
        setAccessToken(state, val) {
            state.accessToken = val
        },
        setAccessTokenDWH(state, val) {
            state.accessTokenDWH = val
        },
        setLoader(state, val) {
            state.loader = val
        },
        setAlertAutoReading(state, val) {
            state.alertAutoReading = val
        },
        setNoteList(state, val) {
            state.notes = val
        },
        setTitleNoteList(state, val) {
            state.titleNoteList = val
        },
        setUser(state, val) {
            state.user = val
        },
        showContractList(state, val) {
            state.showCList = val
        },
        setUserContractList(state, val) {
            state.userContracts = val
        },
        setUserContract(state, val) {
            state.currentUserContract = val
        },
        showAddContract(state, val) {
            state.showAddNewContract = val
        },
        showDeleteContract(state, val) {
            state.showDContract = val
        },
        setDeleteContract(state, val) {
            state.deleteCurrentContract = val
        },
        setAutoReadingSupply(state, val) {
            state.autoReadingSupply = val
        },
        setAutoReadingSended(state, val) {
            state.autoReadingSended = val
        },
        setCoupon(state, val) {
            state.coupon = val
        },
        showInvoiceMenu(state, val) {
            state.showMenuInvoice = val
        },
        setSupplyPointList(state, val) {
            state.supplyPoints = val
        },
        setFilterInvoiceStatus(state, val) {
            state.filterInvoiceStatus = val
        },
        setMainAddress(state, val) {
            state.mainAddress = val
        },
        
        initialiseStore(state) {
            if (localStorage.getItem('store_lucegas')) {
                this.replaceState(
                    Object.assign(state, JSON.parse(localStorage.getItem('store_lucegas')))
                );
            }
        },
    }
})
store.subscribe((mutation, state) => {
    localStorage.setItem('store_lucegas', JSON.stringify(state));
});
export default store;