<template>
  <div v-if="this.$store.state.accessTokenDWH && type === 'ok'" class="payment-block" :class="{ 'payment-block--ok': type === 'ok', 'payment-block--ko': type === 'ko' }">
    <span>
      <img :src="icoCheck">
      {{ $t("payment.regular")}}
    </span>
  </div>
  <div style="cursor: pointer;" v-on:click="goInvoice()" v-if="this.$store.state.accessTokenDWH && type === 'ko'" 
  class="payment-block" :class="{'payment-block--ko' : type === 'ko' ,  'payment-block--disabled' : this.$store.state.filterInvoiceStatus == 0 && this.hasList}">
    <span>
      <img :src="icoKo" v-if="type === 'ko' && !this.hasList">
      <img :src="icoKo" v-if="this.$store.state.filterInvoiceStatus == 2 && this.hasList">
      <img :src="icoGray" v-if="this.$store.state.filterInvoiceStatus == 0 && this.hasList">
      {{ $t("payment.notRegular")}}
    </span>
  </div>
</template>

<script>
import icoCheck from '../assets/ico-check.svg'
import icoKo from '../assets/ico-attention-red.svg'
import icoGray from '../assets/ico-attention-gray.svg'
export default {
  name: 'Payment',
  props: ['type','hasList'],
  data: function () {
    return {
      icoCheck: icoCheck,
      icoKo: icoKo,
      icoGray
    }
  },
  methods: {
    goInvoice : function() {

      if (this.hasList) {
        if (this.$store.state.filterInvoiceStatus == 0) {
          this.$store.commit('setFilterInvoiceStatus', 2);
        } else {
          this.$store.commit('setFilterInvoiceStatus', 0);
        }
        
      } else {
        this.$store.commit('setFilterInvoiceStatus', 2);
        this.$router.push('/pagamenti-fatture');
      }

      
    }
  }
}
</script>
<style lang="scss" scoped>
.payment-block {
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  border-radius: 4px;
  border: solid 1px transparent;
  display: inline-block;

  @include bp($md) {
    padding: 20px 50px;
    font-size: 18px;
  }

  img {
    display: inline-block;
    margin-right: 5px;

    @include bp($md) {
      margin-right: 13px;
    }

  }

  &--ok {
    border-color: var(--green);
    color: var(--green);
  }

  &--ko {
    border-color: var(--red);
    color: var(--red);
  }

  &--disabled {
    border-color: var(--gray);
    color: var(--gray);
  }
}
</style>