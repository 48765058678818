<template>
  <div>
    <section class="bill" :class="'bill--' + $route.params.code.toLowerCase()">
      <div class="container">
        <div class="row" v-if="$store.state.showBack">
          <div class="col-lg-12 pt-4">
            <back :before="beforeBack" />
          </div>
        </div>
        <div class="row bill-header">
          <div class="col-lg-8">
            <tab-header :list="this.active ? [$t('bill.bills'), $t('bill.manage')] : [$t('bill.bills'), $t('bill.view')]" :active-index="activeIndex" @select="(index) => activeIndex = index" />
          </div>
          <div class="col-lg-4 bill-header-code">
            <div>
              {{ $t("bill.supply") }} {{ $route.params.code.toLowerCase() === 'ee' ? $t("bill.supplyEE") : $t("bill.supplyGas") }}{{ this.active ? $t("bill.active") : $t("bill.not_active") }}       
            </div>
            <div>
              <strong>{{ supplyPoint }}</strong>
            </div>
          </div>
        </div>
        <div class="row bill-resume" v-if="activeIndex == 0">
          <div class="col-md-12 col-lg-4">
            <div class="d-flex align-items-center">
              <div class="bill-resume-head">
                <div style="align-items: flex-start;" class="d-flex bill-resume-head-content">
                  <img :src="supplyIcon">
                  <div>
                    <div class="bill-resume-head-content-title">{{ $t("bill.supply") }} {{ $route.params.code.toLowerCase() === 'ee' ? $t("bill.supplyEE") : $t("bill.supplyGas") }}{{ this.active ? $t("bill.active") : '' }}
                        <span v-if="!this.active" style="position: absolute;margin-top: 2px;color: white;font-size: 10px;background-color: red;margin-left: 4px;padding-left:4px;padding-right: 4px;padding-top: 2px;padding-bottom: 2px;border-radius: 2px;">{{ $t("supply.closed") }}</span>
                    </div>
                    <div style="margin-top: 20px;color: #002626;opacity: .5;" v-if="$store.state.supply.invoiceNumber != null" class="bill-resume-head-content-infoinvoice">{{ $t("bill.invoiceTitle")}}</div>
                    <div v-if="$store.state.supply.invoiceNumber != null" class="bill-resume-head-content-infoinvoice">{{$store.state.supply.invoiceNumber }}</div>
                    <div style="margin-top: 10px;color: #002626;opacity: .5;" v-if="$store.state.supply.invoiceDate != null" class="bill-resume-head-content-infoinvoice">{{ $t("bill.invoiceDate")}}</div>
                    <div v-if="$store.state.supply.invoiceDate != null" class="bill-resume-head-content-infoinvoice">{{ $moment($store.state.supply.invoiceDate).format('DD/MM/YYYY')}}</div>
                    <div style="margin-top: 10px;color: #002626;opacity: .5;" v-if="$store.state.supply.competenceDate != null && $store.state.supply.competenceDate.length > 0" class="small-text">{{ $t("bill.invoicePeriod")}}</div>
                    <div v-if="$store.state.supply.competenceDate != null && $store.state.supply.competenceDate.length > 0" class="bill-resume-head-content-infoinvoice">{{ $store.state.supply.competenceDate }}</div>
                    <div style="margin-top: 10px;color: #002626;opacity: .5;" v-if="$store.state.supply.address != null" class="bill-resume-head-content-infoinvoice">{{ $t("bill.invoiceAddress")}}</div>
                    <div v-if="$store.state.supply.address != null" class="bill-resume-head-content-infoinvoice">{{ $store.state.supply.address}}</div>
                  </div>
                </div>
                <div class="col-lg-12 bill-history" style="margin-top: 60px;" v-if="this.$store.state.dashboard && !this.$store.state.dashboard.consumptionStatsVisible && $store.state.showHistory">
                  <c-link @click="goToBills">{{ $t("bill.showHistory") }}</c-link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="d-flex align-items-center">
              <div class="bill-price">
                <div v-if="$store.state.supply.invoiceId != null" class="bill-price-expire">{{ $t("bill.lastInvoice") }}</div>
                <div v-if="$store.state.supply.totalValue != null" class="bill-price-total">
                  {{ formatFloat($store.state.supply.totalValue) }}
                  <span>€</span>
                </div>
                <div v-if="$store.state.supply.expirationDate != null" class="bill-price-expire">{{ $t("bill.expiry") }} {{ $moment($store.state.supply.expirationDate).format('DD/MM/YYYY') }}</div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 text-md-end">
            <a v-bind:style="[this.$store.state.supply.paymentButtonEnabled ? 
            {'width': '180px','text-align': 'center'} : 
            {'width': '180px','text-align': 'center','pointer-events':'none','cursor':'default','color':'gray','border-color':'gray'}]" 
            v-if="this.$store.state.supply.paymentButtonVisible" 
            @click="this.payNow" target="_blank" class="bill-resume-download">
             {{ this.$store.state.supply.paymentButtonLabel }}
            </a>
            <div v-if="this.$store.state.supply.paymentButtonVisible"  style="width: 15px;height: 5px;"></div>
            <a style="width: 180px;height: 50px;"  v-if="this.$store.state.supply.pdfURL != this.$store.state.supply.syntheticPdfURL" @click="() => this.$store.commit('showInvoiceMenu',true)" class="bill-resume-download">
                  <img :src="icoDownload"> {{ $t("bill.download") }}
                </a>
            <a style="width: 180px;height: 50px;" v-if="this.$store.state.supply.pdfURL == this.$store.state.supply.syntheticPdfURL" :href="this.$store.state.supply.pdfURL" target="_blank" class="bill-resume-download">
                <img :src="icoDownload"> {{ $t("bill.download") }}
            </a>
          </div>
        </div>

        <div class="row bill-data" v-if="this.$store.state.dashboard && this.$store.state.dashboard.consumptionStatsVisible && activeIndex == 0">
          <div class="col-lg-4 col-md-6 mb-4" v-if="consumption.hasOwnProperty('monthConsumption') && consumption.monthConsumption">
            <div class="d-flex justify-content-between">
              <div class="bill-data-details">
                <div class="bill-data-details-title">{{ $t("bill.consumption") }}</div>
                <div class="bill-data-details-number">
                  {{ consumption.monthConsumption ?  Math.round(consumption.monthConsumption) : 0 }}
                  <span v-if="$route.params.code.toLowerCase() === 'ee'">kWh</span>
                  <span v-if="$route.params.code.toLowerCase() === 'gas'">smc</span>
                </div>
              </div>
              <div class="bill-data-desc">
                <div>
                  <div style="display: flex;flex: 1;flex-direction: row;align-items: center;justify-content: center;" class="bill-data-desc-indicator" :class="[ consumption.monthConsumptionPreviousYearPercentageDifference > 0 ? 'bill-data-desc-indicator--red' : (consumption.monthConsumptionPreviousYearPercentageDifference < 0 ? 'bill-data-desc-indicator': 'bill-data-desc-indicator--white') ]">{{ consumption.monthConsumptionPreviousYearPercentageDifference ? (consumption.monthConsumptionPreviousYearPercentageDifference > 0 ? '+'+ Math.round(consumption.monthConsumptionPreviousYearPercentageDifference) :  Math.round(consumption.monthConsumptionPreviousYearPercentageDifference)) : 0 }}%</div>
                  <div class="bill-data-desc-text">{{ $t("bill.consumptionDesc") }}</div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="col-lg-4 col-md-6 mb-4" v-if="consumption.hasOwnProperty('averageThreeMonthsConsumption') && consumption.averageThreeMonthsConsumption">
            <div class="d-flex justify-content-between">
              <div class="bill-data-details">
                <div class="bill-data-details-title">{{ $t("bill.consumptionAverage") }}</div>
                <div class="bill-data-details-number">
                  {{ consumption.averageThreeMonthsConsumption ? consumption.averageThreeMonthsConsumption : 0 }}
                  <span v-if="$route.params.code.toLowerCase() === 'ee'">kWh</span>
                  <span v-if="$route.params.code.toLowerCase() === 'gas'">smc</span>
                </div>
              </div>
              <div class="bill-data-desc">
                <div>
                  <div class="bill-data-desc-indicator" :class="{ 'bill-data-desc-indicator--red': consumption.averageThreeMonthsConsumptionPreviousYearPercentageDifference >= 0 }">{{ consumption.averageThreeMonthsConsumptionPreviousYearPercentageDifference ? (consumption.averageThreeMonthsConsumptionPreviousYearPercentageDifference > 0 ? '+'+consumption.averageThreeMonthsConsumptionPreviousYearPercentageDifference : consumption.averageThreeMonthsConsumptionPreviousYearPercentageDifference) : 0 }}%</div>
                  <div class="bill-data-desc-text">{{ $t("bill.consumptionDesc") }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 mb-4" v-if="consumption.hasOwnProperty('monthPeak') && consumption.monthPeak">
            <div class="d-flex justify-content-between">
              <div class="bill-data-details">
                <div class="bill-data-details-title">{{ $t("bill.consumptionPeak") }}</div>
                <div class="bill-data-details-number">
                  {{ consumption.monthPeak ? consumption.monthPeak : 0 }} <span>kW</span>
                </div>
              </div>
              <div class="bill-data-desc">
                <div>
                  <div class="bill-data-desc-text">{{ $t("bill.consumptionMessage") }}</div>
                </div>
              </div>
            </div>
          </div> -->

          <div class="col-lg-12 bill-history" v-if="$store.state.showHistory">
            <c-link @click="goToBills">{{ $t("bill.showHistory") }}</c-link>
          </div>
        </div>
      </div>
    </section>
    <section v-if="activeIndex == 0 && events.length" class="overflow-hidden">
      <div class="container">
        <div class="row bill-events">
          <div class="col-lg-12">
            <h3 class="bill-events-title">{{ $t("bill.eventsTitle") }}</h3>

            <swiper
              style="overflow:visible"
              :slides-per-view="1"
              :modules="modules"
              :breakpoints="breakpoints"
              :scrollbar="{ draggable: true }"
              @reachEnd="loadMore"
            >
              <template v-for="(e,i) in events" :key="'event-'+i">
                <swiper-slide>
                  <history-event :event="e"/>
                </swiper-slide>
              </template>
            </swiper>

          </div>
        </div>
      </div>
    </section>

    <section v-if="activeIndex == 1" class="manage" :class="'manage--' + $route.params.code.toLowerCase()">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h3 class="manage-title">{{ $t("bill.contract") }}</h3>
          </div>
          <div class="col-md-4 mb-4">
            <div class="manage-block">
              <h3 class="manage-block-title">{{ $t("bill.paymentType") }}</h3>
              <div class="manage-block-bank">
                <img :src="icoBank">
                <div>
                  <div class="manage-block-bank-title">{{ this.getMethodPayment() == "RID" ? $t("bill.paymentTypeBank") : $t("bill.paymentTypeBLP") }}</div>
                  <div class="manage-block-bank-label">{{ this.getMethodPayment() == "RID" ? this.getBankName() : '' }}</div>
                </div>
              </div>
              <c-link v-if="this.active" @click="() => goToHelp(1)">{{ $t("bill.contact") }}</c-link>
            </div>
          </div>

          <div class="col-md-4 mb-4" v-if="this.active">
            <div class="manage-block">
              <h3 class="manage-block-title">{{ $t("bill.voltureTitle") }}</h3>
              <div class="manage-block-text">{{ $t("bill.voltureDesc") }}</div>
              <c-link @click="() => goToHelp(2)">{{ $t("bill.contact") }}</c-link>
            </div>
          </div>

          <div class="col-md-4 mb-4" v-if="this.active">
            <div class="manage-block">
              <h3 class="manage-block-title">{{ $t("bill.addressTitle") }}</h3>
              <div class="manage-block-text">{{ $t("bill.addressDesc") }}</div>
              <c-link @click="() => goToHelp(7)">{{ $t("bill.contact") }}</c-link>
            </div>
          </div>

          <div class="col-lg-12">
            <h3 class="manage-title">{{ $t("bill.counter") }}</h3>
          </div>
          <div class="col-md-12 mb-4">
            <div class="manage-block-counter">
              <div class="manage-block-counter-title">
                <img :src="icoCounter"> {{ $t("bill.yourCounter") }}
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div v-if="$route.params.code.toLowerCase() === 'ee'" class="manage-block-counter-subtitle">{{ $t("bill.pod") }}</div>
                  <div v-if="$route.params.code.toLowerCase() === 'gas'" class="manage-block-counter-subtitle">{{ $t("bill.pdr") }}</div>
                  <div class="manage-block-counter-text">{{ supplyPoint }}</div>
                </div>
                <div class="col-md-4">
                  <div class="manage-block-counter-subtitle">{{ $t("bill.fiscalCode") }}</div>
                  <div class="manage-block-counter-text">{{ $store.state.supply.fiscalCode }}</div>
                </div>
                <div class="col-md-4">
                  <div class="manage-block-counter-subtitle">{{ $t("bill.address") }}</div>
                  <div class="manage-block-counter-text">{{ $store.state.supply.address }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4 mb-4" v-if="this.active">
            <div class="manage-block">
              <h3 class="manage-block-title">{{ $t("bill.verifyTitle") }}</h3>
              <div class="manage-block-text">{{ $t("bill.verifyDesc") }}</div>
              <c-link @click="() => goToHelp(3)">{{ $t("bill.contact") }}</c-link>
            </div>
          </div>

          <div class="col-md-4 mb-4" v-if="this.active">
            <div class="manage-block">
              <h3 class="manage-block-title">{{ $t("bill.powerTitle") }}</h3>
              <div class="manage-block-text">{{ $t("bill.powerDesc") }}</div>
              <c-link @click="() => goToHelp(4)">{{ $t("bill.contact") }}</c-link>
            </div>
          </div>

          <div class="col-md-4 mb-4" v-if="this.active">
            <div class="manage-block">
              <h3 class="manage-block-title">{{ $t("bill.moveTitle") }}</h3>
              <div class="manage-block-text">{{ $t("bill.moveDesc") }}</div>
              <c-link @click="() => goToHelp(5)">{{ $t("bill.contact") }}</c-link>
            </div>
          </div>

          <div class="col-md-4 mb-4" v-if="this.active">
            <div class="manage-block">
              <h3 class="manage-block-title">{{ $t("bill.removeTitle") }}</h3>
              <div class="manage-block-text">{{ $t("bill.removeDesc") }}</div>
              <c-link @click="() => goToHelp(6)">{{ $t("bill.contact") }}</c-link>
            </div>
          </div>
          <div style="height: 80px;" v-if="!this.active && this.windowWidth >= 480">
          </div>
        </div>
      </div>
    </section>
    <popup :urlPdf="this.$store.state.supply.pdfURL" :urlSmallPdf="this.$store.state.supply.syntheticPdfURL" :code="$route.params.code" @close="this.$store.state.showMenuInvoice = false" v-if="this.$store.state.showMenuInvoice">
      <div>
         
         
        </div>
    </popup>
  </div>
</template>

<script>
import TabHeader from "@/components/TabHeader";
import icoDownloadEE from '../assets/ico-download-ee.svg'
import icoDownloadGAS from '../assets/ico-download-gas.svg'
import ee from '../assets/ee.png'
import gas from '../assets/gas.png'
import icoBank from '../assets/ico-bank.svg'
import icoCounter from '../assets/ico-contatore.svg'
import CLink from "@/components/CLink";
import { Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { FreeMode } from "swiper";
import "swiper/swiper.min.css";
import 'swiper/modules/scrollbar/scrollbar.min.css';
import "swiper/modules/free-mode/free-mode.min.css"
import HistoryEvent from "@/components/HistoryEvent";
import Back from "@/components/Back";
import Popup from "@/components/PopupInvoiceMenu";
export default {
  name: 'Bill',
  components: {
    Back,
    HistoryEvent,
    CLink,
    TabHeader,
    Swiper,
    SwiperSlide,
    Popup
  },
  data: function () {
    let supplyPoint = this.$store.state.supply.supplyPoint
    if (this.$store.state.supply.SupplyPoint) {
      supplyPoint = this.$store.state.supply.SupplyPoint
    }

    let active = this.isActive();

    return {
      windowWidth: window.innerWidth,
      activeIndex: 0,
      icoBank: icoBank,
      icoCounter: icoCounter,
      icoDownload: this.$route.params.code.toLowerCase() === 'ee' ? icoDownloadEE : icoDownloadGAS,
      supplyIcon: this.$route.params.code.toLowerCase() === 'ee' ? ee : gas,
      consumption: {},
      events: [],
      modules: [FreeMode, Scrollbar],
      supplyPoint: supplyPoint,
      limit: 5,
      offset: 0,
      end: false,
      active:active,
      breakpoints: {
        1200: {
          slidesPerView: 4
        },
        1025: {
          slidesPerView: 3
        },
        768: {
          slidesPerView: 2
        }
      }
    }
  },
  methods: {
    payNow: function() {

      this.to = setInterval(async() => {

        await this.axios.get(process.env.VUE_APP_API_DWH_URL + 'invoices?invoiceId=' + this.$store.state.supply.invoiceId + '&limit=1&offset=0', this.authDWHSetting)
        .then((response) => {

          if (response != null && response != undefined) {
            const data = response.data;

            if (data != null && data != undefined) {
                const invoices =  data.invoices;
                if (invoices != null && invoices != undefined && Array.isArray(invoices) && invoices.length > 0) {
                  const invoice = invoices[0];

                  if (invoice.paymentStatus == "PAID" || invoice.paymentStatus == "INPAYMENT") {
                    this.$store.commit('setSupply', invoice);
                    clearInterval(this.to);
                    this.to = undefined;
                  }
                } else {
                  clearInterval(this.to);
                  this.to = undefined;
                }
            } else {
              clearInterval(this.to);
              this.to = undefined;
            }
          } else {
            clearInterval(this.to);
            this.to = undefined;
          }
        }).catch((e) => {
          console.log(e,'_aa_xx_error');
          clearInterval(this.to);
          this.to = undefined;
        });

        console.log('_aa_xx_calling_end');
      }, 1000);
      window.open(this.$store.state.supply.paymentURL, '_blank');
    },
    isActive: function() {
      var active = false;

      const userContract = this.$store.state.currentUserContract;
      const supply = this.$store.state.supply;

      const supplies = userContract.supplyPoints;

      for (let index = 0; index < supplies.length; index++) {
        const element = supplies[index];

      
        if (supply.supplyPoint != undefined && supply.supplyPoint != null) {
          if (element.supplyPoint == supply.supplyPoint) {
            active = element.active;
            break;
          }
        } else if (supply.SupplyPoint != undefined && supply.SupplyPoint != null) {
          if (element.supplyPoint == supply.SupplyPoint) {
            active = element.active;
            break;
          }
        }

       

      }
      
      return active;
    },
    getBankName: function() {
      var bankName = "";

      const userContract = this.$store.state.currentUserContract;
      const supply = this.$store.state.supply;

      const supplies = userContract.supplyPoints;

      for (let index = 0; index < supplies.length; index++) {
        const element = supplies[index];

        if (supply.supplyPoint != undefined && supply.supplyPoint != null) {
          if (element.supplyPoint == supply.supplyPoint) {
            bankName = element.bankName;
            break;
          }
        } else if (supply.SupplyPoint != undefined && supply.SupplyPoint != null) {
          if (element.supplyPoint == supply.SupplyPoint) {
            bankName = element.bankName;
            break;
          }
        }

      }
      
      return bankName;
    },
    getMethodPayment: function() {
      var payment = "RID";

      const userContract = this.$store.state.currentUserContract;
      const supply = this.$store.state.supply;

      const supplies = userContract.supplyPoints;

      for (let index = 0; index < supplies.length; index++) {
        const element = supplies[index];

        if (supply.supplyPoint != undefined && supply.supplyPoint != null) {
          if (element.supplyPoint == supply.supplyPoint) {
            payment = element.masterContractPaymentTypeCode;
            break;
          }
        } else if (supply.SupplyPoint != undefined && supply.SupplyPoint != null) {
          if (element.supplyPoint == supply.SupplyPoint) {
            payment = element.masterContractPaymentTypeCode;
            break;
          }
        }

      }
      
      return payment;
    },
    beforeBack: function() {
      this.$store.commit('setBillsYear', this.$moment(this.$store.state.supply.invoiceDate).format('YYYY'))
    },
    goToHelp: function(code) {
      this.$store.commit('setHelp', {
        RequestTypeID: code,
        SupplyPoint: this.supplyPoint
      })
      this.$router.push('/assistenza')
    },
    goToBills: function() {
      this.$router.push('/bollette/' + this.$route.params.code + '/' + this.$moment(this.$store.state.supply.invoiceDate).format('YYYY') + '/' + this.supplyPoint)
    },
    getEvents: async function() {
      await this.axios.get(process.env.VUE_APP_API_DWH_URL + 'events?limit=' + this.limit + '&offset=' + this.offset + '&SupplyPoint=' + this.supplyPoint, this.authDWHSetting)
        .then((response) => {
          this.events.push(...response.data.results)
          this.total = response.data.total

          if (this.total <= this.events.length) {
            this.end = true
          }
        }).catch(() => {});
    },
    loadMore: function () {
      if (!this.end) {
        this.offset += this.limit
        this.getEvents()
      }
    }
  },
  async created() {

    this.$store.commit('setLoader', true)

    if (this.$store.state.dashboard && this.$store.state.dashboard.consumptionStatsVisible) {

      var year = this.$moment().format('YYYY');
      var month = this.$moment().format('MM');

      if (month == '01') {
        month = 12;
        year = year - 1;
      } else {
        month = month - 1;
      }

      console.log(year,month,'_aa_xx_month');

      if (this.$store.state.supply.invoiceDate != null && this.$store.state.supply.invoiceDate != undefined) {
        year = this.$moment(this.$store.state.supply.invoiceDate).format('YYYY');
        month = this.$moment(this.$store.state.supply.invoiceDate).format('MM');
      }


      await this.axios.get(process.env.VUE_APP_API_DWH_URL + this.$route.params.code.toLowerCase() + '/consumption-stats?SupplyPoint=' + this.supplyPoint + '&Year=' + year + '&Month=' + month, this.authDWHSetting)
      .then((response) => {
        this.consumption = response.data
      }).catch(() => {}); 
    }

    await this.getEvents()

    this.$store.commit('setLoader', false)

  },
  beforeUnmount() {
    if (this.to != undefined) {
      clearInterval(this.to);
      this.to = undefined;
    }
  }
}
</script>

<style lang="scss" scoped>
.bill {
  background-color: var(--grey);

  &--ee {
    .bill-resume-download {
      border: solid 2px var(--ee);
      color: var(--ee);
    }

    .bill-price-total {
      color: var(--ee);
    }

    .bill-data-details-number {
      color: var(--ee);
    }
  }

  &--gas {
    .bill-resume-download {
      border: solid 2px var(--gas);
      color: var(--gas);
    }

    .bill-price-total {
      color: var(--gas);
    }

    .bill-data-details-number {
      color: var(--gas);
    }
  }

  &-header {
    padding: 35px 0;

    @include bp($md) {
      padding: 35px 0 55px;
    }

    &-code {
      opacity: 0.5;
      font-size: 12px;
      font-weight: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--black);
      padding-top: 20px;

      @include bp($md) {
        padding-top: 0;
        text-align: right;
      }
    }
  }

  &-resume {
    padding-bottom: 30px;

    @include bp($md) {
      padding-bottom: 70px;
    }

    &-download {
      padding: 13px 40px;
      border-radius: 4px;
      font-size: 12px;
      font-weight: bold;
      line-height: 1;
      border: solid 2px var(--ee);
      color: var(--ee);
      text-decoration: none;
      text-transform: uppercase;
      display: inline-block;
      margin-top: 20px;

      @include bp($md) {
        margin-top: 0;
      }

      img {
        margin-right: 7px;
      }
    }

    &-head {
      img {
        width: 50px;
        margin-right: 15px;

        @include bp($md) {
          width: 70px;
        }
      }

      &-content {
        align-items: center;

        &-title {
          font-size: 16px;
          line-height: normal;
          color: var(--black);
          margin-bottom: 4px;
        }

        &-subtitle {
          font-size: 18px;
          font-weight: normal;
          line-height: normal;
          color: var(--black);

          @include bp($md) {
            font-size: 24px;
          }
        }

        &-infoinvoice {
          font-size: 16px;
          font-weight: normal;
          line-height: normal;
          color: var(--black);

        }
      }
    }
  }

  &-price {
    margin-top: 10px;

    @include bp($md) {
      margin-top: 0;
    }

    &-total {
      font-weight: bold;
      line-height: normal;
      color: var(--ee);
      font-size: 30px;

      @include bp($md) {
        font-size: 50px;
      }

      span {
        font-size: 26px;
        color: var(--black);
        display: inline-block;
        position: relative;
        left: -5px;
      }
    }

    &-expire {
      font-size: 14px;
      font-weight: normal;
      line-height: normal;
      color: var(--black);
    }
  }

  &-data {
    &-details {
      &-title {
        margin: 0 0 16px;
        opacity: 0.8;
        font-size: 12px;
        font-weight: bold;
        line-height: normal;
        text-transform: uppercase;
        color: var(--black);
      }

      &-number {
        font-size: 50px;
        font-weight: bold;
        line-height: normal;
        color: var(--ee);
        white-space: nowrap;

        span {
          font-size: 26px;
          color: var(--black);
          display: inline-block;
          position: relative;
          left: -5px;
        }
      }
    }

    &-desc {
      padding: 0 50px 0 20px;
      margin: 0 0 0 20px;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: end;

      &:before {
        content: '';
        width: 1px;
        height: 100%;
        background-color: rgba(0,0,0,.12);
        position: absolute;
        top: 0;
        left: 0;
      }

      &-indicator {
        margin: 0 0 5px;
        font-size: 22px;
        font-weight: bold;
        line-height: normal;
        color: var(--black);
        position: relative;
        width: 100%;

        &::after {
          content: '';
          display: inline-block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 8px 7px 0 7px;
          border-color: var(--light-green) transparent transparent transparent;
          margin-left: 16px;
          position: relative;
          top: -2px;
        }

        &--red {
          &::after {
            content: '';
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 7px 8px 7px;
            border-color: transparent transparent var(--light-red) transparent;
            margin-left: 16px;
            position: relative;
            top: -2px;
          }
        }

        &--white {
          &::after {          
            border-color: transparent transparent transparent transparent;
          }
        }
      }

      &-text {
        margin: 5px 0 0;
        font-size: 12px;
        font-weight: 300;
        line-height: normal;
        color: var(--black);
        max-width: 120px;
        padding-bottom: 10px;
      }
    }
  }

  &-history {
    padding-top: 20px;
    padding-bottom: 40px;

    @include bp($md) {
      padding-top: 60px;
    }

    &-title {
      margin: 0 287px 39px 0;
      font-size: 20px;
      font-weight: normal;
      line-height: normal;
      color: var(--title-color);
    }
  }

  &-events {
    padding-top: 30px;
    padding-bottom: 60px;

    &-title {
      margin-bottom: 0;
      padding-bottom: 40px;
      font-size: 20px;
      font-weight: normal;
      line-height: normal;
      color: var(--title-color);
    }
  }
}

.manage {
  background-color: var(--grey);

  &--ee {
    .manage-block-counter {
      background-image: var(--gradient-energy-reverse);
    }
  }

  &--gas {
    .manage-block-counter {
      background-image: var(--gradient-gas);
    }
  }

  &-title {
    margin-bottom: 0;
    padding-bottom: 20px;
    padding-top: 20px;
    font-size: 20px;
    font-weight: normal;
    line-height: normal;
    color: var(--title-color);
  }

  &-block {
    padding: 20px;
    background-color: var(--white);
    height: 100%;

    &-title {
      opacity: 0.8;
      font-size: 12px;
      font-weight: bold;
      line-height: normal;
      color: var(--title-color);
      text-transform: uppercase;
    }

    &-text {
      margin: 8px 0 11px;
      font-size: 14px;
      font-weight: 300;
      font-style: normal;
      line-height: normal;
      color: var(--black);
    }

    &-counter {
      background-image: var(--gradient-ee);
      padding: 20px;
      border-radius: 4px;

      &-title {
        margin-bottom: 30px;
        font-size: 14px;
        font-weight: normal;
        line-height: normal;
        color: var(--white);

        img {
          width: 30px;
          margin-right: 10px;
        }
      }

      &-subtitle {
        margin-bottom: 8px;
        font-size: 12px;
        font-weight: 300;
        line-height: normal;
        color: var(--white);
        text-transform: uppercase;
      }

      &-text {
        font-size: 18px;
        font-weight: bold;
        line-height: normal;
        color: var(--white);
      }
    }

    &-bank {
      margin: 14px 0;
      padding: 5px 15px;
      border-radius: 4px;
      background-color: var(--grey2);
      display: flex;

      img {
        width: 30px;
        margin-right: 15px;
      }

      &-title {
        margin: 0 0 4px;
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        line-height: normal;
        color: var(--black);
      }

      &-label {
        margin: 0;
        font-size: 12px;
        font-weight: bold;
        line-height: normal;
        color: var(--black);
      }
    }
  }
}
</style>