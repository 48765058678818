import { createApp } from 'vue'
import router from './router'
import App from './App'
import axios from "axios";
import store from './store/store'
import moment from "moment";
import { createI18n } from 'vue-i18n'
import messages from "@/locale/messages";

store.commit('initialiseStore');

const i18n = createI18n({
    locale: store.state.locale,
    fallbackLocale: 'it',
    messages
})

const app = createApp(App)

// CONFIG AJAX
const instance = axios.create({
    baseURL: process.env.VUE_APP_SITE_URL
});

app.config.globalProperties.axios = instance
app.config.globalProperties.$siteURL = process.env.VUE_APP_SITE_URL
app.config.globalProperties.$moment = moment

app.mixin({
    data: function () {
        return {
            authSetting: {
                headers: {
                    Authorization: 'Bearer ' + this.$store.state.accessToken,
                }
            },
            authDWHSetting: {
                headers: {
                    Authorization: 'Bearer ' + this.$store.state.accessTokenDWH,
                }
            },
            deviceType:(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
        }
    },
    methods: {
        formatFloat(number) {
            return parseFloat(number).toLocaleString(this.$store.state.locale, { minimumFractionDigits: 2 }).replace(',00', '')
        }
    }
})

app.use(store)
app.use(router)
app.use(i18n)
app.mount('#app')
