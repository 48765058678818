<template>
  <div class="container">
    <div class="container-app">
      <div class="row">
          
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: 'SSOLogin',
  data: function () {
    return {
      code: '',
    }
  },
  computed: {
  components: {
   
  },
  methods: {
    checkUser: async function(userData) {
      this.$store.commit('setUserRegistered', { email: this.username, phone: userData.phoneNumber, password: this.password, token: userData.token })
      let provisionalHeaders = {
        headers: {
          Authorization: 'Bearer ' + userData.token,
        }
      }
      if (!userData.emailConfirmed) {
        await this.axios.post(process.env.VUE_APP_API_AUTH_URL + 'validate-email', {}, provisionalHeaders)
          .then(async () => {
            this.$router.push('/conferma-email')
          }).finally(() => {
            this.$store.commit('setLoader', false)
          });
        return
      }
      if (!userData.phoneConfirmed) {
        await this.axios.post(process.env.VUE_APP_API_AUTH_URL + 'validate-phone-number', {}, provisionalHeaders)
          .then(async () => {
            this.$router.push('/conferma-telefono')
          }).finally(() => {
            this.$store.commit('setLoader', false)
          });
      }
    }
  }
  },
  created() {

  },
  mounted() {

    this.code = this.$route.params.code;

    if (this.code != null && this.code != undefined) {
      this.$store.commit('setLoader', true);

      this.$store.commit('resetSSO');
      this.$store.commit('resetHistory');

      this.axios.post(process.env.VUE_APP_API_AUTH_URL + 'login', {
          "SSOToken": this.code
        }).then((response) => {
          if (response.data.tokenType === 'AccessToken') {
            this.$store.commit('setAccessToken', response.data.token)
            this.$store.commit('setType', 'private')
            this.$store.commit('showContractList', true)
            this.$router.push('/')
            this.$store.commit('setLoader', false)
          } else {
            this.checkUser(response.data)
          }
        }).catch(async (error) => {
          if (error.response.data.tokenType && error.response.data.tokenType === 'ProvisionalToken') {
            this.checkUser(error.response.data)
          } else {
            this.$store.commit('setLoader', false)
            this.$router.push('/login');
          }
        });
      
      
    } else {
      this.$router.push('/login');
    }

    
  }
}
</script>