<template>
  <div class="c-popup" v-if="this.showAddContract == 0">
    <div class="c-popup-content" style="padding: 0px;">
      <div style="height: 122px;background-color: white;padding: 43px 88px 27px;">

        <span style="font-size: 22px;color: #002626; line-height: 1.18;">
          {{ $t('popupUserContractList.title') }}        
        </span>
      </div>
     
      <div style="margin: 27px 0 30px;
          background-color: #f5f5f5;
            border: solid 1px #dcdcdc;
          width:100%">
        <ul v-if="this.$store.state.userContracts.length > 0" style="padding-top: 36px;padding-bottom: 30px;">
          <li v-for="item in this.$store.state.userContracts" :key="item.user.customerCode" style="max-width: 375px;border-radius: 5px;margin: auto;padding-right: 12px;padding-left: 12px;">
            <div style="cursor: pointer;display: flex; flex-direction: row;align-items: center;margin-bottom: 11px; background-color: white;padding-top: 16px;padding-bottom: 16px;border-radius: 5px;" 
            @click="chooseUserContract(item)">
                <div style="min-width: 30px;background-color: #f5f5f5;width:30px;height: 30px;border-radius: 15px;margin-left: 25px;">
                </div>
                <div style="display: flex;flex:1;flex-direction: column;margin-left: 15px;">
                    <span style="font-size: 12px;color: #000;">
                      {{ this.$t("popupUserContractList.item_code_customer") }}
                    </span>
                    <span style="font-size: 18px;color: #000;font-weight: bold;margin-top: 3px;">
                     {{item.user.customerCode}}
                    </span>
                    <span style="font-size: 12px;color: #000;margin-top: 3px;">
                     {{this.formatTypeContract(item)}}
                    </span>
                </div>
                <div style="min-width: 45px;height: 45px; width: 45px;background-color: #f5f5f5;border-radius: 8px;padding: 8px;">
                    <img v-if="item.user.supplyTypeCode.toLowerCase().indexOf('ee') > -1" src="../assets/ico_light_on.png" width="30" height="30" />
                    <img v-if="item.user.supplyTypeCode.toLowerCase().indexOf('ee') == -1" src="../assets/ico_light_off.png" width="30" height="30" />
                  </div>
                  <div style="min-width: 45px;height: 45px; width: 45px;background-color: #f5f5f5;border-radius: 8px;padding: 8px;margin-left: 5px;">
                    <img v-if="item.user.supplyTypeCode.toLowerCase().indexOf('gas') > -1" src="../assets/ico_gas_on.png" width="30" height="30" />
                    <img v-if="item.user.supplyTypeCode.toLowerCase().indexOf('gas') == -1" src="../assets/ico_gas_off.png" width="30" height="30" />
                  </div>
                  <div style="min-width: 20px;width: 20px;height: 100%;"></div>
            </div>
          </li>
        </ul>
        <div v-if="this.$store.state.userContracts.length == 0" style="padding-top: 36px;padding-bottom: 30px;min-height: 180px;background-color: white;display: flex;align-items: center;flex-direction: column;">
          <img src="../assets/ico_empty_list.png" width="83" height="83" />
          <span style="font-size: 16px;font-weight: 300;color: #002626;margin-top: 20px;">
          {{ this.$t("popupUserContractList.add_contract_empty_list") }}    
        </span>
        </div>
      </div>
      <div style="width: 100%;min-height: 100px;display: flex;align-items: center;justify-content: center;max-width: 375px;padding-right: 12px;padding-left: 12px;margin: auto;">
        <div style="cursor: pointer;display: flex;flex-direction: row;width: 100%;min-height: 58px;border-radius: 4px;  border: solid 1px #000;align-items: center;justify-content: center; border-radius: 4px;"
        @click="addNewContract"
        >
          <img style="height: 20px; height: 20px;color: #000;" src="../assets/ico_add.png">
          <span style="font-size: 14px;font-weight: bold;color: #000;margin-left: 15px;">
                      {{ this.$t("popupUserContractList.button_add_contract") }}
          </span>
        </div>
      </div>
      <div style="width: 100%;max-width: 375px;margin-top: 38px;display: flex;flex-direction: row;padding-right: 12px;padding-left: 12px;margin-left: auto;margin-right: auto;">
        <div style="background-color:#606060;opacity: 0.5;width:100%;height:2px">    
        </div>
        <span style="display: inline-block;font-size: 14px;font-weight: bold;color: #000;opacity: 0.5;align-self: center;justify-self: center;background-color: white;padding-left: 14px;padding-right: 14px;margin-top: -10px;">
          {{ this.$t("popupUserContractList.or") }}
        </span>
        <div style="background-color:#606060;opacity: 0.5;width:100%;height:2px">    
        </div>
      </div>
      <div style="height: 33px;"></div>
      <div style="display: flex;flex-direction: row;align-items: center;justify-content: center;">
        <span style="display: inline-block;font-size: 14px;color: #000;">
          {{ this.$t("popupUserContractList.support_title") }}
        </span>
      </div>
    
      <div style="display: flex;flex-direction: row;align-items: center;justify-content: center;">
        <span style="display: inline-block;font-size: 14px;color: #000;background-color: white;">
          {{ this.$t("popupUserContractList.support_subtitle_1") }}
        </span>
        <a style="margin-left: 5px;" :href="'tel:' + $store.state.dashboard.contacts.callCenterFreePhoneNumber">{{this.$t("popupUserContractList.support_subtitle_2")}}</a>
      </div>
      <div style="height: 40px;"></div>
    </div>
  </div>
  <div class="c-popup" v-if="this.showAddContract == 1">
    <div class="c-popup-content" style="padding: 46px 0px 0px 0px;">
      <span v-if="this.windowWidth < 480" @click="showAddContract = 0" style="cursor: pointer;margin-left: 12px">
        <span style="color: #002626;font-size: 14px;">
          <img :src="icoArrowRight" alt="" style="width: 19px;
            height: auto;
            margin-right: 14px;
            transform: rotate(180deg);">
        </span>
        {{  $t('popupUserContractList.text_back') }}
      </span>
      <span  v-if="this.windowWidth >= 480" @click="showAddContract = 0" style="cursor: pointer;margin-left: 56px">
        <span style="color: #002626;font-size: 14px;">
          <img :src="icoArrowRight" alt="" style="width: 19px;
            height: auto;
            margin-right: 14px;
            transform: rotate(180deg);">
        </span>
        {{  $t('popupUserContractList.text_back') }}
      </span>
      <div style="max-width: 370px;margin: auto;padding: 42px 12px 27px;display: flex;flex-direction: column;">

        <span v-if="this.windowWidth < 480" style="font-size: 30px;color: #002626;">
          {{ $t('popupUserContractList.add_contract_title') }}        
        </span>
        <span v-if="this.windowWidth >= 480" style="font-size: 34px;color: #002626;">
          {{ $t('popupUserContractList.add_contract_title') }}        
        </span>
        <span style="font-size: 16px;font-weight: 300;color: #002626;margin-top: 20px;">
          {{ $t('popupUserContractList.add_contract_subtitle') }}        
        </span>
        <form @submit="addContract">
          <div class="row">
            <div class="col-md-12 mb-2" style="margin-top: 20px;">
                  <div class="form-floating">
                    <input type="text" class="form-control" :class="{'is-invalid': errors.code}" id="code" v-model="code" :placeholder="$t('popupUserContractList.code')">
                    <label for="code">{{ $t("popupUserContractList.code")}}</label>
                    <div class="invalid-feedback" v-if="errors.code">
                      {{ $t("popupUserContractList.errorCode")}}
                    </div>
                  </div>
            </div>
            <div class="col-md-12 mb-2" style="margin-top: 20px;">
                  <div class="form-floating">
                    <input type="text" class="form-control"  :class="{'is-invalid': errors.vat}" id="vat" v-model="vat" :placeholder="$t('popupUserContractList.vat')">
                    <label for="code">{{ $t("popupUserContractList.vat")}}</label>
                    <div class="invalid-feedback" v-if="errors.code">
                      {{ $t("popupUserContractList.errorVat")}}
                    </div>
                  </div>
            </div>
          </div>
          <div class="invalid-feedback" v-if="errors.errorNoContract">
                {{ $t("popupUserContractList.errorNoContract")}}
          </div>
          <div class="invalid-feedback" v-if="errors.errorServer">
                {{ $t("popupUserContractList.errorServer")}}
          </div>
          <div style="margin-top: 72px;margin-bottom: 75px;">
            <button type="submit" class="btn btn-big text-uppercase w-100 mt-3" :disabled="!validForm">{{ $t("popupUserContractList.continue")}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="c-popup" v-if="this.showAddContract == 2">
    <div class="c-popup-content" style="padding: 46px 0px 0px 0px;">
      <span v-if="this.windowWidth < 480" @click="showAddContract = 1" style="cursor: pointer;margin-left: 12px;">
        <span style="color: #002626;font-size: 14px;">
          <img :src="icoArrowRight" alt="" style="width: 19px;
            height: auto;
            margin-right: 14px;
            transform: rotate(180deg);">
        </span>
        {{  $t('popupUserContractList.text_back') }}
      </span>
      <span v-if="this.windowWidth >= 480" @click="showAddContract = 1" style="cursor: pointer;margin-left: 56px;">
        <span style="color: #002626;font-size: 14px;">
          <img :src="icoArrowRight" alt="" style="width: 19px;
            height: auto;
            margin-right: 14px;
            transform: rotate(180deg);">
        </span>
        {{  $t('popupUserContractList.text_back') }}
      </span>
      <div style="max-width: 370px;margin: auto;padding: 42px 12px 27px;display: flex;flex-direction: column;">

        <span style="font-size: 34px;color: #002626;">
          {{ $t('popupUserContractList.add_contract_confirm_title') }}        
        </span>
        <span style="font-size: 16px;font-weight: 300;color: #002626;margin-top: 20px;">
          {{ $t('popupUserContractList.add_contract_confirm_subtitle',{email: this.email}) }}        
        </span>
        <div style="height: 20px;"></div>
        <form @submit="confirm">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-floating">
                    <input type="number" class="form-control" :class="{'is-invalid': errors.otp}" id="otp" v-model="otp" :placeholder="$t('confirmPhone.otp')">
                    <label for="otp">{{ $t("popupUserContractList.otp")}}</label>
                    <div class="invalid-feedback" v-if="errors.otp">
                      {{ $t("popupUserContractList.errorOtp")}}
                    </div>
                    <div class="invalid-feedback" v-if="errors.errorServer">
                      {{ $t("popupUserContractList.errorServer")}}
                    </div>
                    <div class="setreset-text">{{ $t("popupUserContractList.textOtp")}}</div>
                  <div class="setreset-link" @click="resend">{{ $t("popupUserContractList.linkOtp")}}</div>
                  </div>
                  
                </div>
              </div>
              <button type="submit" class="btn btn-big text-uppercase w-100 mt-4" :disabled="!validReset">{{ $t("popupUserContractList.reset")}}</button>
          </form>
      </div>
    </div>
  </div>
  <div class="c-popup" v-if="this.showAddContract == 3">
    <div class="c-popup-content" style="padding: 46px 0px 0px 0px;">
      <div style="max-width: 370px;margin:auto;padding: 42px 12px 27px;display: flex;flex-direction: column;align-items: center;">

        <img src="../assets/ico_check_black.png" width="83" height="83" />
        <span style="font-size: 34px;color: #002626;margin-top: 39px;">
          {{ $t('popupUserContractList.add_contract_confirm_success_title') }}        
        </span>
        <span style="font-size: 16px;font-weight: 300;color: #002626;margin-top: 20px;">
          {{ $t('popupUserContractList.add_contract_confirm_success_subtitle') }}        
        </span>
        <div style="height: 30px;width: 100%;"></div>
        <button @click="successOnBack" class="btn btn-big text-uppercase w-100 mt-4">{{ $t("popupUserContractList.add_contract_confirm_success_back")}}</button>
        <div style="height: 30px;"></div>
      </div>
    </div>
  </div>
</template>
<script>
import icoArrowRight from '../assets/ico-arrow-right.png'

export default {
  name: 'PopupUserContractList',
  props: {
  },
  data: function () {
    return {
      windowWidth: window.innerWidth,
      icoArrowRight: icoArrowRight,
      showAddContract:0,
      code:'',
      vat:'',
      email:'',
      vendorCustomerId:'',
      otp:'',
      errors:{
        code:false,
        vat:false,
        otp:false,
        errorServer:false,
        errorNoContract:false
      }
    }
  },
  computed: {
    validForm(){
      let valid = true
      if(!this.code) {
        valid = false
      } else if(!this.vat) {
        valid = false
      }
      return valid
    },
    validReset(){
      let valid = true
      if(!this.otp) {
        valid = false
      }
      return valid
    }
  },
  methods: {
    formatTypeContract: function(item) {
      var result = "";

      if (item.user.customerTypeCode.toLowerCase() == "piva") {
        result = this.$t("popupUserContractList.item_type_business");
      } else if (item.user.customerTypeCode.toLowerCase() == "cond") {
        result = this.$t("popupUserContractList.item_type_cond");
      } else if (item.user.customerTypeCode.toLowerCase() == "dom") {
        result = this.$t("popupUserContractList.item_type_domestic");
      }

      return result;
    },
    chooseUserContract: function(item) {

      this.$store.commit('setUserContract',item);
      this.$store.commit('setAccessTokenDWH',item.user.dwhAccessToken);

      this.$router.go();

      this.$store.commit('showContractList', false);
      this.$store.commit('setLoader', true);

      
    },
    addNewContract: function() {
        this.showAddContract = 1;
    },
    addContract: async function(ev) {
      ev.preventDefault();

      this.errors.code = false
      this.errors.vat = false
      this.errors.errorServer = false

      if (!this.code) {
        this.errors.code = true
      }

      if (!this.vat) {
        this.errors.vat = true
      }

      if (this.errors.code == false && this.errors.vat == false) {
        this.$store.commit('setLoader', true);

        await this.axios.get(process.env.VUE_APP_API_AUTH_URL + 'user-contracts?customerCode=' + this.code + '&vatFiscalCode=' + this.vat, this.authSetting)
        .then(async(response) =>   {

          if (response.data && Array.isArray(response.data) && response.data.length > 0) {

            var contract = response.data[0];

            var user = contract.user;

            if (user) {
              this.connectContract(user.vendorCustomerId,user.customerCode,user.vatFiscalCode,"");
            } else {
              this.errors.errorNoContract = true;
              this.$store.commit('setLoader', false);
            }

            console.log(contract,'_aa_xx');
          } else {
            this.errors.errorNoContract = true;
            this.$store.commit('setLoader', false);
          }

        }).catch(async() => {
          this.errors.errorServer = true;
          this.$store.commit('setLoader', false);
        });

        
      }
    },
    connectContract: async function(vendorCustomerId,customerCode,vatFiscalCode,email) {
      await this.axios.patch(process.env.VUE_APP_API_AUTH_URL_API + 'user-contract/connect', {
        VendorCustomerId: vendorCustomerId,
        CustomerCode: customerCode,
        VatFiscalCode : vatFiscalCode,
        VerificationEmailAddress : email.length > 0 ? email : ""
      }, this.authSetting).then(async(response) => {       
        if (response.data.result) {
          this.code = customerCode;
          this.vat = vatFiscalCode;
          this.email = response.data.email
          this.vendorCustomerId = vendorCustomerId

          this.showAddContract = 2;
        } else {
          this.errors.errorServer = true;
        }

        this.$store.commit('setLoader', false);
      }).catch(() => {
        this.errors.errorServer = true;
        this.$store.commit('setLoader', false)
      });
    },
    resend: function() {
      this.$store.commit('setLoader', true)
      this.connectContract(this.vendorCustomerId,this.code,this.vat,"");
    },
    confirm: async function(ev) {
      ev.preventDefault();

      this.errors.otp = false
      this.errors.errorServer = false

      if (!this.otp) {
        this.errors.otp = true
      }

      if (this.errors.otp == false) {
        this.$store.commit('setLoader', true)

        await this.axios.patch(process.env.VUE_APP_API_AUTH_URL_API + 'user-contract/verify-email', {
        VendorCustomerId: this.vendorCustomerId,
        CustomerCode: this.code,
        VatFiscalCode: this.vat,
        OTP: this.otp
      }, this.authSetting).then(async(response) => {       
        if (response.data.result) {
          this.code = ''
          this.vat = ''
          this.email = ''
          this.vendorCustomerId = ''
          this.otp = ''

          this.showAddContract = 3;
        } else {
          this.errors.errorServer = true;
        }

        this.$store.commit('setLoader', false);
      }).catch(() => {
        this.errors.errorServer = true;
        this.$store.commit('setLoader', false)
      });
      }
    },
    successOnBack: async function(ev) {
      ev.preventDefault();

      this.$store.commit('setLoader', true);
     

      await this.axios.get(process.env.VUE_APP_API_AUTH_URL + 'user-contracts', this.authSetting)
          .then((response) => {
            this.$store.commit('setUserContractList',response.data);

            if (response.data.length == 1) {
              this.showPopup = false;
              this.$store.commit('setUserContract',response.data[0]);
              this.$store.commit('setAccessTokenDWH',response.data[0].user.dwhAccessToken);
              this.$store.commit('showContractList', false);

              this.$router.go();
            } else {
              this.showAddContract = 0;
            }
            this.$store.commit('setLoader', false);
          }).catch(() => {
            this.$store.commit('setLoader', false);
          });
    }
  }
}
</script>
<style lang="scss" scoped>
.c-popup {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.5);
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    z-index: 1;
  }

  &-content {
    z-index: 2;
    position: relative;
    width: 100%;
    max-width: 630px;
    border-radius: 8px;
    border: solid 1px #dcdcdc;
    background-color: var(--white);
    max-height: 80%;
    overflow: auto;
    
    padding: 60px 20px;
    width: 90%;

    @include bp($md) {
      padding: 60px 100px;
    }
    
  }
  .setreset {
  &-link {
    
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    color: var(--black);
    text-decoration: underline;
    cursor: pointer;
  }

  &-text {
    margin: 35px 0 0;
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
    color: var(--title-color);
  }
}
}

</style>