<template>
  <div class="card gradient" style="display: flex;">
    <div class="container" style="display: flex;flex-direction: row;justify-items: center;align-items: center;">
      <div class="card-body" style="display: flex;flex-direction: row;justify-items: center;align-items: center;">
        <img style="min-width:30px;height: 30px;width: 30px;display: flex;" :src="icoContatore" alt="">
        <p class="card-text" style="margin-left: 35px;">
          <slot name="card-text"></slot>
        </p>
        <a style="margin-left:35px;display: flex;" :href="href">
          <img style="height: 20px;width: 20px;min-width:20px" :src="icoArrowRight" alt="">
        </a>
      </div>
       
    </div>
  </div>
</template>

<script>
import icoArrowRight from '../assets/ico-arrow-right-black.png'
import icoContatore from '../assets/ico-contatore-black.png'
export default {
  name: 'CardGradientAutoReading',
  props: ['href'],
  data: function () {
    return {
      icoArrowRight: icoArrowRight,
      icoContatore:icoContatore
    }
  }
}
</script>
<style lang="scss" scoped>
.card.gradient {
  padding: 38px 29px 30px;
  border: none;
  border-radius: 0;
  position: relative;

  @include bp($lg) {
    
  }

  .card-body {
    padding: 0;
    z-index: 5;

    .card-title {
      font-size: 26px;
    }

    .card-text {
      font-size: 16px;
      margin: 27px 0 21px;
      font-weight: 300;
    }
  }

  .card-sub-text {
    margin: 20px 0 16px;
    opacity: 0.7;
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-image: linear-gradient(135deg, #f19711 2%, #2279c1 99%);
  }
}
</style>