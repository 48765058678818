<template>
  <!--    header private-->
  <header v-if="type === 'private' && loaded === loadedCall" class="private border-bottom">
    <div class="container">
      <nav class="navbar navbar-expand-xl">
        <div class="container">
          <div class="navbar-brand">
            <router-link to="/">
              <img :src="$siteURL + '/build/frontend/images/luce-e-gas-official.png'" alt="">
            </router-link>
          </div>
          <button class="navbar-toggler" id="close-menu" type="button" data-bs-toggle="collapse" data-bs-target="#navbarPrivate" aria-controls="navbarPrivate" aria-expanded="false" aria-label="Toggle navigation">
              <img class="is-open" :src="$siteURL + '/build/frontend/images/ico-menu.png'" />
              <img class="is-close" :src="$siteURL + '/build/frontend/images/ico-close.png'" />
          </button>
          <div class="collapse navbar-collapse" id="navbarPrivate">
            <ul class="item-nav" id="main-nav">
              <div v-if="this.$store.state.currentUserContract" class="is-mobile" style="display: flex;flex-direction: column;margin-left: 16px;margin-right: 10px;">
                          <span style="font-size: 16px;color: white;opacity: 0.5;">{{ $t("header.menu_cod_cliente") }}</span>
                          <span style="font-size: 18px;color:white;">{{ this.$store.state.currentUserContract.user.customerCode }}</span>
              </div>
              <li v-for="(item, i) in this.snippetHeaderItems.private_header_menu" :key="i" class="nav-item dropdown">
                <div class="is-mobile" v-if="item.is_only_mobile">
                  <router-link
                  :to="item.menu_item_link.replace($siteURL + '/private', '')"
                  @click="closeMenu"
                  class="dropdown-toggle"
                  :class="{
                    'router-link-active': $store.state.activeHome && item.menu_item_link.replace($siteURL + '/private', '') === '/'
                  }">
                  {{item.menu_item}}
                </router-link>
                </div>
                <div v-if="!item.is_only_mobile">
                  <router-link
                  :to="item.menu_item_link.replace($siteURL + '/private', '')"
                  @click="closeMenu"
                  class="dropdown-toggle"
                  :class="{
                    'router-link-active': $store.state.activeHome && item.menu_item_link.replace($siteURL + '/private', '') === '/'
                  }">
                  {{item.menu_item}}
                </router-link>
                </div>
              </li>
            </ul>

            <div class="d-flex is-desktop item-nav">
              <ul class="item-nav">
                <li class="nav-item search border-start border-end">
                  <router-link to="/notifiche" :class="{ 'notification-dot': $store.state.unreadNotification }">
                    <img :src="$siteURL + '/build/frontend/images/ico-notification.png'"/>
                    {{ $t("header.menu_notification") }}
                  </router-link>
                </li>
                  <li v-if="this.$store.state.currentUserContract"  class="nav-item dropdown border-end">
                    
                      
                      <div class="dropdown-toggle" style="display: flex;flex-direction: row;">
                        <div style="height: 100%;align-items: center;justify-content: center;padding-top: 10x;">
                          <img  style="height: 22px;width: 22px;"  :src="$siteURL + '/build/frontend/images/ico-contract.png'"/>
                        </div>
                       
                        <div style="display: flex;flex-direction: column;margin-left: 16px;margin-top: -10px;margin-right: 10px;">
                          <span style="font-size: 12px;color:#a1a1a1;opacity: 0.5;">{{ $t("header.menu_cod_cliente") }}</span>
                          <span style="font-size: 14px;color:#a1a1a1;">{{ this.$store.state.currentUserContract.user.customerCode }}</span>
                        </div>
                       
                      </div>
                      <div>
                          <span class="dropdown-icon is-desktop">
                            <img :src="$siteURL + '/build/frontend/images/ico-arrow-down.png'"/>
                          </span>
                      </div>
                      <span class="dropdown-icon is-mobile" data-bs-toggle="dropdown" aria-expanded="false">
                              <img :src="$siteURL + '/build/frontend/images/ico-arrow-down-w@2x.png'"/>
                          </span>
                    
                   
                    <ul class="dropdown-menu border border-0" aria-labelledby="primary-1">
                      <li class="dropdown-item-container">
                        <a href="/contract-list">
                          <img  style="height: 22px;width: 22px;"  :src="$siteURL + '/build/frontend/images/ico-contract.png'"/>
                          &nbsp;&nbsp;{{ $t("header.menu_contract_list") }}
                        </a>
                      </li>
                      <li class="dropdown-item-container">
                        <a href="#" @click="preLogout">
                          <img :src="$siteURL + '/build/frontend/images/ico-exit.png'"/>
                          &nbsp;&nbsp;{{ $t("header.menu_contract_logout") }}
                        </a>
                      </li>
                    </ul>
                  </li>
             
              </ul>
            </div>
          </div>
          <div class="login-mobile is-mobile">
            <div class="login-mobile-icons">
              <router-link to="/notifiche" :class="{ 'notification-dot': $store.state.unreadNotification }">
                <img :src="$siteURL + '/build/frontend/images/ico-notification.png'"/>
              </router-link>
              <a href="#" @click="preLogout">
                <img :src="$siteURL + '/build/frontend/images/ico-exit.png'"/>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
    <popup @close="showPopup = false" v-if="showPopup">
      <template #text>
        {{ $t('header.logoutText') }}
        <div class="mt-3">
                <c-button @click="logout" color="blue" width="auto"><template #text>{{ $t('header.logoutBtn') }}</template></c-button>
              </div>
      </template>
    </popup>
  </header>

  <!--    header public-->
  <header v-if="type === 'public' && loaded === loadedCall" class="border-bottom" id="main-header">
    <div class="container">
      <nav class="navbar navbar-expand-xl">
        <div class="container">
          <div class="navbar-brand">
            <a :href="$siteURL">
              <img :src="$siteURL + '/build/frontend/images/luce-e-gas-official.png'" alt="">
            </a>
          </div>
          <button class="navbar-toggler" id="close-menu" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <img class="is-open" :src="$siteURL + '/build/frontend/images/ico-menu.png'" />
            <img class="is-close" :src="$siteURL + '/build/frontend/images/ico-close.png'" />
          </button>
          <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
            <ul class="item-nav">
              <li v-for="(item, i) in mainHeaderItems" :key="item.id + i" class="nav-item dropdown">
                <a :href="item.url" title="{{ item.title }}"  class="dropdown-toggle" id="{{item.id}}">
                  {{ item.title }}
                  <span v-if="item.children.length" class="dropdown-icon is-desktop">
                    <img :src="$siteURL + '/build/frontend/images/ico-arrow-down.png'"/>
                  </span>
                </a>
                <span v-if="item.children" class="dropdown-icon is-mobile" id="primary-{{ item.id }}" data-bs-toggle="dropdown" aria-expanded="false">
                          <img :src="$siteURL + '/build/frontend/images/ico-arrow-down-w@2x.png'"/>
                      </span>
                <ul v-if="item.children" class="dropdown-menu border border-0" aria-labelledby="primary-{{item.id }}">
                  <li v-for="submenuItem in item.children" :key="submenuItem.id" class="dropdown-item-container">
                    <a :href="submenuItem.url" class="dropdown-item">{{submenuItem.title}}</a>
                  </li>
                </ul>
              </li>
            </ul>

            <ul class="item-nav">
              <li v-for="(item, i) in secondaryHeaderItems" :key="item + i" class="nav-item dropdown">
                <a :href="item.url" title="{{ item.title }}">
                  {{ item.title }}
                  <span  v-if="item.children.length" class="dropdown-icon is-desktop">
                    <img :src="$siteURL + '/build/frontend/images/ico-arrow-down.png'"/>
                  </span>
                </a>
                <span v-if="item.children.length" class="dropdown-icon is-mobile" id="secondary-{{ item.id }}" data-bs-toggle="dropdown" aria-expanded="false">
                   <img :src="$siteURL + '/build/frontend/images/ico-arrow-down-w@2x.png'"/>
                </span>
<!--                public header submenu 2-->
                <ul v-if="item.children.length" class="dropdown-menu border border-0" aria-labelledby="secondary-{{ item.id }}">
                  <li v-for="submenuItem in item.children" :key="submenuItem.id + i" class="dropdown-item-container">
                    <a :href="submenuItem.url" class="dropdown-item">{{submenuItem.title}}</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import Popup from "@/components/Popup";
import CButton from "@/components/CButton";
import itemListData from '../assets/json/webapp_settings_area_it.json'
import mainItems from '../assets/json/main_items_it.json'
import secondaryItems from '../assets/json/secondary_items_it.json'

export default {
  name: 'Header',
  props: ['type'],
  emits: ['onLoaded'],
  data: function () {
    return {
      showPopup: false,
      loaded: 0,
      loadedCall: 3,
      snippetHeaderItems: itemListData,
      mainHeaderItems: mainItems.items,
      secondaryHeaderItems: secondaryItems.items
    }
  },
  watch: {
    loaded: function(newVal) {
      if(newVal === this.loadedCall) {
        this.$emit('onLoaded', true)
      }
    }
  },
  computed: {

  },
  components: {
    CButton,
    Popup
  },
  methods: {
    preLogout: function(ev){
      ev.preventDefault()
      this.showPopup = true
    },
    logout: function(ev) {
      this.showPopup = false
      ev.preventDefault()
      this.$store.commit('reset')
      this.$router.push('/login')
    },
    closeMenu: function () {
      document.getElementById('close-menu').click()
    }
  },
  created() {

  },
  mounted() {
     this.loaded=3;
  }

}
</script>

<style lang="scss" scoped>
#main-nav > li > a.router-link-active-forced,
#main-nav > li > a.router-link-active {
  position: relative;

  @include bp($xl) {
    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: -3px;
      left: -5px;
      width: calc(100% + 10px);
      height: 10px;
      opacity: 0.27;
      background-image: var(--gradient-link);
      z-index: 1;
      border: none!important;
      margin: 0!important;
    }
  }
}

.notification-dot {
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background-color: var(--unread);
    border-radius: 50%;
    position: absolute;
    top: -2px;
    left: 14px;
  }
}
</style>