<template>
  <div class="c-popup">
    <div class="c-popup-bg" @click="close"></div>
    <div class="c-popup-content">
      <span class="c-popup-close" @click="close">
        <img :src="icoClose" />
      </span>
      <img src="../assets/illustration-check.svg" width="80" />
      <br />
      <div style="height: 30px;"></div>
      <span style="font-size: 34px;color: #002626;">
        <slot name="title">
          {{ $t('autoReadingEE.success_title') }}
        </slot>
        
      </span>
      <div style="height: 20px;"></div>
      <span style="font-size: 16px;color: #002626;font-weight: 300;">
        <slot name="text">
          {{ $t('autoReadingEE.success_description') }}
        </slot>
        
      </span>
      <div style="height: 30px;"></div>
      <div class="col-md-12 mb-4">
        <button type="button" class="btn btn-big text-uppercase w-100 mt-3" @click="() => this.goBack()">{{ $t("autoReadingEE.button_send_reading_back")}}</button>
      </div>
     
    </div>
  </div>
</template>

<script>
import icoClose from '../assets/ico-close.png'


export default {
  name: 'PopupReading',
  props: {
  },
  data: function () {
    return {
      icoClose: icoClose,
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },

    goBack: function() {

      if (this.$store.state.type == 'private') {
        this.$store.commit('setAutoReadingSupply', null);
      }
      
      this.$router.go(-1);

    }
  
  }
}
</script>
<style lang="scss" scoped>
.c-popup {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.5);
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    z-index: 1;
  }

  &-content {
    z-index: 2;
    position: relative;
    width: 100%;
    max-width: 630px;
    border-radius: 8px;
    border: solid 1px #dcdcdc;
    background-color: var(--white);
    max-height: 80%;
    overflow: auto;
    text-align: center;
    padding: 60px 20px;
    width: 90%;

    @include bp($md) {
      padding: 60px 100px;
    }
  }

  &-close {
    position: absolute;
    top: 22px;
    right: 22px;
    cursor: pointer;
  }
}
</style>