<template>
  <div class="container">
    <div class="container-app">
      <div class="row">
        <div class="col-md-12 mb-3">
          <back />
        </div>
        <div class="col-md-12 col-lg-6 offset-lg-3">
          <div class="rl-box m-auto">
            <h2 v-if="this.$store.state.type != 'private'" class="title-small">{{ $t("autoReadingGas.title")}}</h2>
            <div v-if="this.$store.state.type == 'private'" style="padding-bottom: 20px;display: flex;flex-direction: row;">
                <img style="height: 70px;width: 70px;" src="../assets/auto-reading-gas.png" />
                <div style="display: flex;flex-direction: column;margin-left: 15px;">
                  <span style="font-size: 16px;color: black;">
                    {{ $t("autoReadingLogged.title_gas") }}
                  </span>
                  <span v-if="this.$store.state.autoReadingSupply != null" style="font-size: 24px;color: black;">
                    {{ this.$store.state.autoReadingSupply.SupplyPoint }}
                  </span>
                </div>
            </div>
            <p class="rl-description">{{ $t("autoReadingGas.subtitle")}}</p>

            <form @submit="sendReading">
              <div class="row">
                <div class="col-md-12 mb-2">
                  <div class="form-floating">
                    <input type="text" class="form-control" style="text-transform: lowercase;" :class="{'is-invalid': errors.clientId}" id="clientId" v-model="clientId" :placeholder="$t('autoReadingGas.placeholder_clientId')" :disabled="$store.state.clientId">
                    <label for="name">{{ $t("autoReadingGas.placeholder_clientId")}}</label>
                    <div class="invalid-feedback" v-if="errors.clientId">
                      {{ $t("autoReadingGas.errorClientId")}}
                    </div>
                    <div style="margin-top: 15px;font-size: 12px; color: #002626;">{{ $t("autoReadingGas.title_clientId")}}</div>
                  </div>
                </div>
                <div class="col-md-12 mb-2">
                  <div class="form-floating">
                    <input type="text" class="form-control" style="text-transform: uppercase;" :class="{'is-invalid': errors.pdr}" id="pdr" v-model="pdr" :placeholder="$t('autoReadingGas.placeholder_pdr')" :disabled="$store.state.pdr">
                    <label for="name">{{ $t("autoReadingGas.placeholder_pdr")}}</label>
                    <div class="invalid-feedback" v-if="errors.pdr">
                      {{ $t("autoReadingGas.errorPDR")}}
                    </div>
                    <div style="margin-top: 15px;font-size: 12px; color: #002626;">{{ $t("autoReadingGas.title_pdr")}}</div>
                  </div>
                </div>
                <div class="col-md-12">
                  <hr class="separator">
                </div>
                <div class="col-md-12 mb-4">
                  <div style="display: flex; flex-direction: row;margin-bottom: 80px; margin-top: 54px;">
                    <div style="flex: 1;">
                      <label style="font-size:16px;font-weight: bold;color: #002626;">{{ $t("autoReadingGas.info_title")}}</label>
                      <label style="margin-top: 13px;,font-size:16px; color: #002626;">{{ $t("autoReadingGas.info_description")}}</label>
                    </div>
                    <div style="flex: 1;">
                      <img src="../assets/gas_self_reading.png">
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mb-4">
                  <div class="form-floating">
                    <input type="number" class="form-control" style="text-transform: lowercase;" :class="{'is-invalid': errors.reading}" id="pdr" v-model="reading" :placeholder="$t('autoReadingGas.placeholder_reading')" :disabled="$store.state.reading">
                    <label>{{ $t("autoReadingGas.placeholder_reading")}}</label>
                    <div class="invalid-feedback" v-if="errors.reading">
                      {{ $t("autoReadingGas.errorReading")}}
                    </div>
                  </div>
                </div>
                <div class="invalid-feedback" v-if="errors.apiReadingGas">
                  {{ $t("autoReadingGas.errorReadingGas")}}
                </div>
                <div class="col-md-12 mb-4">
                  <button type="submit" class="btn btn-big text-uppercase w-100 mt-3">{{ $t("autoReadingGas.button_send_reading")}}</button>
                </div>
               
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <popup @close="showPopup = false" v-if="showPopup">
      <div>
         
         
        </div>
    </popup>
  </div>

</template>

<script>
import Back from "@/components/Back";
import Popup from "@/components/PopupReading";


export default {
  name: 'AutoReadingGas',
  data: function () {

    return {
      popupContent: '',
      showPopup: false,
      clientId: this.$store.state.currentUserContract != null ? this.$store.state.currentUserContract.user.customerCode : '',
      pdr: this.$store.state.autoReadingSupply != null ? this.$store.state.autoReadingSupply.SupplyPoint : '',
      reading: '',
      errors: {
        clientId: false,
        pdr: false,
        reading:false,
      },
     
    }
  },
  watch: {
    
  },
  computed: {
  },
  components: {
    Popup,
    Back,
 
  },
  methods: {
    detectAutoReadingSended: function() {
      var result = "";

      const year = this.$moment().format('YYYY');
      const month = this.$moment().format('MM');

      const supply = this.$store.state.autoReadingSupply;

      const value =  (year+'_'+month+'_'+supply.supplyTypeCode+'_'+supply.SupplyPoint); 


      const autoReadingSended = this.$store.state.autoReadingSended;

      for (let index = 0; index < autoReadingSended.length; index++) {
        const element = autoReadingSended[index];

        if (element.value == value) {
          result = element.gas;
          break;
        }
        
      }

      return result;
    },
    read: function(html) {
      this.popupContent = html
      this.showPopup = true
    },
    sendReading: async function(ev) {
      ev.preventDefault()

      // Reset errors
      for (const errorsKey in this.errors) {
        if (typeof this.errors[errorsKey] === 'object') {
          for (const errorKey in this.errors[errorsKey]) {
            this.errors[errorsKey][errorKey] = false
          }
        } else {
          this.errors[errorsKey] = false
        }
      }

      // Validation
      if (!this.clientId) {
        this.errors.clientId = true
      }

      if (!this.pdr) {
        this.errors.pdr = true
      }


      if (!this.reading) {
        this.errors.reading = true
      }
      
      let error = false
      for (const errorsKey in this.errors) {
        if (typeof this.errors[errorsKey] === 'object') {
          for (const errorKey in this.errors[errorsKey]) {
            if (this.errors[errorsKey][errorKey]) {
              error = true
            }
          }
        } else {
          if (this.errors[errorsKey]) {
            error = true
          }
        }
      }

      if (!error) {
        this.$store.commit('setLoader', true)
       
        await this.axios.post(process.env.VUE_APP_API_DWH_URL + 'self-reading', {
          CustomerCode:this.clientId,
          SupplyTypeCode: "GAS",
          SupplyPoint: this.pdr,
          SelfReading_GAS: this.reading
        }).then(async (response) => {

            console.log(response,'_aa_xx');
            this.saveAutoReadingSended();

            this.showPopup = true;
            this.$store.commit('setLoader', false)


        }).catch(() => {
          this.errors.apiReadingGas = true
        });
        this.$store.commit('setLoader', false)
      }

    },
    saveAutoReadingSended: function() {
        if (this.$store.state.type != 'private') {
          return;
        }

        const autoReadingSended = this.$store.state.autoReadingSended;
        const year = this.$moment().format('YYYY');
        const month = this.$moment().format('MM');

        const supply = this.$store.state.autoReadingSupply;

        var founded = false;

        const value =  (year+'_'+month+'_'+supply.supplyTypeCode+'_'+supply.SupplyPoint); 


        for (let index = 0; index < autoReadingSended.length; index++) {
          const element = autoReadingSended[index];

          if (element.value == value) {
            element.ee_a1 = '';
            element.ee_a2 = '';
            element.ee_a3 = '';
            element.gas = this.reading;
            founded=true;
            break;
          }
          
        }

        if (!founded) {
          var newArray = [{
            value: value,
            ee_a1:'',
            ee_a2:'',
            ee_a3:'',
            gas:this.reading
          }].concat(autoReadingSended);

          this.$store.commit('setAutoReadingSended',newArray);
        } else {
          this.$store.commit('setAutoReadingSended',autoReadingSended);
        }
    }
  },
  created() {

  },
  mounted() {
    if (this.$store.state.type == 'private') {
      const autoReadingSended = this.detectAutoReadingSended();

      if (autoReadingSended != null) {
        this.reading = autoReadingSended;
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.form-password {
  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    cursor: pointer;
  }
}

.container-app {
  @include bp($md) {
    padding: 40px 0 80px;
  }
}

.separator {
  border-color: var(--light-grey);
  opacity: .1;
  margin: 24px 0;
}

.setreset {
  &-link {
    margin: 5px 0 0;
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    color: var(--black);
    text-decoration: underline;
    cursor: pointer;
  }

  &-text {
    margin: 35px 0 0;
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
    color: var(--title-color);
  }
}

.form-floating-inline {
  display: flex;

  > *:first-child {
    width: 30%;
  }

  > *:last-child {
    width: calc(70% + 1px);
    margin-left: -1px;
  }
}

.form-floating-flex {
  //flex: 1 1 auto;

  .form-control {
    min-width: 0;
    width: 100%;
  }
}

.privacy-link {
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  color: #002626;
  margin-left: 10px;
  display: block;
  cursor: pointer;
}
</style>