<template>
  <div class="container">
    <div class="container-app">
      <div class="row">
        <div class="col-md-12 col-lg-6 offset-lg-3">
          <div class="rl-box m-auto text-center">
            <img :src="icoSuccess">
            <h2 class="title-small">{{ $t("success.title")}}</h2>
            <p class="rl-description">{{ $t("success.desc")}}</p>
            <button type="button" class="btn btn-big text-uppercase w-100" @click="$router.push('/login')">{{ $t("success.back")}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import icoSuccess from '../assets/illustration-check.svg'
export default {
  name: 'Success',
  data: function () {
    return {
      icoSuccess: icoSuccess
    }
  },
}
</script>
<style lang="scss" scoped>
img {
  width: 80px;
  margin-bottom: 30px;
}

.container-app {
  @include bp($md) {
    padding: 140px 0;
  }

}
</style>