<template>
  <div class="payment-block">
    <span v-if="title" style="font-size: 12px;font-weight: bold;">{{ title }}</span>
    <br v-if="title">
    <span v-if="supplyPoint" style="font-size: 16px;font-weight: bold;">{{ supplyPoint }}</span>
    <br v-if="supplyPoint">
    <span v-if="supplyPoint" style="font-size: 12px;">{{ this.getAddress(supplyPoint) }}</span>
    <br v-if="supplyPoint">
    <div v-if="supplyPoint" style="height: 10px;"></div>
    <div class="payment-block-content">
      <img :src="icoBank" />
      <div>
        <div v-if="subtitle" class="payment-block-subtitle">{{ subtitle }}</div>
        <div v-if="text" class="payment-block-text">{{ text }}</div>
      </div>
    </div>
    <!-- <c-link @click="$emit('edit')">{{ $t('paymentBlock.manage') }}</c-link> -->
    <slot />
  </div>
</template>

<script>
import icoBank from '../assets/ico-bank.svg'
//import CLink from "@/components/CLink";
export default {
  name: 'PaymentBlock',
  components: {},
  props: {
    title: String,
    subtitle: String,
    text: String,
    supplyPoint:String
  },
  data: function () {
    return {
      icoBank: icoBank
    }
  },
  methods: {
    getAddress: function(code) {
      const supplies = this.$store.state.supplyPoints;
      var address = "";

      for (let index = 0; index < supplies.length; index++) {
        const element = supplies[index];
        
        if (element.SupplyPoint == code) {
          address = element.address;
          break;
        }
      }

      return address;
    },
  }
}
</script>
<style lang="scss" scoped>
.payment-block {
  &-content {
    background-color: var(--grey2);
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 15px;

    img {
      margin-right: 15px;
    }
  }

  &-title {
    margin: 0 0 17px;
    opacity: 0.8;
    font-size: 12px;
    font-weight: bold;
    color: var(--title-color);
  }

  &-subtitle {
    margin: 0;
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    color: var(--black);
  }

  &-text {
    margin: 0;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: normal;
    color: var(--black);
  }
}
</style>