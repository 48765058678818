<template>
    <div class="container">
      <div class="is-mobile" style="height: 20px;"></div>
      <div class="row help-row">
        <div class="col-lg-4 col-xl-4 mb-5 mb-lg-0">
          <subtitle style="font-size: 24px;color: #002626;">{{ $t("contractList.title") }}</subtitle>
          <h3 class="help-subtitle" style="font-size: 16px;margin-top: 23px;" v-html="$t('contractList.subtitle')"></h3>
          
          <div style="display: flex;flex-direction: row;margin-top: 33px;">
            <span style="display: inline-block;font-size: 14px;color: #000;text-align: center;">
              {{ this.$t("contractList.support_title") }}
            </span>
          </div>
    
          <div style="display: flex;flex-direction: row;">
            <span style="display: inline-block;font-size: 14px;color: #000;background-color: white;">
              {{ this.$t("contractList.support_subtitle_1") }}
            </span>
            <a style="margin-left: 5px;" href="/assistenza">{{this.$t("contractList.support_subtitle_2")}}</a>
          </div>

        </div>
        <div class="col-lg-8 col-xl-6">
          <ul v-if="this.$store.state.userContracts.length > 0" style="padding-bottom: 30px;">
            <li v-for="item in this.$store.state.userContracts" :key="item.user.customerCode" style="border-radius: 5px;display: flex;flex-direction: row;">
              <div style="display: flex;flex: 1; flex-direction: row;align-items: center;margin-bottom: 11px; background-color: white;padding-top: 16px;padding-bottom: 16px;border-radius: 5px;border: solid 1px #e8ecee;">
                    <div style="cursor:pointer;background-color: #f5f5f5;width:30px;height: 30px;border-radius: 15px;margin-left: 25px;"
                    @click="chooseUserContract(item)"
                    >
                      <div v-if="this.isSelected(item)" style="width:30px;height: 30px;border-radius: 15px;background-color: green;display: flex;align-items: center;justify-content: center;">
                        <div  style="width:26px;height: 26px;border-radius: 13px;background-color: white;display: flex;align-items: center;justify-content: center;">
                          <img :src="icoCheck">
                        </div>
                      </div>
                    </div>
                    <div style="display: flex;flex-direction: column;margin-left: 15px;">
                        <span style="font-size: 12px;color: #000;">
                          {{ this.$t("popupUserContractList.item_code_customer") }}
                        </span>
                        <span style="font-size: 18px;color: #000;font-weight: bold;margin-top: 3px;">
                        {{item.user.customerCode}}
                        </span>
                        <span style="font-size: 12px;color: #000;margin-top: 3px;">
                        {{this.formatTypeContract(item)}}
                        </span>
                    </div>
                    <div style="display: flex;flex: 1;"></div>
                    <div style="display: flex;flex-direction: row;margin-right: 20px;">
                      <div style="height: 45; width: 45px;background-color: #f5f5f5;border-radius: 8px;padding: 8px;">
                        <img v-if="item.user.supplyTypeCode.toLowerCase().indexOf('ee') > -1" src="../assets/ico_light_on.png" width="30" height="30" />
                        <img v-if="item.user.supplyTypeCode.toLowerCase().indexOf('ee') == -1" src="../assets/ico_light_off.png" width="30" height="30" />
                      </div>
                      <div style="height: 45; width: 45px;background-color: #f5f5f5;border-radius: 8px;padding: 8px;margin-left: 5px;">
                        <img v-if="item.user.supplyTypeCode.toLowerCase().indexOf('gas') > -1" src="../assets/ico_gas_on.png" width="30" height="30" />
                        <img v-if="item.user.supplyTypeCode.toLowerCase().indexOf('gas') == -1" src="../assets/ico_gas_off.png" width="30" height="30" />
                      </div>
                      <div style="width: 20px;max-width: 20px;min-width: 20px"></div>
                      <div @click="deleteContract(item)" v-if="!this.isSelected(item)" style="cursor: pointer;height: 45; width: 45px;background-color: #ff5b5b;border-radius: 8px;padding: 8px;margin-left: 5px;display: flex;align-items: center;justify-content: center;">
                        <img alt="Dissocia" title="Dissocia" style="height: 22px;width: 22px;" :src="icoExit" />
                      </div>
                      <div v-if="this.isSelected(item)" style="height: 45; width: 45px;background-color: white;border-radius: 8px;padding: 8px;margin-left: 5px;display: flex;align-items: center;justify-content: center;">
                        
                      </div>
                    </div>
                </div>
               
            </li>
          </ul>
        <div style="width: 100%;min-height: 100px;display: flex;align-items: center;justify-content: center;">
          <div style="cursor: pointer;display: flex;flex-direction: row;width: 100%;min-height: 58px;border-radius: 4px;  border: solid 1px #000;align-items: center;justify-content: center; border-radius: 4px;"
          @click="addNewContract"
          >
            <img style="height: 20px; height: 20px;color: #000;" src="../assets/ico_add.png">
            <span style="font-size: 14px;font-weight: bold;color: #000;margin-left: 15px;">
                        {{ this.$t("contractList.button_add_contract") }}
            </span>
        </div>
      </div>
       <div style="height: 97px;"></div>
        </div>
      </div>
      <popup @close="this.$store.state.showAddNewContract = false" v-if="this.$store.state.showAddNewContract">
      <div>
            
      </div>
      </popup>
      <popupdelete @close="this.$store.state.showDContract = false" v-if="this.$store.state.showDContract">
      <div>
            
      </div>
      </popupdelete>
    </div>
  
</template>

<script>
import Subtitle from "@/components/Subtitle";
import icoCheck from '../assets/ico-check.svg'
import icoExit from '../assets/ico-exit-white.png'
import Popup from "@/components/PopupAddUserContract";
import Popupdelete from "@/components/PopupDeleteUserContract";

export default {
  name: 'ContractList',
  components: {
    Subtitle,
    Popup,
    Popupdelete
  },
  props: ['type'],
  data: function () {
    return {
      icoCheck: icoCheck,
      icoExit:icoExit
    }
  },
  methods: {
    deleteContract: function(item) {
      this.$store.commit('setDeleteContract',item)
      this.$store.commit('showDeleteContract',true)     
    },
    isSelected : function(item) {
      var currentUserContract = this.$store.state.currentUserContract;

      return (currentUserContract.user.customerCode == item.user.customerCode);
    },
    formatTypeContract: function(item) {
      var result = "";

      if (item.user.customerTypeCode.toLowerCase() == "piva") {
        result = this.$t("popupUserContractList.item_type_business");
      } else if (item.user.customerTypeCode.toLowerCase() == "cond") {
        result = this.$t("popupUserContractList.item_type_cond");
      } else if (item.user.customerTypeCode.toLowerCase() == "dom") {
        result = this.$t("popupUserContractList.item_type_domestic");
      }

      return result;
    },
    chooseUserContract: function(item) {

      this.$store.commit('setUserContract',item);
      this.$store.commit('setAccessTokenDWH',item.user.dwhAccessToken);

      this.$router.push('/')

      
    },
    addNewContract: function() {
     this.$store.commit('showAddContract', true);
    },
  },
  async created() {

    this.$store.commit('setLoader', true);

    await this.axios.get(process.env.VUE_APP_API_AUTH_URL + 'user-contracts', this.authSetting)
        .then((response) => {
          this.$store.commit('setUserContractList',response.data);
          this.$store.commit('setLoader', false);
        }).catch(() => {
          this.$store.commit('setLoader', false);
        });
  },
  mounted() {
    
  }
}
</script>
<style lang="scss" scoped>
.help {
  background-color: var(--grey);
  padding: 0 0 24px;

  @include bp($md) {
    padding: 0 0 80px;
  }

  &-row {
    @include bp($md) {
      padding-top: 35px;
    }
  }

  &-subtitle {
    opacity: 0.5;
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    color: var(--title-color);
  }

  &-contacts {
    margin: 0;
    padding: 0;
    list-style: none;

    &-title {
      margin: 25px 0 12px;
      opacity: 0.72;
      font-size: 14px;
      font-weight: normal;
      line-height: 1;
      letter-spacing: normal;
      color: var(--black);
    }

    &-content {
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      color: var(--black);
    }

    &-hours {
      margin: 12px 0 18px;
      opacity: 0.48;
      font-size: 14px;
      line-height: 1;
      letter-spacing: normal;
      color: var(--black);
    }
  }

  &-form {
    &-title {
      opacity: 0.8;
      font-size: 12px;
      font-weight: bold;
      color: var(--title-color);
      text-transform: uppercase;
      margin: 0 0 25px;
    }
  }


}

</style>