<template>
  <div>
      <card-gradient-auto-reading @click="$router.push('/auto-reading-private')" v-if="this.$store.state.dashboard.selfReadingInfo && (this.$store.state.dashboard.selfReadingInfo.eeIsAvailable || this.$store.state.dashboard.selfReadingInfo.gasIsAvailable)">
              <template #card-text><div v-html="$t('home.autoReadingDescription')"></div></template>
      </card-gradient-auto-reading>
    <section class="client-data">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="client">
              <h3 class="big-text">{{ $t("home.hello")}} {{ this.$store.state.showCList ? ' ' : $store.state.user.name+' '+$store.state.user.surname }}, </h3>
              <div style="margin-top: 60px;" v-if="this.$store.state.currentUserContract">
                <div class="small-text">{{ this.$store.state.showCList ? ' ' : $t("profile.nameLastname") }}</div>
                <div class="address">{{ this.$store.state.showCList ? ' ' : $store.state.currentUserContract.user.customerName }}</div>
                <p v-if="this.$store.state.currentUserContract" class="address">
                    {{ $t("home.clientCode")}} {{ this.$store.state.showCList ? ' ' : $store.state.currentUserContract.user.customerCode }}
                </p>
                <p v-if="this.$store.state.currentUserContract" class="small-text text-uppercase">{{ $t("home.yourHome")}}</p>
                <!-- <p v-if="this.$store.state.currentUserContract && this.$store.state.currentUserContract.supplyPoints.length > 0" class="address">{{ this.$store.state.showCList ? ' ' : this.$store.state.currentUserContract.supplyPoints[0].shippingAddress }}</p> -->
                <p v-if="this.mainAddress && this.mainAddress.length > 0" class="address">{{ this.mainAddress }}</p>

              </div>
            </div>
            <!-- <div v-if="this.$store.state.currentUserContract" class="address-container">
            </div> -->
            <div class="get-profile">
              <c-link @click="$router.push('/profilo')">{{ $t("home.yourData")}}</c-link>
            </div>
          </div>
          <div class="col-lg-8 mt-3 mt-lg-0">
            <div class="supplies d-flex">
              <div class="cards-container d-flex justify-content-around flex-wrap">
                <swiper
                  style="width:100%"
                  :slides-per-view="1"
                  :breakpoints="breakpoints"
                  :space-between="30"
                  :modules="modules"
                  :scrollbar="{ draggable: true }"
                >
                  <swiper-slide v-for="s in supplies" :key="s.invoiceId">
                    <supply :supply="s" />
                  </swiper-slide>
                </swiper>
              </div>

              <!--<button class="btn-add">Aggiungi</button>-->
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="payments">
      <div class="container">
        <div class="row py-4 py-md-5 align-items-center">
          <div class="col-md-8 mt-3 mt-lg-0">
            <payment :type="paymentStatus"></payment>
          </div>
          <div class="col-md-4 text-md-end mt-3">
            <div>
              <subtitle>{{ this.$store.state.accessTokenDWH ? $t("home.payments") : ''}}</subtitle>
              <c-link style="font-size: 16px;" v-if="this.$store.state.accessTokenDWH" @click="goToAllPayment">{{ $t("home.showPayments")}}</c-link>
            </div>
          </div>
         
        </div>
      </div>
    </section>
    <section class="clear-your-bill">
      <div class="container">
        <div class="row align-items-center">
          <div>
            <card-gradient @click="$router.push('/azzera-bolletta')">
              <template #card-title>{{ $t("home.discountTitle") }}</template>
              <template #card-text><div v-html="$t('home.discountText')"></div></template>
              <template #card-sub-text>{{ $t("home.discountNotes") }}</template>
            </card-gradient>
          </div>
          <!-- <div class="col-lg-9 col-padded">
            <div class="d-sm-flex align-items-center justify-content-between">
              <card-no-borders>
                <template #card-img>
                  <img src="../assets/illustration-amici-2.png"/>
                </template>
                <template #card-title>{{ $t("home.discountBox1Title")}}</template>
                <template #card-text>{{ $t("home.discountBox1Text")}}</template>
              </card-no-borders>

              <div class="px-3 block-separator">
                  <img src="../assets/rows.png"/>
              </div>

              <card-no-borders>
                <template #card-img>
                  <img src="../assets/illustration-amici-8.png"/>
                </template>
                <template #card-title>{{ $t("home.discountBox2Title")}}</template>
                <template #card-text>{{ $t("home.discountBox2Text")}}</template>
              </card-no-borders>

              <div class="px-3 block-separator">
                <img src="../assets/rows.png"/>
              </div>

              <card-no-borders>
                <template #card-img>
                  <img src="../assets/illustration-amici-13.png"/>
                </template>
                <template #card-title>{{ $t("home.discountBox3Title")}}</template>
                <template #card-text>{{ $t("home.discountBox3Text")}}</template>
                <template #card-notes>{{ $t("home.discountBox3Notes")}}</template>
              </card-no-borders>

            </div>

            <div class="notes text-center notes-relative">
              {{ $t("home.discountTerms")}}
            </div>

          </div> -->
        </div>
      </div>
    </section>
    <popup @close="this.$store.state.showCList = false" v-if="this.$store.state.showCList">
      <div>
         
         
        </div>
    </popup>
  </div>

</template>

<script>
import { Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.css";
import 'swiper/modules/scrollbar/scrollbar.min.css';
import Supply from "@/components/SupplyHome";
import CLink from "@/components/CLink";
import Subtitle from "@/components/Subtitle";
import Payment from "@/components/Payment";
import CardGradient from "@/components/CardGradient";
import CardGradientAutoReading from "@/components/CardGradientAutoReading";
//import CardNoBorders from "@/components/CardNoBorders";
import Popup from "@/components/PopupUserContractList";

export default {
  name: 'PersonalArea',
  components: {
    //CardNoBorders,
    CardGradient,
    Payment,
    Subtitle,
    Supply,
    Swiper,
    SwiperSlide,
    CLink,
    Popup,
    CardGradientAutoReading
  },
  data: function () {
    return {
      modules: [Scrollbar],
      paymentStatus: 'ok',
      supplies: [],
      breakpoints: {
        1025: {
          slidesPerView: 3
        },
        768: {
          slidesPerView: 2
        }
      },
      showPopup: false,
      mainAddress:'',
    }
  },
  computed: {
    
  },
  methods: {
    goToAllPayment: function() {
      this.$store.commit('setFilterInvoiceStatus', 0);
      this.$router.push('/pagamenti-fatture');
    }
  },
  async created() {

    this.$store.commit('setLoader', true)

    await this.axios.get(process.env.VUE_APP_API_AUTH_URL + 'user-profile', this.authSetting)
    .then((response) => {
      this.$store.commit('setUser', response.data)
    }).catch(() => {});

    if (this.$store.state.showCList) {
      await this.axios.get(process.env.VUE_APP_API_AUTH_URL + 'user-contracts', this.authSetting)
        .then((response) => {
          this.$store.commit('setUserContractList',response.data);

          if (response.data.length == 1) {
            this.showPopup = false;
            this.$store.commit('setUserContract',response.data[0]);
            this.$store.commit('setAccessTokenDWH',response.data[0].user.dwhAccessToken);

            this.$router.go();

            this.$store.commit('showContractList', false);
            this.$store.commit('setLoader', false);
          } else {
            this.showPopup = this.$store.state.showCList;
          }
        }).catch(() => {});
    } else {
      await this.axios.get(process.env.VUE_APP_API_DWH_URL + 'payment-status', this.authDWHSetting)
        .then((response) => {
          this.paymentStatus = response.data.paymentsAreRegular ? 'ok' : 'ko'
        }).catch(() => {});

      await this.axios.get(process.env.VUE_APP_API_DWH_URL + 'supply-points', this.authDWHSetting)
        .then((response) => {
          this.supplies = response.data;
          this.$store.commit('setSupplyPointList',this.supplies);
        }).catch(() => {});

      await this.axios.get(process.env.VUE_APP_API_DWH_URL + 'main-address', this.authDWHSetting)
        .then((response) => {
          this.mainAddress = response.data.address;
          this.$store.commit('setMainAddress',this.mainAddress);
        }).catch(() => {});
    }
    this.$store.commit('setLoader', false)
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.client-data {
  padding: 24px 0 0;

  @include bp($md) {
    padding: 66px 0 75px;
  }

  .client {
    margin-bottom: 20px;

    @include bp($md) {
      margin-bottom: 50px;
    }
  }
}

.swiper {
  padding-bottom: 24px;

  @include bp($md) {
    padding-bottom: 35px;
  }
}
.supplies {
  .cards-container {
    width: 100%;

    .card {

      .card-img-top {
        width: 47px;
        height: auto;
        margin-bottom: 20px;
      }
    }
  }
}

.col-padded {
  margin-top: 30px;

  @include bp($lg) {
    margin-top: 0;
    padding-left: 65px;
  }
}

.notes-relative {
  position: relative;
  top: 35px;
}

.block-separator {
  display: none;

  @include bp($md) {
    display: block;
  }
}

</style>