<template>
  <div class="c-popup">
    <div class="c-popup-bg" @click="close"></div>
    <div class="c-popup-content">
      <span class="c-popup-close" @click="close">
        <img :src="icoClose" />
      </span>
      <span class="icon" v-if="!!$slots.title"><slot name="title"></slot></span>
      <span class="text" v-if="!!$slots.text"><slot name="text"></slot></span>
    </div>
  </div>
</template>

<script>
import icoClose from '../assets/ico-close.png'
export default {
  name: 'Popup',
  props: {
  },
  data: function () {
    return {
      icoClose: icoClose
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
.c-popup {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.5);
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    z-index: 1;
  }

  &-content {
    z-index: 2;
    position: relative;
    width: 100%;
    max-width: 630px;
    border-radius: 8px;
    border: solid 1px #dcdcdc;
    background-color: var(--white);
    max-height: 80%;
    overflow: auto;
    text-align: center;
    padding: 60px 20px;
    width: 90%;

    @include bp($md) {
      padding: 60px 100px;
    }
  }

  &-close {
    position: absolute;
    top: 22px;
    right: 22px;
    cursor: pointer;
  }
}
</style>