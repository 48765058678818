<template>

  <section class="bills">
    <div class="container">
      <div class="row bills-header">
        <div class="col-lg-6">
          <back :before="backToBill" />
        </div>
        <div class="col-lg-6 bills-header-code">
          {{ $t("bill.supply") }} {{ $route.params.code.toLowerCase() === 'ee' ? $t("bill.supplyEE") : $t("bill.supplyGas") }} <strong>{{ $store.state.supply.SupplyPoint }}</strong>
        </div>
      </div>
      <div class="row bills-title">
        <div class="col-md-6">
          <subtitle>{{ $t('bill.titleHistory') }}</subtitle>
        </div>
        <!-- <div class="col-md-6 text-md-end">
          <year v-model="year" :type="$route.params.code.toLowerCase()" :years="this.years" />
        </div> -->
      </div>
      <div class="row pb-5" v-if="this.enableBefore2020">
            <div class="col-12">
            <div style="display: flex;flex-direction: column;align-items: center;">
                <div style="display: flex;flex-direction: row;">
                <span style="display: inline-block;font-size: 14px;color: #000;text-align: center;">
                  {{ this.$t("payment.support_title") }}
                </span>
              </div>
        
              <div style="display: flex;flex-direction: row;">
                <span style="display: inline-block;font-size: 14px;color: #000;">
                  {{ this.$t("payment.support_subtitle_1") }}
                </span>
                <a style="margin-left: 5px;" href="/assistenza?show_back=true">{{this.$t("payment.support_subtitle_2")}}</a>
              </div>
            </div>
            </div>
          </div>
      <div class="row bills-content" ref="bills">
        <div class="col-lg-12" v-if="billsTotal === 0 && end">
          {{ $t('bill.noBills')}}
        </div>
        <!-- <div class="col-lg-12" v-if="billsTotal > 0">
          <div class="row pb-5" v-for="month in billsMonths" :key="'month-' + month">
            <div class="col-12">
              <date-header :year="year" :month="month" />
            </div>
            <div class="col-md-4 col-lg-3" v-for="b in bills[year][month]" :key="'invoice-' + b.invoiceId">
              <supply mode="payment" :supply="b" />
            </div>
          </div>
        </div> -->
        <div class="col-lg-12" v-if="billsTotal > 0">
          <template v-for="year in billsYears" :key="'year-' + year">
            <div class="row pb-5" v-for="month in billsMonths(year)" :key="'month-' + month">

            <div class="col-12">
              <date-header :year="year" :month="month" />
            </div>
            <div class="col-md-4 col-lg-3" v-for="b in bills[year][month]" :key="'invoice-' + b.invoiceId">
              <supply mode="payment" :supply="b" />
            </div>
            </div>
          </template>
          <div class="row pb-5" v-if="this.end && this.enableBefore2020">
            <div class="col-12">
            <div style="background-color: #858585;width: 100%; height: 5px;opacity: 0.1;margin-bottom: 20px;"></div>
            <div style="display: flex;flex-direction: column;align-items: center;">
                <div style="display: flex;flex-direction: row;">
                <span style="display: inline-block;font-size: 14px;color: #000;text-align: center;">
                  {{ this.$t("payment.support_title") }}
                </span>
              </div>
        
              <div style="display: flex;flex-direction: row;">
                <span style="display: inline-block;font-size: 14px;color: #000;">
                  {{ this.$t("payment.support_subtitle_1") }}
                </span>
                <a style="margin-left: 5px;" href="/assistenza?show_back=true">{{this.$t("payment.support_subtitle_2")}}</a>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <popup @close="() => this.closePopup()" v-if="$store.state.notes.length > 0" />
</template>

<script>
import Back from "@/components/Back";
import Subtitle from "@/components/Subtitle";
//import Year from "@/components/Year";
import Supply from "@/components/Supply";
import DateHeader from "@/components/DateHeader";
import Popup from "@/components/PopupNoteList";
export default {
  name: 'Bill',
  components: {
    DateHeader,
    Supply,
    //Year,
    Subtitle,
    Back,
    Popup
  },
  data: function () {
    let year = this.$route.params.year
    if (this.$store.state.billsYear) {
      year = this.$store.state.billsYear
      this.$store.commit('setBillsYear', null)
    }

    return {
      year: year,
      years: [year],
      bills: {},
      total: 0,
      end: false,
      isLoading: false,
      limit: 10,
      offset: 0,
      billsTotal: 0,
      enableBefore2020:false,
      yearFrom:year,
      yearTo:year
    }
  },
  computed: {
    billsYears: function () {
      return Object.keys(this.bills).sort((a,b) => { return b - a})
    },
  },
  watch:{
    year () {
      this.resetScrollData()
      this.getData(true)
    }
  },
  methods: {
    billsMonths: function (year) {
      return Object.keys(this.bills[year]).sort((a,b) => { return b - a})
    },
    closePopup: function() {
      this.$store.commit('setNoteList', []);
      this.$store.commit('setTitleNoteList','');
    },
    backToBill: function() {
      setTimeout(() => {
        this.$store.commit('setShowHistory', true)
        this.$store.commit('setShowBack', false)
      }, 100)
    },
    resetScrollData: function(){
      this.bills = {}
      this.offset = 0
      this.billsTotal = 0
      this.end = false
    },
    getData: async function (loader) {
      if (loader) {
        this.$store.commit('setLoader', true)
      }
      this.isLoading = true

      await this.axios.get(process.env.VUE_APP_API_DWH_URL + 'invoices?SupplyPoint=' + this.$route.params.id + '&limit=' + this.limit + '&offset=' + this.offset + '', this.authDWHSetting)
        .then((response) => {

          response.data.invoices.map((bill) => {
            const year = this.$moment(bill.invoiceDate).format('YYYY')
            const month = this.$moment(bill.invoiceDate).format('M')
            if (!this.bills[year]) {
              this.bills[year] = {}
            }
            if (!this.bills[year][month]) {
              this.bills[year][month] = []
            }
            this.billsTotal++
            this.bills[year][month].push(bill)
          })

          if (response.data.existBefore2020 != null && response.data.existBefore2020 != undefined) {
            this.enableBefore2020 = response.data.existBefore2020;
          }

          // if (response.data.yearFrom != null && response.data.yearFrom != undefined) {
          //   this.yearFrom = response.data.yearFrom;
          // }

          // if (response.data.yearTo != null && response.data.yearTo != undefined) {
          //   this.yearTo = response.data.yearTo;
          // }
         
          // var years = [];

          // for (let index = this.yearTo; index > this.yearFrom-1; index--) {
     
          //   years.push(index);
      
          // }

          // if (years.length > 0) {
          //   this.years = years;
          // }          
         
          this.offset += this.limit
          this.total = response.data.total

          if (this.total <= this.billsTotal) {
            this.end = true
          }
        }).catch(() => {});

      this.isLoading = false

      if (loader) {
        this.$store.commit('setLoader', false)
      }
    },
    handleScroll: function(){
      if (this.$refs.bills.getBoundingClientRect().bottom < window.innerHeight && !this.isLoading && !this.end) {
         console.log('_aa_xx_scrolling',this.offset);
        this.getData(this.offset == 0)
      }
    },
  },
  async created() {
    this.getData(true)
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }}
</script>

<style lang="scss" scoped>
.bills {
  background-color: var(--grey);

  &-header {
    padding: 24px 0 0;

    @include bp($md) {
      padding: 35px 0 0;
    }

    &-code {
      opacity: 0.5;
      font-size: 12px;
      font-weight: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--black);
      padding-top: 20px;
      margin-bottom: 20px;

      @include bp($md) {
        padding-top: 0;
        text-align: right;
      }
    }
  }

  &-title {
    align-items: center;
    margin-bottom: 23px;
  }

  &-content {
    padding-bottom: 70px;
  }
}
</style>