<template>
  <div class="c-popup">
    <div class="c-popup-content" style="padding: 46px 0px 0px 0px;">
      <div style="width: 100%;">
        <span class="is-mobile" @click="() => {
          this.$store.commit('showDeleteContract',false)
          this.$store.commit('setDeleteContract',null)
          }" style="cursor: pointer;position: absolute;right: 12px;">
          <img class="is-close" :src="$siteURL + '/build/frontend/images/ico-close.png'" />
        </span>
        <span class="is-desktop" @click="() => {
          this.$store.commit('showDeleteContract',false)
          this.$store.commit('setDeleteContract',null)
          }" style="cursor: pointer;position: absolute;right: 50px;">
          <img class="is-close" :src="$siteURL + '/build/frontend/images/ico-close.png'" />
        </span>
      </div>
      <div style="max-width: 370px;margin: auto;padding: 42px 12px 27px;display: flex;flex-direction: column;align-items: center;">

        <span style="font-size: 34px;color: #002626;">
          {{ $t('popupDeleteUserContract.title') }}        
        </span>
        <span style="font-size: 16px;font-weight: 300;color: #002626;margin-top: 20px;text-align: center;">
          {{ $t('popupDeleteUserContract.subtitle') }}        
        </span>
        <span style="font-size: 16px;font-weight: bold;color: #002626;margin-top: 20px;text-align: center;margin-top: 30px;">
          {{ $t('popupDeleteUserContract.subtitle2',{customerCode: this.$store.state.deleteCurrentContract ? this.$store.state.deleteCurrentContract.user.customerCode : ''}) }}        
        </span>
     
          <div class="row">
      
          </div>
          <div class="invalid-feedback" v-if="errors.errorServer" style="display: flex;align-items: center;justify-content: center;">
                {{ $t("popupDeleteUserContract.errorServer")}}
          </div>
          <div class="col-md-12 mb-4" style="margin-top: 50px;width: 100%;">
            <button type="button" class="btn btn-big text-uppercase w-100 mt-3" @click="disconnectContract()">{{ $t("popupDeleteUserContract.button")}}</button>
          </div>
          <div  @click="() => {
              this.$store.commit('showDeleteContract',false)
              this.$store.commit('setDeleteContract',null)
            }" class="col-md-12 mb-4" style="text-decoration: underline;cursor:pointer;margin-top: 50px;margin-bottom: 60px;display: flex;align-items: center;justify-content: center;">
           <span style="font-size: 14px;font-weight: bold;">
            {{ $t("popupDeleteUserContract.not_now") }}
           </span>
          </div>
          
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'PopupDeleteUserContract',
  components: {
  },
  data: function () {
    return {
      errors:{
        errorServer:false
      }
    }
  },
  computed: {

  },
  methods: {
    disconnectContract: async function() {
      const contract = this.$store.state.deleteCurrentContract;

      console.log(contract,'_aa_xx');

      this.errors.errorServer = false;
      this.$store.commit('setLoader', true);

      await  this.axios.patch(process.env.VUE_APP_API_AUTH_URL_API + 'user-contract/disconnect', {
        VendorCustomerId: contract.user.vendorCustomerId,
        CustomerCode: contract.user.customerCode,
        VatFiscalCode: contract.user.vatFiscalCode,
      }, this.authSetting).then(async() => {
        
        await this.loadUserContract();
      }).catch(() => {
        this.errors.errorServer = true
        this.$store.commit('setLoader', false)
      });
    },
    loadUserContract: async function() {
      await this.axios.get(process.env.VUE_APP_API_AUTH_URL + 'user-contracts', this.authSetting)
        .then((response) => {
          this.$store.commit('setUserContractList',response.data);

          this.$store.commit('setToast', {
            show: true,
            type: 'success',
            text: this.$t('popupDeleteUserContract.confirmMessage'),
            timeout: 3000
          })
          this.$store.commit('showDeleteContract',false)
          this.$store.commit('setDeleteContract',null)
        }).catch(() => {
          this.errors.errorServer = true
        }).finally(() => {
        this.$store.commit('setLoader', false)
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.c-popup {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.5);
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    z-index: 1;
  }

  &-content {
    z-index: 2;
    position: relative;
    width: 100%;
    max-width: 630px;
    border-radius: 8px;
    border: solid 1px #dcdcdc;
    background-color: var(--white);
    max-height: 80%;
    overflow: auto;
    
    padding: 60px 20px;
    width: 90%;

    @include bp($md) {
      padding: 60px 100px;
    }
    
  }
  .setreset {
  &-link {
    
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    color: var(--black);
    text-decoration: underline;
    cursor: pointer;
  }

  &-text {
    margin: 35px 0 0;
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
    color: var(--title-color);
  }
}
}

</style>