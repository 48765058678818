<template>
  <div class="profile">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mb-2 mb-md-5">
          <subtitle>{{ $t("profile.title") }}</subtitle>
        </div>

        <div class="col-lg-3 col-xl-3 mb-5 mb-lg-0">
          <h3 class="profile-name">{{ $t('profile.hello') }} {{ $store.state.user.name+' '+$store.state.user.surname }},</h3>
          <!-- <h3 v-if="$store.state.currentUserContract" class="profile-subtitle">{{ $t('profile.code') }} {{ $store.state.currentUserContract.user.customerCode }}</h3> -->
          <ul class="profile-contacts">
            <li>
              <div class="profile-contacts-title">{{ $t("profile.nameLastname") }}</div>
              <div class="profile-contacts-content">{{ $store.state.currentUserContract.user.customerName }}</div>
              <div class="profile-contacts-content">
                {{ $t('profile.code') }} {{ $store.state.currentUserContract.user.customerCode }}
              </div>
              
            </li>
            <li>
              <div class="profile-contacts-title">{{ $t("profile.email") }}</div>
              <div class="profile-contacts-content">{{ $store.state.user.email }}</div>
            </li>
          </ul>
          <c-link @click="() => goToHelp(9)">{{ $t("profile.contact") }}</c-link>
        </div>
        <div class="col-lg-8 offset-lg-1 offset-xl-1 col-xl-8">
          <div class="row">
            <div class="col-md-6 mb-4">
              <profile-block :title="$t('profile.phone')" :text="$store.state.user.phoneNumber" >
                <c-link @click="() => goToHelp(8)">{{ $t("profile.contact") }}</c-link>
              </profile-block>
            </div>
            <div class="col-md-6 mb-4">
              <profile-block :title="$t('profile.password')" :text="'**********'" :edit="true" @edit="$router.push('/modifica-password')" />
            </div>
            <div class="col-md-6 mb-4">
              <profile-block :title="$t('profile.payment')">

                <payment-block :key="item + i" v-for="(item, i) in this.$store.state.currentUserContract.supplyPoints" :supplyPoint="item.supplyPoint" :title="item.supplyTypeCode == 'GAS' ? $t('profile.paymentGas') : $t('profile.paymentEE')" :subtitle="item.masterContractPaymentTypeCode == 'RID' ? $t('profile.paymentRID') : $t('profile.paymentBLP') " :text="item.masterContractPaymentTypeCode == 'RID' ? this.getBankName() : ''" class="mb-4">
                  <c-link v-if="item.active" @click="() => goToHelp(1)">{{ $t("profile.contact") }}</c-link>
                </payment-block>
              </profile-block>
            </div>
            <div v-if="$store.state.currentUserContract" class="col-md-6 mb-4">
              <profile-block :title="$t('profile.address')" :text="$store.state.mainAddress">
                <c-link v-if="$store.state.currentUserContract.supplyPoints[0].active" @click="() => goToHelp(10)">{{ $t("profile.contact") }}</c-link>
              </profile-block>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Subtitle from "@/components/Subtitle";
import CLink from "@/components/CLink";
import ProfileBlock from "@/components/ProfileBlock";
import PaymentBlock from "@/components/PaymentBlock";
export default {
  name: 'Help',
  components: {PaymentBlock, ProfileBlock, CLink, Subtitle},
  props: ['type'],
  data: function () {
    return {
    }
  },
  methods: {
    goToHelp: function(code) {
      this.$store.commit('setHelp', {
        RequestTypeID: code,
        SupplyPoint: this.supplyPoint
      })
      this.$router.push('/assistenza')
    },
    getBankName: function() {
      var bankName = "";

      const userContract = this.$store.state.currentUserContract;
     
      bankName = userContract.supplyPoints[0].bankName;
      
      return bankName;
    },
  },
  mounted() {
  }
}
</script>
<style lang="scss" scoped>
.profile {
  padding: 24px 0;
  background-color: var(--grey);

  @include bp($md) {
    padding: 80px 0;
  }


  &-subtitle {
    opacity: 0.5;
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    color: var(--title-color);
  }

  &-name {
    font-size: 24px;
    font-weight: normal;
    line-height: normal;
    color: var(--title-color);
  }

  &-contacts {
    margin: 20px 0 0;
    padding: 0;
    list-style: none;

    @include bp($md) {
      margin: 50px 0 0;
    }

    &-title {
      margin: 0 0 5px;
      opacity: 0.5;
      font-size: 14px;
      font-weight: normal;
      line-height: normal;
      color: var(--title-color);
    }

    li {
      margin: 0 0 20px;

      @include bp($md) {
        margin: 0 0 38px;
      }

      &:last-child {
        margin: 0 0 10px;
      }
    }

    &-content {
      margin: 0;
      font-size: 18px;
      font-weight: normal;
      letter-spacing: normal;
      color: var(--title-color);
    }

    &-hours {
      margin: 12px 0 18px;
      opacity: 0.48;
      font-size: 14px;
      line-height: 1;
      letter-spacing: normal;
      color: var(--black);
    }
  }

  &-form {
    &-title {
      opacity: 0.8;
      font-size: 12px;
      font-weight: bold;
      color: var(--title-color);
      text-transform: uppercase;
      margin: 0 0 25px;
    }
  }
}
</style>