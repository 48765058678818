<template>
  <div class="text-header"><slot></slot></div>
</template>

<script>
export default {
  name: 'TextHeader'
}
</script>
<style lang="scss" scoped>
.text-header {
  opacity: 0.8;
  font-size: 12px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: var(--title-color);
  text-transform: uppercase;
  margin-bottom: 25px;
  position: relative;

  &::after {
    content: '';
    width: calc(100% - 168px);
    opacity: 0.1;
    height: 5px;
    background-color: #858585;
    right: 0;
    position: absolute;
    top: calc(50% - 3px);
  }
}
</style>